import { Controller } from 'stimulus';
import 'bootstrap-daterangepicker';
import moment from 'moment';
import $ from 'jquery';

export default class extends Controller {
  static values = { start: String, end: String };
  static targets = ['input'];

  connect() {
    const config = {
      ranges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [
          moment().subtract(1, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month'),
        ],
      },
      locale: {
        format: 'DD/MM/YYYY',
      },
      opens: 'left',
      drops: 'down',
      autoApply: true,
      alwaysShowCalendars: false,
      showCustomRangeLabel: true,
    };

    if (this.startValue === '' || this.endValue === '') {
      $(this.inputTarget).daterangepicker(config);
      $(this.inputTarget).val('');
    } else {
      config['startDate'] = moment(this.startValue);
      config['endDate'] = moment(this.endValue);
      $(this.inputTarget).daterangepicker(config);
    }
  }
}
