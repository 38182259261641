import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['preview'];

  changed(e) {
    let subscriber_id = e.target.dataset.subscriberId;
    const frame = this.previewTarget;
    this.previewTarget.id = 'wa_whats_app_template_' + e.target.value;
    let origin = window.location.origin;
    let url = new URL(origin + '/wa/templates/' + e.target.value);
    if (subscriber_id) {
      url.searchParams.append('subscriber_id', subscriber_id);
    }
    frame.src = url;
  }
}
