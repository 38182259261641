import { Controller } from 'stimulus';
import Sortable from 'sortablejs';
import { patch } from '@rails/request.js'

export default class extends Controller {
  static values = { url: String, column: String, klass: String, group: String }

  connect() {
    this.initSortable();
  }

  initSortable() {
    var options = {
      dataIdAttr: 'data-id',
      fallbackOnBody: true,
      group: this.groupValue,
      swapThreshold: 0.65,
      animation: 150,
      sort: true,
      handle: '.sortable-handle',
      onSort: this.sort.bind(this),
    };

    this.sortable = Sortable.create(this.element, options);
  }

  disconnect() {
    this.sortable.destroy();
  }

  sort(evt) {
    const { item: { dataset: { id } }, newIndex } = evt;
    this.updatePosition(this.urlValue.replace(':id', id), newIndex);
  }

  async updatePosition(url, position) {
    const { klassValue, columnValue } = this;
    let data = { [klassValue]: { [columnValue]: position } };

    const response = await patch(url, {
      responseKind: 'turbo-stream',
      body: JSON.stringify(data)
    });
  }
}
