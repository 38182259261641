import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [];

  connect() {
    this.addEventListeners();
  }

  addEventListeners() {
    let parentEl = this.element;
    this.element
      .querySelector('input[name=\'select_all\']')
      .addEventListener('change', (evt) => {
        parentEl.querySelectorAll('input.row_selection').forEach((el) => {
          if (evt.target.checked) {
            el.setAttribute('checked', evt.target.checked);
          } else {
            el.removeAttribute('checked');
          }
        });
      });
    this.element
      .querySelector('form.assignment-form')
      .addEventListener('submit', (e) => {
        let selectedConversations = [];
        parentEl
          .querySelectorAll('input.row_selection:checked')
          .forEach((e) => {
            selectedConversations.push(e.value);
          });
        if (selectedConversations.length == 0) {
          e.preventDefault();
          return false;
        } else {
          parentEl.querySelector(
            'input[name=\'assignment_conversations\']'
          ).value = selectedConversations.join(',');
          return true;
        }
      });
  }
}
