import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    url: String,
    target: String
  };

  connect() {
    this.element.addEventListener('click', this.onClick.bind(this));
  }

  onClick() {
    if (this.urlValue) {
      let frame = document.querySelector(this.targetValue)
      frame.src = this.urlValue;
      frame.reload();
    }
  }
}