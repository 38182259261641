import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.element.querySelectorAll('.nav-item').forEach((navItem) => {
      navItem.addEventListener('click', () => {
        this.onTabClick(navItem);
      });
    });
  }

  onTabClick(tab) {
    // get first element with .nav-link class
    let navLink = tab.querySelector('.nav-link');
    if (navLink && navLink.id) {
      window.history.replaceState(
        null,
        null,
        `?tab=${navLink.id.replace('-tab', '')}`
      );
    }

    let dropdownItem = tab.querySelector('.dropdown-item');
    if (dropdownItem && dropdownItem.id) {
      window.history.replaceState(
        null,
        null,
        `?tab=${dropdownItem.id.replace('-tab', '')}`
      );
    }
  }
}
