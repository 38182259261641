import { Controller } from 'stimulus';

export default class extends Controller {
  static values = { hideSubscriberPlaceholders: Boolean, editor: String}
  static targets = ['name', 'fallback', 'button', 'input', 'select'];

  connect() {
    this.hideSubscriberPlaceholdersValue = this.hasHideSubscriberPlaceholdersValue ? this.hideSubscriberPlaceholdersValue : false
    let custom_variables = {
      '0': {
        'label': 'Custom Placeholder',
        'name': '',
        'category': 'custom',
        'fallback': '',
        'example': ''
      }
    }

    let subsciber_variables = {
      '1': {
        'label': 'Subscriber Name',
        'name': 'subscriber.name',
        'category': 'subscriber',
        'fallback': 'there',
        'example': 'John Doe'
      },
      '2': {
        'label': 'Subscriber First Name',
        'name': 'subscriber.first_name',
        'category': 'subscriber',
        'fallback': 'there',
        'example': 'John'
      },
      '3': {
        'label': 'Subscriber Last Name',
        'name': 'subscriber.last_name',
        'category': 'subscriber',
        'fallback': 'there',
        'example': 'Doe'
      }
    }

    this.variables = Object.assign(custom_variables, this.hideSubscriberPlaceholdersValue ? null : subsciber_variables)

    for (let key in this.variables) {
      let value = this.variables[key]

      let option = document.createElement('option')
      option.value = key
      option.text = value['label']
      this.selectTarget.appendChild(option)
    }
  }
  showModal() {
    let modalId = this.hasEditorValue ? this.editorValue + "_templateVariable" : 'templateVariable'
    let modal_element = document.querySelector('#' + modalId)
    this.modal = new bootstrap.Modal(modal_element, {
      keyboard: false,
    })
    this.modal.show()
  }

  insertString(e) {
    let value = e.target.dataset.value;
    this.insertValue(value)
  }

  insertValue(value) {
    if (this.hasInputTarget && this.inputTarget) {
      this.inputTarget.value = value;
      let componentController = this.application.getControllerForElementAndIdentifier(
        this.inputTarget,
        'component'
      );
      componentController.changeType();
    } else {
      let editorElement = this.editorElement();
      let editorController =
        this.application.getControllerForElementAndIdentifier(
          editorElement,
          'customize-trix'
        );
      editorController.insertString(value);
    }
  }

  resetValue() {
    this.fallbackTarget.value = ''
    this.nameTarget.value = ''
    this.buttonTarget.disabled = true
    this.nameTarget.disabled = false
  }

  editorElement() {
    if (this.hasEditorValue && this.editorValue) {
      return document.querySelector("#" + this.editorValue);
    }
    return document.querySelector('trix-editor');
  }

  insertCustomString() {
    if (this.hasNameTarget && this.hasFallbackTarget) {
      let isSubscriber = this.nameTarget.value.indexOf('subscriber') == 0
      let name = isSubscriber ? this.nameTarget.value : `campaign.${this.nameTarget.value.toLowerCase().replace(/\s+/g, "_").replace(/\W/g, "")}`
      let value = `{{ ${name} | fallback: '${this.fallbackTarget.value}' }}`
      this.insertValue(value)
      this.resetValue();
    }
  }

  validateCustomString() {
    if (this.hasNameTarget && this.hasFallbackTarget) {
      if (this.nameTarget.value.length > 0 && this.fallbackTarget.value.length > 0) {
        this.buttonTarget.disabled = false
      } else {
        this.buttonTarget.disabled = true
      }
    }
  }

  selected(e) {
    let value = e.target.value;
    if (value in this.variables) {
      if (this.hasNameTarget) {
        this.nameTarget.value = this.variables[value]['name']
        if (this.variables[value]['category'] == 'subscriber') {
          this.nameTarget.disabled = true;
        }
        else {
          this.nameTarget.disabled = false;
          this.nameTarget.focus();
        }
      }
      if (this.hasFallbackTarget) {
        this.fallbackTarget.value = this.variables[value]['fallback']
        this.fallbackTarget.focus();
      }
      if (this.hasExampleTarget)
        this.exampleTarget.value = this.variables[value]['example']

      this.buttonTarget.disabled = false
    } else {
      this.resetValue();
    }
  }
}
