import { Controller } from 'stimulus';
import StimulusReflex from 'stimulus_reflex';
import { BrowserOSChecker } from '../lib/device_exception_handler';
import { Turbo } from '@hotwired/turbo-rails';
import ahoy from 'ahoy.js';
import { setWidgetVideoCallStatus } from '../helpers/index';
import getDeviceInfo from '../utils/getDeviceInfo';
export default class extends Controller {
  static targets = [
    'tryingToConnect',
    'permissionWrapper',
    'availableAgent',
    'permissionButton',
    'submitButton',
    'participationForm',
    'successMessage',
    'failureMessage',
    'browserErrorMessage',
    'audioVideoSwitch',
    'audioSwitch',
    'audioHiddenInput',
    'permissionSlotBtn',
    'contentWrapper',
    'incomingForm',
  ];

  connect() {
    StimulusReflex.register(this);
    this.joinMode = this.element.dataset.joinMode || 'default';
    this.next_url = this.element.dataset.joinUrl;
    this.enable_popup = this.element.dataset.enablePopup;
    this.account_id = this.element.dataset.accountId;

    const params = new URLSearchParams(window.location.search);
    let is_popup = params.has('popup') && params.get('popup') == 'true';

    if (this.next_url && !is_popup && this.enable_popup == 'true') {
      let url = new URL(this.next_url);
      url.searchParams.set('popup', true);
      window.open(
        url.toString(),
        '',
        'toolbar=no,scrollbars=yes,resizable=yes,top=0,right=0,width=400px,height=768px'
      );
      window.parent.postMessage('gswidget:close', '*');
    }

    if (this.hasPermissionButtonTarget) {
      this.browserCheck();
      if (this.joinMode == 'default') this.checkPermissions();
    }
    if (this.hasTryingToConnectTarget) {
      this.setPageRefresh();
    }
    if (this.joinMode == 'new_incoming') {
      this.toggleNewIncoming();
    }
    this._handleSwitchListener();
  }

  disconnect() {
    if (this.pageRefreshId) window.clearInterval(this.pageRefreshId); // clearing interval when controller disconnects
  }

  pageReload() {
    window.location.reload();
  }

  setPageRefresh() {
    this.pageRefreshId = window.setInterval(this.pageReload, 5000); // 5secs
  }

  beforeShow() {
    document.querySelector('.loading-spinner').classList.remove('d-none');
  }

  afterShow(el) {
    document.querySelector('.loading-spinner').classList.add('d-none');
    const url = new URL(window.location);
    url.searchParams.set('vc_id', el.dataset.videoCallParticipationId);
    window.history.pushState({}, '', url);
  }

  beforeHide() {
    document.querySelector('.loading-spinner').classList.remove('d-none');
  }

  afterHide() {
    document.querySelector('.loading-spinner').classList.add('d-none');
  }

  showError() {
    document.querySelector('.loading-spinner').classList.add('d-none');
  }

  showHalted() {
    document.querySelector('.loading-spinner').classList.add('d-none');
  }

  hideError() {
    document.querySelector('.loading-spinner').classList.add('d-none');
  }

  hideHalted() {
    document.querySelector('.loading-spinner').classList.add('d-none');
  }

  show() {
    this.stimulate(
      'VideoCallParticipation#show',
      this.element.dataset.videoCallParticipationId
    );
  }

  hide() {
    this.stimulate(
      'VideoCallParticipation#hide',
      this.element.dataset.videoCallParticipationId
    );
  }

  onVideoCallChanged(e) {
    if (Object.prototype.hasOwnProperty.call(e.detail, 'status')) {
      if (e.detail.status == 'completed') setWidgetVideoCallStatus(false);
    }
    window.location.reload();
  }

  browserCheck() {
    const { isCompatible, message } =
      new BrowserOSChecker().compatibilityTest();
    this.is_compatible_browser = isCompatible;
    this.browser_error_message = message;
    if (!isCompatible) {
      this.permissionButtonTarget.disabled = true;
      this.permissionButtonTarget.style.display = 'none';
    }
    if (message) {
      this.browserErrorMessageTarget.style.display = 'block';
      this.browserErrorMessageTarget.innerText = message;
    }
  }

  checkPermissions() {
    if (!this.is_compatible_browser) return;
    let versions = new BrowserOSChecker().browserOSVersions();
    let ua = new BrowserOSChecker().userAgent();

    if (
      ua.match(
        /gumstack:ios|gumstack:android|gumstack:embed:android|gumstack:embed:ios/g
      )
    ) {
      console.log('[FLUTTER APP] UA: ', ua);
      console.log(
        '[FLUTTER APP] this.element.dataset',
        this.element.dataset,
        this.element.dataset.joinUrl
      );
      console.log('[FLUTTER APP] next_url: ', this.next_url);
      this.setPermissions(true);
      return;
    }
    var ignoreBrowserList = ['Safari', 'Mobile Safari', 'Chrome'];
    if (
      (ignoreBrowserList.includes(versions.browser.name) &&
        versions.os.name == 'iOS') ||
      (versions.browser.name == 'Safari' && versions.os.name == 'Mac OS')
    ) {
      return;
    } else {
      var audio = navigator.permissions.query({ name: 'microphone' });
      var video = navigator.permissions.query({ name: 'camera' });
      Promise.all([audio, video]).then((res) => {
        if (res[0].state == 'granted')
          this.setPermissions(
            true,
            this.hasAudioSwitchTarget ? res[1].state !== 'granted' : false
          );
      });
    }
  }

  showPermissions() {
    let gaController = this._googleAnalyticsController();
    if (gaController) {
      gaController.track({ event: 'granted_av_permissions' });
    }
    ahoy.track('begin_call', {
      account_id: this.account_id,
      screen: 'request_permission',
    });
    if (this.joinMode == 'new_incoming') {
      this.toggleNewIncoming();
      this.initPermissions();
    } else this.checkPermissions();
    if (this.hasPermissionWrapperTarget)
      this.permissionWrapperTarget.style.display = 'block';
    if (this.hasAvailableAgentTarget)
      this.availableAgentTarget.style.display = 'none';
    if (this.hasPermissionSlotBtnTarget) this.permissionSlotBtnTarget.remove();
    if (this.hasContentWrapperTarget) this.contentWrapperTarget.remove();
    if (this.hasSubmitButtonTarget) this.submitButtonTarget.remove();
    if (this.hasPermissionButtonTarget)
      this.permissionButtonTarget.style.display = 'block';
  }

  initPermissions() {
    let successCallback = () => {
      console.log('Permissions granted');
      this.setPermissions(true, this.audio_only());
    };
    let errorCallback = () => {
      console.log('Permissions denied');
      this.setPermissions(false);
    };

    if (this.hasPermissionButtonTarget) {
      this.permissionButtonTarget.disabled = true;
      this.permissionButtonTarget.innerText = 'Waiting for permissions...';
    }
    ahoy.track('grant_permission', {
      account_id: this.account_id,
      audio: true,
      video: !this.audio_only(),
    });
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: !this.audio_only() })
      .then(successCallback)
      .catch(errorCallback);
  }

  setPermissions(bool, audio_only = false) {
    if (this.hasAudioHiddenInputTarget) {
      this.audioHiddenInputTarget.value = audio_only;
    }
    console.log('granted permissions?', bool);
    if (bool) {
      this.permissionSuccessCallback(audio_only);
    } else {
      this.failureMessageTarget.style.display = 'block';
      this.successMessageTarget.style.display = 'none';
      if (this.hasPermissionButtonTarget) {
        this.permissionButtonTarget.disabled = false;
        this.permissionButtonTarget.innerText = 'Grant Permissions';
      }
    }

    let { browser, os, infoString } = getDeviceInfo();
    if (!this.element.dataset?.isHost) {
      ahoy.track('granted_permission', {
        account_id: this.account_id,
        audio: true,
        video: !audio_only,
        success: bool,
        screen: 'waiting_room',
        os: os.name,
        os_version: os.version,
        browser: browser.name,
        browser_version: browser.version,
        device_info: infoString,
      });
    }
    this.is_permissions_granted = bool;
  }

  permissionSuccessCallback(audio_only) {
    if (this.hasPermissionButtonTarget)
      this.permissionButtonTarget.disabled = true;
    this.successMessageTarget.style.display = 'block';
    this.failureMessageTarget.style.display = 'none';
    if (this.hasAudioHiddenInputTarget)
      this.audioHiddenInputTarget.value = audio_only;
    if (this.joinMode == 'show')
      document.querySelector('form#join_schedule_call').submit();
    else if (this.element.dataset.isHost == 'true') {
      let url = new URL(this.element.dataset.joinUrl, window.origin);
      url.searchParams.append('audio_only', audio_only);
      Turbo.visit(url?.toString());
    } else this.incomingFormTarget.submit();
    setWidgetVideoCallStatus(true);
  }

  toggleSwitch(mode) {
    if (this.hasAudioSwitchTarget && this.hasAudioVideoSwitchTarget) {
      let audio_only = mode == 'audio';
      this.audioVideoSwitchTarget.dataset.active = !audio_only;
      this.audioSwitchTarget.dataset.active = audio_only;
      this.element.dataset.audioMode = audio_only;
      if (this.hasAudioHiddenInputTarget)
        this.audioHiddenInputTarget.value = audio_only;
    }
  }

  audio_only() {
    if (this.hasAudioSwitchTarget) {
      return this.audioSwitchTarget.dataset.active == 'true';
    } else return false;
  }

  reloadParent() {
    window.parent.postMessage('page:reload', '*');
  }

  toggleNewIncoming() {
    let body = document.querySelector('body');
    if (!body) return;
    if (body.classList.contains('new_incoming'))
      body.classList.remove('new_incoming');
    else body.classList.add('new_incoming');
  }

  _handleSwitchListener() {
    if (this.hasAudioSwitchTarget && this.hasAudioVideoSwitchTarget) {
      this.audioSwitchTarget.addEventListener('click', () => {
        this.toggleSwitch('audio');
      });
      this.audioVideoSwitchTarget.addEventListener('click', () => {
        this.toggleSwitch('video');
      });
    }
  }

  _googleAnalyticsController() {
    if (document.querySelector('[data-ga-tracking-enabled="true"]') !== null) {
      return this.application.getControllerForElementAndIdentifier(
        this.element,
        'google-analytics'
      );
    }
  }
}
