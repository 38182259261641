import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'shareProduct',
    'productCarousel',
    'productCarouselWrapper',
    'productSlider',
    'notification',
    'productPanel',
    'activityForm',
    'activityInput',
    'activityListener',
  ];

  connect() {
    this._handleAgentProductSelection();
    this._handleAgentProductShare();
    this._handleProductAddedEvent();
    this._handleProductActivity();
    this.videoCallContainer = document.querySelector('#video-call-container');
  }

  openCarousel(e) {
    const image_id = e.target.dataset.imageId;
    const image_set_id = e.target.dataset.imageSetId;
    if (this.image_set_id == image_set_id) {
      this.handleCarouselImageChange(image_id);
      return;
    }
    const image_set = document.getElementById(image_set_id);
    const data = JSON.parse(image_set.dataset.images);
    this.image_set_id = image_set_id;
    const dom_images = data.map((e) => {
      return `
        <div 
          class="carousel-item ${e[0] == image_id ? 'active' : ''}"
          data-carousel-image-id="${e[0]}"
        >
         <img 
          src="${e[1]}" 
          class="object-fit-cover" 
          alt="Product Image" 
          width="100%" 
          height="100%" 
          />
        </div>
      `;
    });
    this.productCarouselTarget.innerHTML = dom_images;
    this.showCarousel();
  }

  handleCarouselImageChange(image_id) {
    const carousel_image = this.productCarouselTarget.querySelector(
      `[data-carousel-image-id="${image_id}"]`
    );
    const active_carousel_image = this.productCarouselTarget.querySelector(
      '.carousel-item.active'
    );
    if (carousel_image) {
      if (!carousel_image.classList.contains('active')) {
        carousel_image.classList.add('active');
        if (active_carousel_image) {
          active_carousel_image.classList.remove('active');
        }
      }
    }
    this.showCarousel();
  }

  showCarousel() {
    this.productCarouselWrapperTarget.classList.replace('d-none', 'd-flex');
  }

  hideCarousel() {
    this.productCarouselWrapperTarget.classList.replace('d-flex', 'd-none');
  }

  showLatestProduct() {
    if (!this.hasProductSliderTarget) return;
    let scrollWidth = this.productSliderTarget.scrollWidth;
    this.productSliderTarget.scrollTo(scrollWidth, 0);
  }

  addToCart(e) {
    let cartEvent = {
      name: 'gs:add-cart',
      data: {
        items: [
          {
            quantity: 1,
            id: e.target.dataset.variantId,
          },
        ],
      },
    };
    window.parent.postMessage(cartEvent, '*');
    this.hideCarousel();
    this.notifyAddedToCart();
    this.updateCart();
    this.hideCarousel();
    this.videoCallContainer.classList.remove('hide-actions');
  }

  updateCart() {
    const cart = document.getElementById('shopify_cart');
    if (!cart) return;
    let badgeEl = cart.querySelector('.notification-badge');
    if (!badgeEl) return;
    const badgeCount =
      badgeEl.textContent == '' ? 0 : parseInt(badgeEl.textContent);
    badgeEl.textContent = badgeCount + 1;
  }

  notifyAddedToCart() {
    if (this.hasNotificationTarget) {
      this.notificationTarget.classList.replace('d-none', 'd-flex');
      setTimeout(() => {
        this.notificationTarget.classList.replace('d-flex', 'd-none');
      }, 2500);
    }
  }

  selectProduct(e) {
    e.dataset.active = true;
    let product_sharing_input = document.getElementById('product_sharing_id');
    product_sharing_input.value = e.dataset.productId;
    let product_cards = document.querySelectorAll('.product_card');
    product_cards.forEach((el) => {
      if (e != el) {
        el.dataset.active = false;
      }
    });
  }

  sendActivity(e) {
    if (!this.hasActivityFormTarget || !this.hasActivityInputTarget) return;
    const data = e.target.dataset;
    let activity_data = {
      type: 'view',
      product_id: data.productId,
      image_id: data.imageId,
    };
    this.activityInputTarget.value = JSON.stringify(activity_data);
    this.activityFormTarget.requestSubmit();
  }

  performActivity() {
    try {
      let activity = JSON.parse(this.activityListenerTarget.dataset.activity);
      switch (activity.type) {
      case 'view':
        return this.highlightImage(activity);
      default:
        return;
      }
    } catch (error) {
      console.log(error);
    }
  }

  scrollToProduct(product_id) {
    let template = document.querySelector(`.template_${product_id}`);
    if (!template) return;
    template.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
  }

  highlightImage(activity) {
    if (this.activityListenerTarget.dataset.host != 'true') return;
    if(this.productPanelTarget.dataset.panelDisplay == 'true')
    this.scrollToProduct(activity.product_id);
    let active_image = document.querySelector('img.active');
    if (active_image) active_image.classList.remove('active');
    let image = document.querySelector(
      `[data-image-id="${activity.image_id}"]`
    );
    image.classList.add('active');
  }

  _handleAgentProductSelection() {
    let product_cards = document.querySelectorAll('.product_card');
    product_cards.forEach((el) => {
      el.addEventListener('click', () => {
        this.selectProduct(el);
      });
    });
  }

  _handleAgentProductShare() {
    if (this.hasShareProductTarget) {
      this.element.addEventListener('turbo:submit-start', () => {
        this.shareProductTarget.disabled = true;
        this.shareProductTarget.value = 'Sharing...';
      });

      this.element.addEventListener('turbo:submit-end', () => {
        this.shareProductTarget.disabled = false;
        this.shareProductTarget.value = 'Share again';
      });
    }
  }

  _handleProductAddedEvent() {
    let product_panel = document.querySelector('.product_panel_container');
    if (!product_panel) return;
    var observer = new MutationObserver((mutations) => {
      var show = true;
      for (const record of mutations) {
        if (record.removedNodes.length) show = false;
      }
      if (show) this.showLatestProduct();
    });
    observer.observe(product_panel, { childList: true });
  }

  _handleProductActivity() {
    if (!this.hasActivityListenerTarget) return;
    var observer = new MutationObserver(() => {
      this.performActivity();
    });
    observer.observe(this.activityListenerTarget, { attributes: true });
  }
}
