import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['value', 'image', 'video', 'document'];

  mediaSelected(e) {
    const mediaURL = e.detail.selected_url;
    if (this.hasImageTarget) {
      this.imageTarget.src = mediaURL;
    }
    if (this.hasVideoTarget) {
      this.videoTarget.src = mediaURL;
      this.videoTarget.parentElement.load();
    }
    if (this.hasDocumentTarget) {
      this.documentTarget.src = mediaURL;
    }
    this.valueTarget.value = mediaURL;
  }
}
