import { UAParser } from 'ua-parser-js';

export default function getDeviceInfo() {
  const parser = new UAParser();
  const browser = parser.getBrowser();
  const os = parser.getOS();
  const device = parser.getDevice();
  let infoString = `${browser.name} ${browser.version.split('.')[0]}`;

  if (device.vendor) {
    infoString += ` on ${device.vendor} ${device.model} - ${os.name} ${
      os.version.split('.')[0]
    }`;
  } else {
    infoString += ` on ${os.name} ${os.version.split('.')[0]}`;
  }

  return { browser, os, device, infoString };
}

export function isSafariOnDesktop() {
  let userAgent = navigator.userAgent;
  let is_mobile =
    /Android|iPhone|iPad|Windows Phone|Opera Mobi|Kindle|BackBerry|PlayBook/i.test(
      userAgent
    );
  let chrome = userAgent.indexOf('Chrome') > -1;
  let safari = userAgent.indexOf('Safari') > -1;
  // Chrome user agent will also include safari agent so the below check is needed
  if (chrome && safari) safari = false;
  return !is_mobile && safari;
}
