import moment from 'moment';
import { Controller } from 'stimulus';
import { lightOrDark } from '../helpers';
import ahoy from 'ahoy.js';

const SLOT_PICKER_CONTAINER_TEMPLATE = `
<div class="slot-picker--dropdown mt-2" data-target="date-slot-picker.dropdown">
  <div class='slot-picker--container' data-target='date-slot-picker.root'>
  </div>
</div>
`;

const DATE_PICKER_CONTAINER_TEMPLATE = `
  <div class="slot-picker--datesContainer p-2 mt-1 mb-3 d-flex justify-content-around">
    <a role="button" style="cursor: pointer;" class="text-decoration-none p-md-2 p-sm-0 d-flex align-items-center justify-content-center  " data-target='date-slot-picker.previousBtn' data-action="date-slot-picker#previous"><span class="material-icons">chevron_left</span></a>
    <div data-target="date-slot-picker.dates" class="d-flex flex-fill justify-content-around dates-container"></div>
    <a role="button" style="cursor: pointer;" class="text-decoration-none p-md-2 p-sm-0 d-flex align-items-center justify-content-center" data-target="date-slot-picker.nextBtn" data-action="date-slot-picker#next"><span class="material-icons">chevron_right</span></a>
  </div>
`;

const SLOT_PICKER_DAY_SLOTS_TEMPLATE = `
<div class="slot-picker--dayslots" data-target="date-slot-picker.slots">
  <div class="row px-4 justify-content-end align-items-center d-none toggle-office-hours-container">
  <label for="toggle_office_hours" class="mr-3 font-weight-normal text-capitalize">Show only office hours</label>
    <label class="switch float-right">
      <input type="checkbox" class="toggle_office_hours" name="toggle_office_hours" data-controller="session-creation" data-action="change->session-creation#toggle_ignore_office_hours" data-slot-picker-parent-selector="#slot_picker_parent">
      <span class="slider round"></span>
    </label>
  </div>

  <div class="slot-picker-grid" data-target="date-slot-picker.slotsGrid">
    <div class='slot-picker--morning'>
      <label>Morning</label>
      <div class='slot-picker--grid slot-picker--time' data-target='date-slot-picker.morningSlots'>
      </div>
    </div>
    <div class='slot-picker--afternoon'>
      <label>Afternoon</label>
      <div class='slot-picker--grid slot-picker--time' data-target='date-slot-picker.afternoonSlots'>
      </div>
    </div>
    <div class='slot-picker--evening'>
      <label>Evening</label>
      <div class='slot-picker--grid slot-picker--time' data-target='date-slot-picker.eveningSlots'>
      </div>
    </div>
    <div class='slot-picker--night'>
      <label>Night</label>
      <div class='slot-picker--night slot-picker--grid slot-picker--time' data-target='date-slot-picker.nightSlots'>
      </div>
    </div>
  </div>
</div>
`;

const SLOT_CELL_TEMPLATE = `
<a href="#" class="slot-picker--cell" role="button" onclick="event.stopPropagation();" data-action="click->date-slot-picker#selectSlot"></a>
`;

const DATE_CELL_TEMPLATE = `
<button onclick="event.stopPropagation();" data-action="click->date-slot-picker#selectDate" class="text-center btn fw-bold fs-12 border border-secondary rounded p-3 py-4">
  <div class="day text-uppercase mb-1 penone"></div>
  <div class="date penone"></div>
</button>
`;

class DateSlotPickerController extends Controller {
  static targets = [
    'input',
    'toggleButton',
    'dropdown',
    'root',
    'dates',
    'slots',
    'morningSlots',
    'afternoonSlots',
    'eveningSlots',
    'nightSlots',
    'currMonth',
    'currDate',
    'backToMonthBtn',
    'submitBtn',
    'previousBtn',
    'nextBtn',
  ];
  static values = { brandColor: String };

  connect() {
    if (this.element.querySelector('#slot-picker--pickerButton')) {
      return;
    }
    this.element.classList.add('slot-picker');
    this.showToggleOfficeHours =
      this.element.dataset.showToggleOfficeHours === 'true';
    this.ignoreOfficeHours = this.element.dataset.ignoreOfficeHours === 'true';
    this.hideCalendar = this.element.dataset.hideCalendar === 'true';
    this.autoSubmit = this.element.dataset.autoSubmit === 'true';
    this.utcOffset = this.element.dataset.utc;
    let selected_date = this.element.dataset.selectedDate;
    if (!moment.isMoment(selected_date)) {
      selected_date = moment(selected_date);
    }
    this.selected_time = selected_date.format('h:mm A');
    this.selected_date = selected_date.toDate().toDateString();
    let tmpEl = document.createElement('div');
    tmpEl.innerHTML = SLOT_PICKER_CONTAINER_TEMPLATE;
    this.element.appendChild(tmpEl.firstElementChild);

    let dateEl = document.createElement('div');
    dateEl.innerHTML = DATE_PICKER_CONTAINER_TEMPLATE;
    this.element.prepend(dateEl.firstElementChild);

    this.availability =
      this.element.dataset.json != 'null'
        ? this.loadAvailability(this.element.dataset.json)
        : null;

    this.startIndex = 0;
    this.endIndex = 3;

    this.selectionTargetValue = this.element.dataset.selectionTargetValue;
    this.selectionTargetDisplay = this.element.dataset.selectionTargetDisplay;
    let startDate = moment();
    this.startDate = moment();
    this.data.set('month', startDate.date(1).format('YYYY-MM-DD'));
    this.endDate = moment().add(this.visibilityDays(), 'days');
    this.availability && this.renderDates();
    this.rootTarget.innerHTML = SLOT_PICKER_DAY_SLOTS_TEMPLATE;
    this.renderSlots(this.selected_date);

    if (this.hideCalendar) {
      this.hideWidget();
    } else {
      this.showWidget();
    }
  }

  disconnect() {
    console.log('Disconnecting Slot Picker Controller!');
  }

  toggleWidget(e) {
    e.preventDefault();
    if (this.element.dataset.widgetState == 'opened') {
      this.hideWidget();
    } else {
      this.showWidget();
    }
  }

  visibilityDays() {
    try {
      let vis = parseInt(this.element.dataset.visibilitydays);
      if (isNaN(vis)) {
        return 45;
      }
      return vis;
    } catch (e) {
      return 45;
    }
  }

  loadAvailability(json) {
    let slots = {};
    JSON.parse(json).forEach((slot) => {
      slots[new Date(slot.start).toDateString()] =
        slots[new Date(slot.start).toDateString()] || [];
      slots[new Date(slot.start).toDateString()].push(slot);
    });
    return slots;
  }

  showWidget() {
    // this.toggleButtonTarget.innerText = 'Close'
    this.element.dataset.widgetState = 'opened';
    this.dropdownTarget.classList.add('show');
  }

  hideWidget() {
    // this.toggleButtonTarget.innerText = 'Open'
    this.element.dataset.widgetState = 'closed';
    this.dropdownTarget.classList.remove('show');
  }

  previous(e) {
    e.preventDefault();
    this.startIndex -= 3;
    this.endIndex -= 3;
    this.renderDates();
    // this.hideWidget();
  }

  next(e) {
    e.preventDefault();
    this.startIndex += 3;
    this.endIndex += 3;
    this.renderDates();
    // this.hideWidget();
  }

  toggleOfficeHours() {
    let officeHourContainers = this.element
      .getRootNode()
      .querySelectorAll('.toggle-office-hours-container');
    if (this.showToggleOfficeHours) {
      officeHourContainers.forEach((item) => item.classList.remove('d-none'));
    }
    officeHourContainers.forEach(
      (item) =>
        (item.querySelector('.toggle_office_hours').checked =
          !this.ignoreOfficeHours)
    );
  }

  renderDates() {
    this.toggleOfficeHours();
    this.datesTarget.innerHTML = '';
    let keys = Object.keys(this.availability);
    let dates = keys.slice(this.startIndex, this.endIndex);
    let counter = 0;
    while (counter < dates.length) {
      let date = dates[counter];
      if (!moment.isMoment(date)) {
        date = moment(date);
      }
      let tmpEl = document.createElement('div');
      let date_string = date.toDate().toDateString();

      tmpEl.innerHTML = DATE_CELL_TEMPLATE;
      let btn = tmpEl.firstElementChild;
      btn.dataset.date = date_string;
      if (this.selected_date === date_string) {
        btn.classList.add('active');
        btn.classList.remove('btn-light');
        btn.classList.remove('border-secondary');
        console.log(this.brandColorValue);
        btn.style.backgroundColor = this.brandColorValue;
        btn.style.color =
          lightOrDark(this.brandColorValue) === 'light' ? '#333333' : '#ffffff';
      }
      let dayEl = btn.getElementsByClassName('day')[0];
      let dateEl = btn.getElementsByClassName('date')[0];
      dayEl.innerText = date.format('ddd');
      dateEl.innerText = date.format('MMM DD');
      btn.disabled = false;
      this.datesTarget.appendChild(btn);
      counter += 1;
    }

    if (this.startIndex <= 0) {
      this.previousBtnTarget.classList.add('penone', 'text-light');
    } else {
      this.previousBtnTarget.classList.remove('penone', 'text-light');
    }

    if (this.endIndex >= keys.length) {
      this.nextBtnTarget.classList.add('penone', 'text-light');
    } else {
      this.nextBtnTarget.classList.remove('penone', 'text-light');
    }
  }

  renderSlots(date) {
    if (!this.availability[date]) {
      return;
    }
    this.toggleOfficeHours();
    let slotsCount = [
      { slot: this.morningSlotsTarget, count: 0 },
      { slot: this.afternoonSlotsTarget, count: 0 },
      { slot: this.eveningSlotsTarget, count: 0 },
      { slot: this.nightSlotsTarget, count: 0 },
    ];
    this.availability[date].forEach((slot) => {
      let slotTime = moment(slot.start).utcOffset(this.utcOffset);
      let tmpEl = document.createElement('div');
      tmpEl.innerHTML = SLOT_CELL_TEMPLATE;
      let slotBtn = tmpEl.firstElementChild;
      slotBtn.innerHTML = slotTime.format('h:mm');
      slotBtn.dataset.slot = slot.start;
      if (slotTime.format('h:mm A') == this.selected_time) {
        slotBtn.classList.add('active');
      }
      if (slotTime.hour() < 12) {
        this.morningSlotsTarget.appendChild(slotBtn);
        slotsCount.find((item) => item.slot === this.morningSlotsTarget)
          .count++;
      }
      if (slotTime.hour() >= 12 && slotTime.hour() < 16) {
        this.afternoonSlotsTarget.appendChild(slotBtn);
        slotsCount.find((item) => item.slot === this.afternoonSlotsTarget)
          .count++;
      }
      if (slotTime.hour() >= 16 && slotTime.hour() < 19) {
        this.eveningSlotsTarget.appendChild(slotBtn);
        slotsCount.find((item) => item.slot === this.eveningSlotsTarget)
          .count++;
      }
      if (slotTime.hour() >= 19) {
        this.nightSlotsTarget.appendChild(slotBtn);
        slotsCount.find((item) => item.slot === this.nightSlotsTarget).count++;
      }
      // this.slotsGridTarget.appendChild(slotBtn);
    });
    slotsCount.forEach((item) => {
      if (item.count == 0) {
        item.slot.parentNode.classList.add('d-none');
      }
    });
  }

  selectDate(e) {
    e.preventDefault();
    let selected_date = e.target.dataset.date;
    if (!moment.isMoment(selected_date)) {
      selected_date = moment(selected_date);
    }
    this.selected_date = selected_date.toDate().toDateString();

    // let date = e.target.dataset.date;
    // console.log(date)
    // this.selected_date = date;
    this.rootTarget.innerHTML = SLOT_PICKER_DAY_SLOTS_TEMPLATE;
    this.renderDates();
    this.renderSlots(this.selected_date);
    this.showWidget();
  }

  selectSlot(e) {
    e.preventDefault();
    let slot = e.target.dataset.slot;
    let valueEl = document.querySelector(this.selectionTargetValue);
    if (valueEl) {
      valueEl.value = slot;
    }
    ahoy.track('selected_slot', {
      account_id: this.element.dataset.accountId,
      screen: 'complete_scheduling',
      slot: slot,
    });
    this.selected_time = moment(slot).format('h:mm A');
    Array.from(document.querySelectorAll('.slot-picker--cell.active')).forEach(
      function (el) {
        el.classList.remove('active');
        el.style.color = '';
      }
    );
    e.target.classList.add('active');
    e.target.style.color = this.brandColorValue;
    let displayEl = document.querySelector(this.selectionTargetDisplay);
    if (displayEl) {
      displayEl.value = moment(slot).format('h:mm A');
      displayEl.dispatchEvent(new Event('change'));
    }
    // this.hideWidget();

    if (this.autoSubmit) {
      this.submitBtnTarget.click();
    }
    const widgetVideoCallDom = document.getElementById('widget_video_call');
    if (widgetVideoCallDom) {
      const widgetVideoCallController =
        this.application.getControllerForElementAndIdentifier(
          widgetVideoCallDom,
          'widget-video-call'
        );
      widgetVideoCallController.showRegistrationForm();
    }
  }

  get startDate() {
    return moment(this.data.get('startDate'));
  }

  get endDate() {
    return moment(this.data.get('endDate'));
  }

  set endDate(d) {
    if (!moment.isMoment(d)) {
      d = moment(d);
    }
    this.data.set('endDate', d);
  }

  set startDate(d) {
    if (!moment.isMoment(d)) {
      d = moment(d);
    }
    this.data.set('startDate', d);
  }

  diffMonths(d1, d2) {
    let diff = d1.month() - d2.month() + 12 * (d1.year() - d2.year());
    return diff;
  }
}

export default DateSlotPickerController;
