import { Controller } from 'stimulus';
import moment from 'moment';

export default class extends Controller {
  static targets = ['input'];

  connect() {}

  changeType() {
    const value = this.inputTarget.value;
    if (value.indexOf('http://') === 0 || value.indexOf('https://') === 0) {
      this.inputTarget.type = 'url';
    } else if (value.indexOf('{{') === 0) {
      this.inputTarget.type = 'text';
    }
  }

  resetDateTimeField(e) {
    if (e.target.checked) {
      this.inputTarget.classList.remove('d-none');
      this.inputTarget.value = moment(new Date()).add(7, 'days').format();
    } else {
      this.inputTarget.classList.add('d-none');
      this.inputTarget.value = '';
    }
  }
}
