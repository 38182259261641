import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['checkboxAll', 'checkbox', 'action', 'counter'];
  static values = { selected: Array };

  initialize() {
    this.toggle = this.toggle.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  checkboxAllTargetConnected(checkbox) {
    checkbox.addEventListener('change', this.toggle);

    this.refresh();
  }

  checkboxTargetConnected(checkbox) {
    checkbox.addEventListener('change', this.refresh);

    this.refresh();
  }

  checkboxAllTargetDisconnected(checkbox) {
    checkbox.removeEventListener('change', this.toggle);

    this.refresh();
  }

  checkboxTargetDisconnected(checkbox) {
    checkbox.removeEventListener('change', this.refresh);

    this.refresh();
  }

  toggle(e) {
    e.preventDefault();

    this.checkboxTargets.forEach((checkbox) => {
      // @ts-ignore
      checkbox.checked = e.target.checked;
      this.triggerInputEvent(checkbox);
    });

    this.refreshAction();
  }

  refresh() {
    const checkboxesCount = this.checkboxTargets.length;
    const checkboxesCheckedCount = this.checked.length;

    this.refreshAction();
    this.checkboxAllTarget.checked = checkboxesCheckedCount > 0;
    this.checkboxAllTarget.indeterminate =
      checkboxesCheckedCount > 0 && checkboxesCheckedCount < checkboxesCount;
  }

  refreshAction() {
    if (this.hasSelectedValue) {
      this.selectedValue = this.getSelectedValues();
    }
    const checkboxesCheckedCount = this.checked.length;
    if (this.hasCounterTarget) {
      if (checkboxesCheckedCount == 0) {
        this.counterTarget.innerText = 'Select';
      } else {
        this.counterTarget.innerText = `${checkboxesCheckedCount} conversations selected`;
      }
    }
    if (this.hasActionTarget) {
      if (checkboxesCheckedCount == 0) {
        this.actionTarget.classList.add('d-none');
      } else {
        this.actionTarget.classList.remove('d-none');
      }
    }
  }

  getSelectedValues() {
    return this.checked.map((checkbox) => checkbox.value);
  }

  triggerInputEvent(checkbox) {
    const event = new Event('input', { bubbles: false, cancelable: true });

    checkbox.dispatchEvent(event);
  }

  get checked() {
    return this.checkboxTargets.filter((checkbox) => checkbox.checked);
  }

  get unchecked() {
    return this.checkboxTargets.filter((checkbox) => !checkbox.checked);
  }
}
