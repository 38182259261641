import moment from 'moment';
import { Controller } from 'stimulus';
import Analytics from '../lib/analytics';

const SLOT_PICKER_BUTTON_TEMPLATE = `
<button class="slot-picker--toggleButton" data-target="slot-picker.toggleButton" data-action="slot-picker#toggleWidget" id='slot-picker--pickerButton'>
  <i class="material-icons">event</i>
</button>
`;

const SLOT_PICKER_CONTAINER_TEMPLATE = `
<div class="slot-picker--dropdown mt-2" data-target="slot-picker.dropdown">
  <div class='slot-picker--container bg-light border px-2' data-target='slot-picker.root'>
  </div>
</div>
`;

const SLOT_PICKER_CALENDAR_TEMPLATE = `
<div class="slot-picker--calendar" data-target='slot-picker.calendar'>
  <div class="slot-picker--header">
    <button class="p-2 d-flex align-items-center justify-content-center slot-picker--prev-month" data-target='slot-picker.prevMonthBtn' data-action="slot-picker#prevMonth"><span class="material-icons">chevron_left</span></button>
    <span data-target="slot-picker.currMonth"></span>
    <button class="p-2 d-flex align-items-center justify-content-center" data-target="slot-picker.nextMonthBtn" data-action="slot-picker#nextMonth"><span class="material-icons">chevron_right</span></button>
  </div>

  <div class="row px-4 justify-content-end align-items-center d-none toggle-office-hours-container">
    <label for="toggle_office_hours" class="mr-3 font-weight-normal text-capitalize">Show only office hours</label>
    <label class="switch float-right">
      <input type="checkbox" class="toggle_office_hours" name="toggle_office_hours" data-controller="session-creation" data-action="change->session-creation#toggle_ignore_office_hours" data-slot-picker-parent-selector="#slot_picker_parent">
      <span class="slider round"></span>
    </label>
  </div>

  <div class="slot-picker--grid-days days-of-week" style="">
    <span class="slot-picker--cell">Mo</span>
    <span class="slot-picker--cell">Tu</span>
    <span class="slot-picker--cell">We</span>
    <span class="slot-picker--cell">Th</span>
    <span class="slot-picker--cell">Fr</span>
    <span class="slot-picker--cell">Sa</span>
    <span class="slot-picker--cell">Su</span>
  </div>
  <div class="slot-picker--grid-days" data-target="slot-picker.grid">
  </div>
</div>
`;

const SLOT_PICKER_DAY_SLOTS_TEMPLATE = `
<div class="slot-picker--dayslots" data-target="slot-picker.slots">
  <div class="slot-picker--header">
    <button class="p-2 d-flex align-items-center justify-content-center" data-target='slot-picker.backToMonthBtn' data-action="slot-picker#backToMonth"><span class="material-icons">arrow_back</span></button>
    <span data-target="slot-picker.currDate"></span>
    <span></span>
  </div>

  <div class="row px-4 justify-content-end align-items-center d-none toggle-office-hours-container">
  <label for="toggle_office_hours" class="mr-3 font-weight-normal text-capitalize">Show only office hours</label>
    <label class="switch float-right">
      <input type="checkbox" class="toggle_office_hours" name="toggle_office_hours" data-controller="session-creation" data-action="change->session-creation#toggle_ignore_office_hours" data-slot-picker-parent-selector="#slot_picker_parent">
      <span class="slider round"></span>
    </label>
  </div>

  <div class="" data-target="slot-picker.slotsGrid">
    <div class='slot-picker--morning'>
      <span class='slot-picker--morning-title'>Morning</span>
      <div class='slot-picker--grid slot-picker--time' data-target='slot-picker.morningSlots'>
      </div>
    </div>
    <div class='slot-picker--afternoon'>
      <span class='slot-picker--afternoon-title'>Afternoon</span>
      <div class='slot-picker--grid slot-picker--time' data-target='slot-picker.afternoonSlots'>
      </div>
    </div>
    <div class='slot-picker--evening'>
      <span class='slot-picker--evening-title'>Evening</span>
      <div class='slot-picker--grid slot-picker--time' data-target='slot-picker.eveningSlots'>
      </div>
    </div>
    <div class='slot-picker--night'>
      <span class='slot-picker--night-title'>Night</span>
      <div class='slot-picker--night slot-picker--grid slot-picker--time' data-target='slot-picker.nightSlots'>
      </div>
    </div>
  </div>
</div>
`;

const SLOT_CELL_TEMPLATE = `
<a href="#" class="slot-picker--cell" role="button" data-action="click->slot-picker#selectSlot"></a>
`;

const DATE_CELL_TEMPLATE = `
<a href="#" class="slot-picker--cell" role="button" data-action="click->slot-picker#selectDate"></a>
`;

const NO_SLOTS_CELL_TEMPLATE = `
<span class="slot-picker--cell">Slots not available</span>
`;

class CalGridController extends Controller {
  static targets = [
    'input',
    'toggleButton',
    'dropdown',
    'root',
    'calendar',
    'grid',
    'slots',
    'morningSlots',
    'afternoonSlots',
    'eveningSlots',
    'nightSlots',
    'currMonth',
    'currDate',
    'prevMonthBtn',
    'nextMonthBtn',
    'backToMonthBtn',
    'submitBtn',
  ];

  connect() {
    if (this.element.querySelector('#slot-picker--pickerButton')) {
      return;
    }
    this.element.classList.add('slot-picker');
    this.showToggleOfficeHours =
      this.element.dataset.showToggleOfficeHours === 'true';
    this.ignoreOfficeHours = this.element.dataset.ignoreOfficeHours === 'true';
    this.hideCalendar = this.element.dataset.hideCalendar === 'true';
    this.autoSubmit = this.element.dataset.autoSubmit === 'true';
    let tmpEl = document.createElement('div');
    tmpEl.innerHTML = SLOT_PICKER_BUTTON_TEMPLATE;
    this.element.appendChild(tmpEl.firstElementChild);

    tmpEl.innerHTML = SLOT_PICKER_CONTAINER_TEMPLATE;
    this.element.appendChild(tmpEl.firstElementChild);

    this.availability =
      this.element.dataset.json != 'null'
        ? this.loadAvailability(this.element.dataset.json)
        : null;
    this.selectionTargetValue = this.element.dataset.selectionTargetValue;
    this.selectionTargetDisplay = this.element.dataset.selectionTargetDisplay;
    let startDate = moment();
    this.startDate = moment();
    this.data.set('month', startDate.date(1).format('YYYY-MM-DD'));
    this.endDate = moment().add(this.visibilityDays(), 'days');
    this.availability && this.renderCalendar();

    if (this.hideCalendar) {
      this.hideWidget();
    } else {
      this.showWidget();
    }
    this.addFocusOutListener();
  }

  disconnect() {
    console.log('Disconnecting Slot Picker Controller!');
  }

  renderCalendar() {
    this.rootTarget.innerHTML = SLOT_PICKER_CALENDAR_TEMPLATE;
    this.makeCalendar();
  }

  toggleWidget(e) {
    e.preventDefault();
    if (this.element.dataset.widgetState == 'opened') {
      this.hideWidget();
    } else {
      this.showWidget();
    }
  }

  visibilityDays() {
    try {
      let vis = parseInt(this.element.dataset.visibilitydays);
      if (isNaN(vis)) {
        return 45;
      }
      return vis;
    } catch (e) {
      return 45;
    }
  }

  loadAvailability(json) {
    let slots = {};
    JSON.parse(json).forEach((slot) => {
      slots[new Date(slot.start).toDateString()] =
        slots[new Date(slot.start).toDateString()] || [];
      slots[new Date(slot.start).toDateString()].push(slot);
    });
    return slots;
  }

  showWidget() {
    // this.toggleButtonTarget.innerText = 'Close'
    this.element.dataset.widgetState = 'opened';
    this.dropdownTarget.classList.add('show');
  }

  hideWidget() {
    // this.toggleButtonTarget.innerText = 'Open'
    this.element.dataset.widgetState = 'closed';
    this.dropdownTarget.classList.remove('show');
  }

  prevMonth(e) {
    e.preventDefault();
    this.currMoment = this.currMoment.add(-1, 'month');
    this.makeCalendar();
  }

  nextMonth(e) {
    e.preventDefault();
    this.currMoment = this.currMoment.add(1, 'month');
    this.makeCalendar();
  }

  toggleOfficeHours() {
    let officeHourContainers = this.element
      .getRootNode()
      .querySelectorAll('.toggle-office-hours-container');
    if (this.showToggleOfficeHours) {
      officeHourContainers.forEach((item) => item.classList.remove('d-none'));
    }
    officeHourContainers.forEach(
      (item) =>
        (item.querySelector('.toggle_office_hours').checked =
          !this.ignoreOfficeHours)
    );
  }

  makeCalendar() {
    let d = this.currMoment;
    if (this.diffMonths(d, this.startDate) <= 0) {
      this.prevMonthBtnTarget.disabled = true;
    } else {
      this.prevMonthBtnTarget.disabled = false;
    }
    if (this.diffMonths(d, this.endDate) >= 0) {
      this.nextMonthBtnTarget.disabled = true;
    } else {
      this.nextMonthBtnTarget.disabled = false;
    }
    this.currMonthTarget.innerText = d.format('MMM YYYY');
    this.gridTarget.innerHTML = '';
    this.gridTarget.style.setProperty('--month-offset', d.isoWeekday());
    this.renderDates(d);
    var self = this;
  }

  renderDates(d) {
    this.toggleOfficeHours();
    for (let i = 1; i <= d.daysInMonth(); i++) {
      let tmpEl = document.createElement('div');
      let daySlots = this.availability[d.date(i).toDate().toDateString()];
      if (daySlots && daySlots.length > 0) {
        tmpEl.innerHTML = DATE_CELL_TEMPLATE;
      } else {
        tmpEl.innerHTML = NO_SLOTS_CELL_TEMPLATE;
      }
      let btn = tmpEl.firstElementChild;

      btn.dataset.date = d.date(i).toDate().toDateString();
      btn.innerText = i;
      this.gridTarget.appendChild(btn);
    }
  }

  renderSlots(date) {
    if (!this.availability[date]) {
      return;
    }
    this.toggleOfficeHours();
    let slotsCount = [
      { slot: this.morningSlotsTarget, count: 0 },
      { slot: this.afternoonSlotsTarget, count: 0 },
      { slot: this.eveningSlotsTarget, count: 0 },
      { slot: this.nightSlotsTarget, count: 0 },
    ];
    this.availability[date].forEach((slot) => {
      let slotTime = moment(slot.start);
      let tmpEl = document.createElement('div');
      tmpEl.innerHTML = SLOT_CELL_TEMPLATE;
      let slotBtn = tmpEl.firstElementChild;
      slotBtn.innerHTML = slotTime.format('h:mm');
      slotBtn.dataset.slot = slot.start;
      if (slotTime.hour() < 12) {
        this.morningSlotsTarget.appendChild(slotBtn);
        slotsCount.find((item) => item.slot === this.morningSlotsTarget)
          .count++;
      }
      if (slotTime.hour() >= 12 && slotTime.hour() < 16) {
        this.afternoonSlotsTarget.appendChild(slotBtn);
        slotsCount.find((item) => item.slot === this.afternoonSlotsTarget)
          .count++;
      }
      if (slotTime.hour() >= 16 && slotTime.hour() < 19) {
        this.eveningSlotsTarget.appendChild(slotBtn);
        slotsCount.find((item) => item.slot === this.eveningSlotsTarget)
          .count++;
      }
      if (slotTime.hour() >= 19) {
        this.nightSlotsTarget.appendChild(slotBtn);
        slotsCount.find((item) => item.slot === this.nightSlotsTarget).count++;
      }
      // this.slotsGridTarget.appendChild(slotBtn);
    });
    slotsCount.forEach((item) => {
      if (item.count == 0) {
        item.slot.parentNode.classList.add('d-none');
      }
    });
  }

  selectDate(e) {
    e.preventDefault();
    let date = e.target.dataset.date;
    this.rootTarget.innerHTML = SLOT_PICKER_DAY_SLOTS_TEMPLATE;
    this.currDateTarget.innerHTML = moment(date).format('DD MMM, YYYY');
    this.renderSlots(date);
    this.backToMonthBtnTarget.dataset.date = date;
  }

  selectSlot(e) {
    e.preventDefault();
    let slot = e.target.dataset.slot;
    let valueEl = document.querySelector(this.selectionTargetValue);
    if (valueEl) {
      valueEl.value = slot;
    }
    let displayEl = document.querySelector(this.selectionTargetDisplay);
    if (displayEl) {
      displayEl.value = moment(slot).format('ddd DD MMM, h:mm a');
      displayEl.dispatchEvent(new Event('change'));
    }
    this.hideWidget();

    if (this.autoSubmit) {
      this.submitBtnTarget.click();
      new Analytics().track('selected_slot', {
        slot,
        registrable_id: this.element.dataset.registrableId,
        account_id: this.element.dataset.accountId,
      });
    }
  }

  backToMonth(e) {
    e.preventDefault();
    let date = e.target.dataset.date;
    this.renderCalendar();
  }

  addFocusOutListener() {
    // var self = this;
    // this.element.addEventListener('focusout', (event)=> {
    // if (!self.element.contains(event.relatedTarget)) {
    // self.hideWidget();
    // }
    // });
  }

  get currMoment() {
    return moment(this.data.get('month'));
  }

  set currMoment(m) {
    if (moment.isMoment(m)) {
      this.data.set('month', m.format('YYYY-MM-DD'));
    } else {
      this.currMoment = moment(m);
    }
  }

  get startDate() {
    return moment(this.data.get('startDate'));
  }

  get endDate() {
    return moment(this.data.get('endDate'));
  }

  set endDate(d) {
    if (!moment.isMoment(d)) {
      d = moment(d);
    }
    this.data.set('endDate', d.endOf('month'));
  }

  set startDate(d) {
    if (!moment.isMoment(d)) {
      d = moment(d);
    }
    this.data.set('startDate', d);
  }

  diffMonths(d1, d2) {
    let diff = d1.month() - d2.month() + 12 * (d1.year() - d2.year());
    return diff;
  }
}

export default CalGridController;
