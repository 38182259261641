import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'distance',
    'timeRemainingWrapper',
    'timeRemaining',
    'redirectBtn',
  ];
  connect() {
    const timerElement = this.timeRemainingTarget;
    const redirectBtn = this.redirectBtnTarget;
    const timeRemainingWrapper = this.timeRemainingWrapperTarget;
    var distance = parseInt(this.distanceTarget.value) - 1000;
    var x = setInterval(function () {
      if (distance <= 0) {
        clearInterval(x);
        timeRemainingWrapper.classList.add('d-none');
        redirectBtn.classList.remove('d-none');
      }
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      timerElement.innerHTML = seconds + ' seconds';
      distance = distance - 1000;
    }, 1000);
  }
}
