import moment from 'moment';
import initIntlTelephoneInput from '../lib/telephone_input';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'scheduleModeSelect',
    'scheduleModeConfig',
    'deliveryModeSelect',
    'deliveryModeConfig',
    'scheduledLaunchTime',
    'scheduledStartDate',
    'scheduledEndDate',
    'launchTime',
    'receivers',
  ];

  connect() {
    if (this.hasScheduleModeSelectTarget) {
      this.changeScheduleMode();
      if (this.scheduledLaunchTimeTarget.value) {
        this._changeScheduledLaunchTime(this.scheduledLaunchTimeTarget.value);
      }
      this.changeDeliveryMode();
    }
    if (this.hasReceiversTarget) {
      this.initReceiversField();
    }
  }

  changeScheduleMode() {
    if (this.scheduleModeSelectTarget.value == 'instant') {
      this.scheduleModeConfigTarget.classList.add('d-none');
      this.scheduleModeConfigTarget
        .querySelector('input[type=datetime-local]')
        .removeAttribute('required');
      this.resetDefaultDeliveryTimes();
    } else {
      this.scheduleModeConfigTarget.classList.remove('d-none');
      this.scheduleModeConfigTarget
        .querySelector('input[type=datetime-local]')
        .setAttribute('required', true);
    }
  }

  changeDeliveryMode() {
    if (this.deliveryModeSelectTarget.value == 'bulk') {
      this.deliveryModeConfigTarget.classList.add('d-none');
      this.deliveryModeConfigTarget
        .querySelectorAll('input[type=date]')[0]
        .removeAttribute('required');
      this.deliveryModeConfigTarget.querySelectorAll(
        'input[type=date]'
      )[0].value = '';
    } else {
      this.deliveryModeConfigTarget.classList.remove('d-none');
      this.scheduledStartDateTarget.setAttribute('required', true);
      this.scheduledEndDateTarget.setAttribute('required', true);
    }
  }

  changeScheduledLaunchTime(e) {
    this._changeScheduledLaunchTime(e.target.value);
  }

  _changeScheduledLaunchTime(dt) {
    this.setDefaultScheduledStartDate(dt);
    this.setDefaultLaunchTime(dt);
  }

  setDefaultScheduledStartDate(dt) {
    if (dt.match('\\d+-\\d+-\\d+') && dt.match('\\d+-\\d+-\\d+')[0]) {
      this.scheduledStartDateTarget.value = dt.match('\\d+-\\d+-\\d+')[0];
      this.scheduledStartDateTarget.setAttribute(
        'min',
        dt.match('\\d+-\\d+-\\d+')[0]
      );
    }
  }

  setDefaultLaunchTime(dt) {
    if (dt.match('\\d+:\\d+') && dt.match('\\d+:\\d+')[0]) {
      this.launchTimeTarget.value = dt.match('\\d+:\\d+')[0];
      this.launchTimeTarget.setAttribute('min', dt.match('\\d+:\\d+')[0]);
    }
  }

  resetDefaultDeliveryTimes() {
    this.scheduledStartDateTarget.setAttribute(
      'min',
      moment(new Date()).format('YYYY-MM-DD')
    );
  }

  initReceiversField() {
    initIntlTelephoneInput();
  }
}
