import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form'];
  connect() {}

  async save() {
    this.formTarget.dispatchEvent(new CustomEvent('submit', { bubbles: true }));
  }
}
