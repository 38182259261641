import { Controller } from 'stimulus';
import storageAvailable from '../utils/localStorage';

export default class extends Controller {
  static targets = [
    'collapseBtn',
    'sidebar',
    'body',
    'navItem',
    'onboardingSummary',
  ];

  connect() {
    this.localStoragePresent = storageAvailable('localStorage');
    let navbarPreference =
      this.localStoragePresent && localStorage.getItem('navbar');

    if (navbarPreference && navbarPreference == 'expanded') {
      this.expand();
    } else {
      this.compact();
    }

    this._checkAndShowOnboardingPopup();
  }

  expand() {
    $(this.navItemTargets).tooltip('disable');
    this.sidebarTarget.classList.remove('compact');
    this.bodyTarget.classList.remove('compact');
    this.collapseBtnTarget.innerHTML = '<i class="bi bi-chevron-left"></i>';
    this.localStoragePresent && localStorage.setItem('navbar', 'expanded');
  }

  compact() {
    $(this.navItemTargets).tooltip('enable');
    this.localStoragePresent && localStorage.setItem('navbar', 'compact');
    this.sidebarTarget.classList.add('compact');
    this.bodyTarget.classList.add('compact');
    this.collapseBtnTarget.innerHTML = '<i class="bi bi-chevron-right"></i>';
  }

  toggleCollapse() {
    if (this.sidebarTarget.classList.contains('compact')) {
      this.expand();
    } else {
      this.compact();
    }
  }

  toggleOnboardingSummary() {
    if (this.hasOnboardingSummaryTarget)
      $(this.onboardingSummaryTarget).slideToggle();
  }

  _checkAndShowOnboardingPopup() {
    if (this.hasOnboardingSummaryTarget) {
      if (this.onboardingSummaryTarget.dataset.autoShow == 'true')
        setTimeout(() => {
          $(this.onboardingSummaryTarget).slideToggle();
        }, 1000);
    }
  }
}
