require('channels');
require('jquery');

import Rails from '@rails/ujs';
Rails.start();

const { delegate, disableElement, enableElement } = Rails;

delegate(
  document,
  Rails.linkDisableSelector,
  'turbo:before-cache',
  enableElement
);
delegate(
  document,
  Rails.buttonDisableSelector,
  'turbo:before-cache',
  enableElement
);
delegate(
  document,
  Rails.buttonDisableSelector,
  'turbo:submit-end',
  enableElement
);

delegate(
  document,
  Rails.formSubmitSelector,
  'turbo:submit-start',
  disableElement
);
delegate(document, Rails.formSubmitSelector, 'turbo:submit-end', enableElement);
delegate(
  document,
  Rails.formSubmitSelector,
  'turbo:before-cache',
  enableElement
);
