import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/js/utils';

document.addEventListener('turbo:load', () => {
  initIntlTelephoneInput();
});

document.addEventListener('turbo:frame-render', () => {
  initIntlTelephoneInput();
});

export default function initIntlTelephoneInput() {
  var inputs = document.querySelectorAll('input[type=\'tel\']');
  if (inputs.length) {
    inputs.forEach((input) => {
      if (input.dataset.intlTelInputId != undefined) {
        return;
      }
      var fieldName = input.dataset.formName;
      let iti = intlTelInput(input, {
        initialCountry: 'auto',
        hiddenInput: fieldName,
        autoPlaceholder: 'polite',
        preferredCountries: ['IN', 'US'],
        geoIpLookup: function (callback) {
          $.get(
            'https://ipinfo.io?token=2790597761e5ab',
            function () {},
            'jsonp'
          ).always(function (resp) {
            var countryCode = resp && resp.country ? resp.country : '';
            callback(countryCode);
          });
        },
      });

      var errorMap = [
        'Invalid number',
        'Invalid country code',
        'Too short phone number for country code',
        'Too long phone number for country code',
        'Invalid number',
      ];

      var msgContainer = document.createElement('div');

      var validMsg = document.createElement('p');
      validMsg.setAttribute('id', 'valid-msg');
      validMsg.classList.add('d-none', 'text-success', 'fs-14');
      validMsg.innerText = 'Valid Phone Number';

      var errorMsg = document.createElement('p');
      errorMsg.setAttribute('id', 'error-msg');
      errorMsg.classList.add('d-none', 'text-danger', 'fs-14');

      input.parentElement.after(msgContainer);
      msgContainer.appendChild(validMsg);
      msgContainer.appendChild(errorMsg);

      var submitBtn = Array.from(input.form.elements).filter(function (
        element
      ) {
        return element.type == 'submit';
      })[0];

      var reset = function () {
        input.classList.remove('error');
        errorMsg.innerHTML = '';
        if (submitBtn) submitBtn.disabled = false;
        errorMsg.classList.add('d-none');
        validMsg.classList.add('d-none');
      };

      var checkPhoneNumber = function () {
        reset();
        if (input.value.trim()) {
          if (iti.isValidNumber()) {
            validMsg.classList.remove('d-none');
            if (submitBtn) submitBtn.disabled = false;
            $('input[type=\'hidden\'][name=\'' + fieldName + '\']').val(
              iti.getNumber()
            );
          } else {
            input.classList.add('error');
            if (submitBtn) submitBtn.disabled = true;
            var errorCode = iti.getValidationError();
            errorMsg.innerHTML = errorMap[errorCode];
            errorMsg.classList.remove('d-none');
          }
        }
      };

      if (input.required) {
        input.addEventListener('blur', function () {
          checkPhoneNumber();
        });
      }

      $('input[type=\'tel\']').on('keyup', () => {
        checkPhoneNumber();
      });

      input.addEventListener('countrychange', function () {
        checkPhoneNumber();
      });
    });
  }
}
