import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
import StimulusReflex from 'stimulus_reflex';
import consumer from '../channels/consumer';
import { Autocomplete } from 'stimulus-autocomplete';

const application = Application.start();
const context = require.context('.', true, /\.js$/);
application.register('autocomplete', Autocomplete);
application.load(definitionsFromContext(context));
StimulusReflex.initialize(application, { consumer, isolate: true });
