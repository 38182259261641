import { Controller } from 'stimulus';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import InlineImage from 'editorjs-inline-image';
import Embed from '@editorjs/embed';
import { patch } from '@rails/request.js';
import edjsHTML from 'editorjs-html';
import NestedList from '@editorjs/nested-list';
const Quote = require('@editorjs/quote');
const Delimiter = require('@editorjs/delimiter');
import transforms from './editorjs_transformer';
import MediaLibrary from './editorjs/media_library';

class InlineImageTool extends InlineImage {
  static get toolbox() {
    return {
      title: 'External Image',
      icon: '<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20"><path d="M5.021 16.167q-.521 0-.854-.344-.334-.344-.334-.844V5.021q0-.5.334-.844.333-.344.854-.344h9.958q.521 0 .854.344.334.344.334.844v9.958q0 .5-.334.844-.333.344-.854.344Zm0-.667h9.958q.209 0 .365-.156t.156-.365V5.021q0-.209-.156-.365t-.365-.156H5.021q-.209 0-.365.156t-.156.365v9.958q0 .209.156.365t.365.156Zm1.729-1.917h6.625l-2.229-2.979-1.938 2.438-1.083-1.271ZM4.5 15.5V4.5 15.5Z"/></svg>',
    };
  }
}

export default class extends Controller {
  static targets = [];
  static values = {
    data: Object,
    url: String,
    readOnly: Boolean,
    mediaLibraryUrl: String,
  };
  connect() {
    if (this.readOnlyValue) {
      const edjsParser = edjsHTML({
        delimiter: transforms['delimiter'],
        quote: transforms['quote'],
        paragraph: transforms['paragraph'],
        list: transforms['list'],
        embed: transforms['embed'],
        image: transforms['image'],
        code: transforms['code'],
      });
      let parsedNodes = edjsParser.parse(this.dataValue);
      let html = '';
      parsedNodes.forEach((element) => {
        html += element;
      });
      document.querySelector('#editor-content').innerHTML = html;
    } else {
      this.editor = new EditorJS({
        holder: 'editor',
        readOnly: this.readOnlyValue,
        placeholder: 'Let\'s write an awesome post!',
        tools: {
          header: {
            class: Header,
            shortcut: 'CMD+SHIFT+H',
            config: {
              placeholder: 'Enter a header',
              levels: [1, 2, 3, 4, 5],
              defaultLevel: 1,
            },
          },
          delimiter: Delimiter,
          library: {
            class: MediaLibrary,
            config: {
              endpoint: this.hasMediaLibraryUrlValue
                ? this.mediaLibraryUrlValue
                : '',
            },
          },
          embed: {
            class: Embed,
            inlineToolbar: true,
          },
          list: {
            class: NestedList,
            inlineToolbar: true,
            config: {
              defaultStyle: 'unordered',
            },
          },
          quote: {
            class: Quote,
            inlineToolbar: true,
            shortcut: 'CMD+SHIFT+O',
            config: {
              quotePlaceholder: 'Enter a quote',
              captionPlaceholder: 'Quote\'s author',
            },
          },
          image: {
            class: InlineImageTool,
            inlineToolbar: true,
            config: {
              embed: {
                display: true,
              },
              unsplash: {
                appName: 'Post Embed',
                clientId: 'gM8nlGs54ICh8BAY9FulNBQ0jsMh81Dxm3w_DXfpKP4',
              },
            },
          },
        },
        data: this.dataValue,
        autofocus: true,
        onChange: () => {
          this.save();
        },
      });
    }
  }

  _modifyEditorStatusText(text, disabled = false) {
    if (document.querySelector('#editor-status') != null) {
      let button = document.querySelector('#editor-status');
      button.innerHTML = text;
      button.disabled = disabled;
    }
  }

  imageSelected(event) {
    this.editor.blocks.insert(
      'image',
      { url: event.detail.selected_url },
      undefined,
      event.detail.block_index
    );
  }

  save() {
    this._modifyEditorStatusText('Saving...', true);
    this.editor
      .save()
      .then(async (outputData) => {
        console.log('Post data: ', outputData);
        await patch(this.urlValue, {
          responseKind: 'turbo-stream',
          body: JSON.stringify({
            wa_post: {
              data: outputData,
            },
          }),
        });
        this._modifyEditorStatusText('Save Draft');
      })
      .catch((error) => {
        this._modifyEditorStatusText('Saving failed...');
        console.log('Saving failed: ', error);
      });
  }
}
