import { Controller } from 'stimulus';
import ahoy from 'ahoy.js';

export default class extends Controller {
  static targets = [
    'dateSlotPicker',
    'registrationForm',
    'emailField',
    'selectedTime',
    'selectedTimeDisplay',
    'submitBtn',
    'emailSpinner',
    'emailError',
    'slot',
    'miniSlot',
    'formContainer',
    'confirmText',
    'otherPurposeContainer',
    'purposeTextArea',
  ];

  connect() {
    this._setToken();
    this.checkEmail();
    this.setIdentity();
    this._handleSlotSelection();
    this.timezone = document.getElementById('timezone-target');
    this.banner = document.getElementById('widget-banner');
    this.momentTimezone = import('moment-timezone');
    if (this.element.dataset.scheduleMode === 'full') {
      this.showAllSlots();
    }
  }

  setIdentity() {
    let token = this.element.dataset.subscriberToken;
    let identityData = {
      name: 'gs:identify',
      data: { token: token },
    };

    if (token) {
      window.parent.postMessage(identityData, '*');
    }
  }

  checkEmail() {
    if (this.hasEmailFieldTarget) {
      if (this._validateEmail(this.emailFieldTarget.value)) {
        this._validateEmailRemote(
          this._extractDomain(this.emailFieldTarget.value)
        );
      } else {
        this.emailErrorTarget.innerText = '';
        this.submitBtnTarget.disabled = !(
          this.emailFieldTarget.value.trim() == ''
        );
      }
    }
  }

  toggleEmailSpinner(bool) {
    this.emailSpinnerTarget.style.display = bool ? 'block' : 'none';
  }

  requestPermissions() {
    console.log('Request Permissions');
  }

  showAllSlots() {
    this.miniSlotTarget.classList.replace('d-flex', 'd-none');
    this.formContainerTarget.classList.remove('d-none');
    this.toggleBanner(false);
  }

  showRegistrationForm() {
    this.showAllSlots();
    let gaController = this._googleAnalyticsController();
    if (gaController) {
      gaController.track({ event: 'selectedSlot' });
    }
    this.dateSlotPickerTarget.style.display = 'none';
    this.registrationFormTarget.style.display = 'block';
    this.toggleTimezone(false);
    this._renderTime();
  }

  hideRegistrationForm() {
    this.dateSlotPickerTarget.style.display = 'block';
    this.registrationFormTarget.style.display = 'none';
    this.toggleTimezone(true);
    this._renderTime();
  }

  toggleBanner(show) {
    if (this.banner) {
      this.banner.style.display = show ? 'block' : 'none';
    }
  }

  toggleTimezone(show) {
    if (this.timezone) {
      this.timezone.style.display = show ? 'inline-block' : 'none';
    }
  }

  _setToken() {
    let url = new URL(window.location.href);
    this.token = url.searchParams.get('token');
  }

  _validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
    return re.test(email);
  }

  _renderTime() {
    let selected_date = this.selectedTimeTarget.value;
    this.momentTimezone.then((imprt) => {
      const moment = imprt.default;
      if (!moment.isMoment(selected_date)) {
        selected_date = moment(selected_date).tz(
          this.dateSlotPickerTarget.dataset.timezone
        );
      }

      this.selectedTimeDisplayTarget.innerText = selected_date.format(
        'ddd, MMM DD | h:mm A z'
      );
      if (this.hasConfirmTextTarget) {
        this.confirmTextTarget.innerText = `Confirm for ${selected_date.format(
          'h:mm A, MMM DD'
        )}`;
      }
    });
  }

  _validateEmailRemote(domain) {
    this.toggleEmailSpinner(true);
    this.submitBtnTarget.disabled = true;
    fetch(`/validate/email?token=${this.token}&domain=${domain}`, {
      method: 'POST',
    })
      .then((res) => {
        this._toggleEmailError(res.status == 200, domain);
      })
      .catch(() => {
        this._toggleEmailError(true, domain);
      });
  }

  _toggleEmailError(bool, domain) {
    this.emailErrorTarget.innerText = bool
      ? ''
      : `Please enter your work email address. This form does not accept addresses from ${domain}`;
    this.toggleEmailSpinner(false);
    this.submitBtnTarget.disabled = !bool;
  }

  toggleOtherPurposeContainer(e) {
    this.purposeTextAreaTarget.value = '';
    if (this.hasOtherPurposeContainerTarget) {
      this.otherPurposeContainerTarget.style.display =
        e.target.value == 'true' ? 'block' : 'none';
    }
    if (this.hasPurposeTextAreaTarget && e.target.value == 'false') {
      this.purposeTextAreaTarget.value =
        e.target.options[e.target.selectedIndex].text;
    }
  }

  _extractDomain(email) {
    return email.split('@').pop();
  }

  _resetSlotSelection() {
    if (!this.hasSlotTarget) return;
    this.slotTargets.forEach((slot) => {
      slot.classList.remove('active');
    });
  }

  _handleSlotSelection() {
    if (!this.hasSlotTarget) return;
    this.slotTargets.forEach((slot) => {
      slot.addEventListener('click', () => {
        this._resetSlotSelection();
        slot.classList.add('active');
        this.selectedTimeTarget.value = slot.dataset.time;
        this._renderTime();
        ahoy.track('selected_slot', {
          account_id: slot.dataset.accountId,
          slot: slot.dataset.time,
        });
      });
    });
  }

  _googleAnalyticsController() {
    if (document.querySelector('[data-ga-tracking-enabled="true"]') !== null) {
      return this.application.getControllerForElementAndIdentifier(
        this.element.querySelector('[data-controller=\'google-analytics\']'),
        'google-analytics'
      );
    }
  }
}
