import { Controller } from 'stimulus';

export default class extends Controller {
  static values = { quantity: Number, proPlanActive: String };
  static targets = [
    'upgardeBtn',
    'formWrapper',
    'confirmBtn',
    'quantity',
    'price',
  ];

  connect() {
    this.initial_quantity = parseInt(this.quantityTarget.value);
    this._setPrice(this.initial_quantity);
  }

  _setPrice(no_of_agents) {
    let price = no_of_agents * 29;
    this.priceTarget.innerText = `$${price}`;
  }

  showBillingForm() {
    this.upgardeBtnTarget.style.animation = 'fadeOut 0.5s 4.5s';
    this.upgardeBtnTarget.classList.toggle('d-none');

    this.formWrapperTarget.style.animation = 'fadeIn 0.5s forwards';
    this.formWrapperTarget.classList.toggle('d-none');
  }

  increment() {
    let new_value = parseInt(this.quantityTarget.value) + 1;
    if (new_value <= 10) {
      this.quantityTarget.value = new_value;
      this.quantityValue = new_value;
      this._setPrice(new_value);
    }

    this.confirmBtnTarget.disabled =
      this.quantityValue == this.initial_quantity ||
      this.proPlanActive == 'true';
  }

  decrement() {
    let new_value = parseInt(this.quantityTarget.value) - 1;
    if (new_value >= 1) {
      this.quantityTarget.value = new_value;
      this.quantityValue = new_value;
      this._setPrice(new_value);
    }
    this.confirmBtnTarget.disabled =
      this.quantityValue == this.initial_quantity ||
      this.proPlanActive == 'true';
  }
}
