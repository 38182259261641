import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'overlay',
    'scriptSuccess',
    'enableWidgetForm',
    'hiddenInput',
    'emailNotification',
    'handleInput',
    'handleText'
  ];
  connect() {
    this._handleScriptSuccessMessage();
  }

  playVideo() {
    $('.overlay').fadeOut();
  }

  submitWidgetForm() {
    let form = document.getElementById('widget_hidden_form');
    if (form) {
      if (form.reportValidity()) form.submit();
    }
  }

  submitEnableWidgetForm(e) {
    if (this.hasEnableWidgetFormTarget) {
      this.hiddenInputTarget.value = e.target.checked ? '1' : '0';
      this.enableWidgetFormTarget.submit();
    }
  }

  generateSlug(e){
    let slug = e.target.value
    .trim()
    .toLowerCase()
    .replace(/ /g,'-')
    .replace(/[^\w-]+/g,'')
    console.log(slug);
    if(!this.hasHandleInputTarget || !this.hasHandleTextTarget)
    return;
    this.handleInputTarget.value = slug
    this.handleTextTarget.innerHTML = slug
  }

  _handleScriptSuccessMessage() {
    if (this.hasEmailNotificationTarget) {
      setTimeout(() => {
        this.emailNotificationTarget.remove();
      }, 2500);
    }
  }
}
