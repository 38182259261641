const alignType = ['left', 'right', 'center', 'justify'];
export default {
  delimiter: () => {
    return '<hr />';
  },

  header: ({ data }) => {
    return `<h${data.level}>${data.text}</h${data.level}>`;
  },

  paragraph: ({ data }) => {
    const paragraphAlign = data.alignment || data.align;

    if (
      typeof paragraphAlign !== 'undefined' &&
      alignType.includes(paragraphAlign)
    ) {
      return `<p style="text-align:${paragraphAlign};">${data.text}</p>`;
    } else {
      return `<p>${data.text}</p>`;
    }
  },

  list: ({ data }) => {
    const listStyle = data.style === 'unordered' ? 'ul' : 'ol';

    const recursor = (items, listStyle) => {
      const list = items.map((item) => {
        if (!item.content && !item.items) return `<li>${item}</li>`;

        let list = '';
        if (item.items) list = recursor(item.items, listStyle);
        if (item.content) return `<li> ${item.content} </li>` + list;
      });

      return `<${listStyle}>${list.join('')}</${listStyle}>`;
    };
    return recursor(data.items, listStyle);
  },
  image: ({ data }) => {
    let caption = data.caption ? data.caption : 'Image';
    return `<img class="img-fluid rounded" src="${
      data.file && data.file.url ? data.file.url : data.url
    }" alt="${caption}" />`;
  },

  quote: ({ data }) => {
    return `<figure class="text-${data.alignment}">
      <blockquote class="blockquote">
        <p>${data.text}</p>
      </blockquote>
      <figcaption class="blockquote-footer">
        By <cite title="Source Title">${data.caption}</cite>
      </figcaption>
    </figure>`;
  },

  code: ({ data }) => {
    return `<pre><code>${data.code}</code></pre>`;
  },

  embed: ({ data }) => {
    switch (data.service) {
    case 'vimeo':
      return `<iframe src="${data.embed}" height="${data.height}" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`;
    case 'youtube':
      return `<div class="ratio ratio-16x9"><iframe src="${data.embed}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>`;
    default:
      throw new Error(
        'Only Youtube and Vime Embeds are supported right now.'
      );
    }
  },
};
