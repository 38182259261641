import { Controller } from 'stimulus';
import StimulusReflex from 'stimulus_reflex';
import { isElementAvailable } from '../helpers';
export default class extends Controller {
  connect() {
    StimulusReflex.register(this);
    document.addEventListener(
      'cable-ready:after-morph',
      this.refreshSlotPicker.bind(this)
    );
  }

  disconnect() {
    document.removeEventListener(
      'cable-ready:after-morph',
      this.refreshSlotPicker
    );
  }

  sort() {
    let controller = this.application.getControllerForElementAndIdentifier(
      document.getElementById(this.element.dataset.target),
      'sortable'
    );
    if (controller) controller.sort();
  }
  remove() {
    var el = document.querySelector(
      `[data-session-id="${this.element.dataset.id}"]`
    );
    var nameElement = document.getElementById('participant_names');
    if (nameElement.innerText)
      nameElement.innerText = `${nameElement.innerText},${el.dataset.participantName}`;
    else
      nameElement.innerText = `Proceeding will cancel sessions with ${el.dataset.participantName}`;
    el.remove();
  }

  refreshSlotPicker() {
    if (isElementAvailable('slot_picker_parent')) {
      let slotPickerController =
        this.application.getControllerForElementAndIdentifier(
          document.querySelector('#slot_picker_parent'),
          'slot-picker'
        );
      if (slotPickerController) {
        slotPickerController.connect();
      }
    }
  }

  reload() {
    this.stimulate('SessionQueueReflex#reload');
  }
}
