import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['activeDetail'];
  static values = {
    subscriberId: Number,
  };
  connect() {
    if (this.hasSubscriberIdValue) {
      this.getSubscriber(this.subscriberIdValue);
    }
  }

  setActive(e) {
    this.activeDetailTargets.forEach((element) => {
      element.classList.remove('active');
    });

    e.currentTarget.firstElementChild.firstElementChild.classList.add('active');
  }

  getSubscriber(id) {
    let el = document.querySelector(`[id="subscriber_${id}"]`);
    el.firstElementChild.firstElementChild.classList.add('active');
  }
}
