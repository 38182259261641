import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [];

  connect() {
    let postObject = {};
    postObject.event = this.element.getAttribute('data-analytics-event-name');
    if (this.element.getAttribute('data-analytics-event-args')) {
      postObject.data = JSON.parse(
        this.element.getAttribute('data-analytics-event-args')
      );
    }
    this.track(postObject);
  }

  track(data) {
    try {
      data.event = 'ga:' + data.event;
      parent.postMessage(data, '*');
    } catch (e) {
      window.console && window.console.log(e);
    }
  }
}
