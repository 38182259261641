export default function flash(message) {
  const FLASH_ROOT = document.querySelector("#flash-messages");
  if(FLASH_ROOT){
    FLASH_ROOT.querySelector("#flash-alert").classList.add("show");
    FLASH_ROOT.querySelector("#flash-alert").classList.remove("d-none");
    FLASH_ROOT.querySelector("#notice").innerHTML = message;

    $("#flash-alert.show").fadeTo(4000, 500).slideUp(500, function () {
      this.classList.remove("show");
      this.classList.add("d-none");
    });
  }
}
