import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['item']

  selected(event) {
    this.itemTargets.forEach((item) => {
      item.classList.remove('edit-background');
    });

    event.currentTarget.classList.add('edit-background');
  }
}
