import { Controller } from 'stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import { get } from '@rails/request.js';
export default class extends Controller {
  static targets = [
    'ideasWrapper',
    'ideasOpen',
    'ideasClose',
    'inboxFilter',
    'searchBar',
    'selectedFilters',
    'dateInput',
    'filterForm',
  ];
  static values = { url: String };

  connect() {
    this.setDateRange();
  }

  redirectToSubscriber(e) {
    if (
      e.target.classList.contains('prevent-redirection') ||
      e.target.classList.contains('dropdown-item')
    )
      return;
    if (this.element.dataset.url) Turbo.visit(this.element.dataset.url);
  }

  toggleFilter() {
    if (!this.hasInboxFilterTarget) return;
    if (this.inboxFilterTarget.classList.contains('active'))
      this.inboxFilterTarget.classList.remove('active');
    else this.inboxFilterTarget.classList.add('active');
  }

  async showDetail(event) {
    document.querySelector('.loading-spinner').classList.remove('d-none');
    event.preventDefault();
    const response = await get(this.urlValue, { responseKind: 'turbo-stream' });
    if (response.ok) {
      window.history.pushState({}, '', this.urlValue);
    }
    document.querySelector('.loading-spinner').classList.add('d-none');
  }

  showSearchBar() {
    if (!this.hasSearchBarTarget || !this.hasSelectedFiltersTarget) return;
    this.searchBarTarget.classList.remove('d-none');
    this.selectedFiltersTarget.classList.replace('d-flex', 'd-none');
  }

  setDateRange() {
    if (!this.hasDateInputTarget || !this.hasFilterFormTarget) return;
    document.querySelectorAll('.date-option').forEach((el) => {
      el.addEventListener('click', () => {
        this.dateInputTarget.value = el.dataset.value;
        this.filterFormTarget.submit();
      });
    });
  }

  toggleIdeas() {
    if (
      !this.hasIdeasWrapperTarget ||
      !this.hasIdeasOpenTarget ||
      !this.hasIdeasCloseTarget
    )
      return;
    const is_idea_open = this.ideasWrapperTarget.classList.contains('d-none');
    if (is_idea_open) {
      this.element.classList.add('active');
      this.ideasWrapperTarget.classList.remove('d-none');
      this.ideasOpenTarget.classList.add('d-none');
      this.ideasCloseTarget.classList.remove('d-none');
    } else {
      this.element.classList.remove('active');
      this.ideasWrapperTarget.classList.add('d-none');
      this.ideasOpenTarget.classList.remove('d-none');
      this.ideasCloseTarget.classList.add('d-none');
    }
  }
}
