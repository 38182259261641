// app/javascript/channels/chat_channel.js
import consumer from './consumer';
import CableReady from 'cable_ready';

document.addEventListener('turbo:load', () => {
  let roomContainerEl = document.querySelector('#session-room-container');
  if (roomContainerEl) {
    let channelName = roomContainerEl.dataset.channelName;
    let token = roomContainerEl.dataset.channelToken;
    consumer.subscriptions.create(
      { channel: 'SessionRoomChannel', room: channelName, token: token },
      {
        received(data) {
          if (data.cableReady) CableReady.perform(data.operations);
        },
      }
    );
  }
});
