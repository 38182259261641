import { Controller } from 'stimulus';
import { useClickOutside } from 'stimulus-use';
import StimulusReflex from 'stimulus_reflex';
import { Turbo } from '@hotwired/turbo-rails';
export default class extends Controller {
  static targets = ['timezonePopup'];

  connect() {
    useClickOutside(this);
    StimulusReflex.register(this);
  }

  togglePopup() {
    let is_popup_open = this.timezonePopupTarget.dataset.display == 'true';
    this.timezonePopupTarget.dataset.display = is_popup_open ? 'false' : 'true';
  }

  clickOutside() {
    if (this.hasTimezonePopupTarget) {
      this.timezonePopupTarget.dataset.display = 'false';
    }
  }

  changeTimezone(e) {
    let timezone = '';
    if (e.target.nodeName == 'LI') {
      timezone = e.target.dataset.timezone;
    } else {
      timezone = e.target.parentElement.dataset.timezone;
    }
    if (timezone) {
      let url = new URLSearchParams(window.location.search);
      url.set('tz', timezone);
      history.pushState(null, null, '?' + url.toString());
      Turbo.visit(window.location.href);
    }
  }
}
