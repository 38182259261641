// import Rails from '@rails/ujs'
import { Controller } from 'stimulus';
import StimulusReflex from 'stimulus_reflex';

export default class extends Controller {
  connect() {
    StimulusReflex.register(this);
  }

  beforeReflex(el) {}

  afterReflex(el) {}

  onStatusChange() {
    console.log('SessionRoomController.js: onStatusChange');
    window.location.reload();
  }
}
