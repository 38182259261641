import { Controller } from "stimulus";

export default class extends Controller {
  static values = { solutionId: String };

  open360DialogPermissionScreen() {
    const windowFeatures =
      'toolbar=no, menubar=no, width=600, height=900, top=100, left=100';
    open(
      'https://hub.360dialog.com/dashboard/app/uXt6vjPA/permissions',
      'integratedOnboardingWindow',
      windowFeatures
    );
  }

  openMetaDialogScreen() {
    let configuration = {
      config_id: '1393833954534708', // configuration ID obtained in the previous step goes here
      response_type: 'code',     // must be set to 'code' for System User access token
      override_default_response_type: true,
      extras: {
        setup: {
          // Prefilled data can go here
        }
      }
    }

    // Add solution id to the configuration extras setup object
    if(this.hasSolutionIdValue && this.solutionIdValue){
      configuration.extras.setup.solutionID = this.solutionIdValue;
    }

    FB.login(function (response) {
      const code = response?.authResponse?.code;
      //Use this token to call the debug_token API and get the shared WABA's ID
      window.location.href = "/account/integrations/whatsapp?code=" + code;
    }, configuration);
  }
}