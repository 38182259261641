import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { allowVariables: Boolean, editor: String };

  connect() {
    let buttonGroupFileTools =
      this.element.editorController.toolbarController.element.querySelector(
        '[data-trix-button-group="text-tools"]'
      );
    let buttonHTML =
      `<button type="button" 
           class="trix-button trix-button--icon trix-button--icon-emoji" 
           data-controller="emoji-picker"
           title="Emoji" 
           tabindex="-1">
      </button>`;

    if (this.editorValue) {
      buttonHTML =
        `<button type="button" 
              class="trix-button trix-button--icon trix-button--icon-emoji" 
              data-controller="emoji-picker" 
              data-emoji-picker-editor-value="${this.editorValue}"
              title="Emoji" 
              tabindex="-1">
        </button>`;
    }
    buttonGroupFileTools.insertAdjacentHTML('beforeend', buttonHTML);

    if (this.hasAllowVariablesValue && this.allowVariablesValue) {
      const variableButtonHTML =
        '<button type="button" class="trix-button trix-button--icon trix-button--icon-variable" data-action="click->variable-picker#showModal" title="Placeholder" tabindex="-1"></button>';
      buttonGroupFileTools.insertAdjacentHTML('beforeend', variableButtonHTML);
    }
  }

  insertString(value) {
    this.element.editor.insertString(value);
  }

  disconnect() { }
}
