import { Controller } from 'stimulus';
import StimulusReflex from 'stimulus_reflex';
import debounced from 'debounced';

export default class extends Controller {
  connect() {
    debounced.initialize({ input: { wait: 1000 }, keyup: { wait: 1000 } });
    StimulusReflex.register(this);
  }

  getSelectedUserId() {
    if (this.element.dataset.hasOwnProperty('selectedUserId')) {
      return this.element.dataset.selectedUserId;
    }
    return null;
  }
}
