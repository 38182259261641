import { Controller } from 'stimulus';
import { EmojiButton } from '@joeattardi/emoji-button';

export default class extends Controller {
  static targets = ['emojiMessage', 'emojiIcon'];
  connect() {
    if (this.hasEmojiIconTarget && this.hasEmojiMessageTarget) {
      const picker = new EmojiButton();
      const trigger = this.emojiIconTarget;
      const msg = this.emojiMessageTarget;

      picker.on('emoji', (selection) => {
        const newValue = selection.emoji;
        msg.value += newValue;
      });

      trigger.addEventListener('click', () => picker.togglePicker(trigger));
    }
  }
}
