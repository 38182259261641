import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bsmodal"
export default class extends Controller {

  static values = {
    target: String,
  }

  connect() {
    let element = document.querySelector(this.targetValue);
    this.modal = bootstrap.Modal.getInstance(element);
    this.modal.hide();
    this.element.remove();
  }

  disconnect() {
    
  }
}