import { Controller } from 'stimulus';
import StimulusReflex from 'stimulus_reflex';

export default class extends Controller {
  connect() {
    StimulusReflex.register(this);
    this.element.addEventListener('autocomplete.change', (e) => {
      let duration_element = this.element
        .getRootNode()
        .querySelector('#video_call_duration');
      let duration = duration_element.value;
      let sessionId = this.element
        .getRootNode()
        .querySelector('#video_call_session_id').value;
      this._fetch_availability(e.detail.value, duration, sessionId);
    });
  }

  beforeReflex(el) {
    document.querySelector('.loading-spinner').classList.remove('d-none');
    let slotPickerController =
      this.application.getControllerForElementAndIdentifier(
        document.querySelector('#slot_picker_parent'),
        'slot-picker'
      );
    if (slotPickerController) {
      slotPickerController.disconnect();
    }
    let autocompleteController =
      this.application.getControllerForElementAndIdentifier(
        document.querySelector(
          '[data-controller="autocomplete video-call-creation"]'
        ),
        'autocomplete'
      );
    if (autocompleteController) {
      autocompleteController.disconnect();
    }
  }

  afterReflex(el, reflex) {
    if (document.querySelector('.loading-spinner')) {
      document.querySelector('.loading-spinner').classList.add('d-none');
    }
    let slotPickerController =
      this.application.getControllerForElementAndIdentifier(
        document.querySelector('#slot_picker_parent'),
        'slot-picker'
      );
    if (slotPickerController) {
      slotPickerController.connect();
    }
    let autocompleteController =
      this.application.getControllerForElementAndIdentifier(
        document.querySelector(
          '[data-controller="autocomplete video-call-creation"]'
        ),
        'autocomplete'
      );
    if (autocompleteController) {
      autocompleteController.connect();
    }
  }

  reflexError(el) {
    document.querySelector('.loading-spinner').classList.add('d-none');
  }

  reflexHalted(el) {
    document.querySelector('.loading-spinner').classList.add('d-none');
  }

  fetch_availability(event) {
    event.preventDefault();
    let userId = this.element
      .getRootNode()
      .querySelector('#video_call_user_id').value;
    let sessionId = this.element
      .getRootNode()
      .querySelector('#video_call_session_id').value;
    let duration = this.element
      .getRootNode()
      .querySelector('#video_call_duration').value;
    this._fetch_availability(userId, duration, sessionId);
  }

  toggle_ignore_office_hours(event) {
    event.preventDefault();
    let user_id = event.target
      .getRootNode()
      .querySelector('#video_call_user_id').value;
    this.stimulate('VideoCallCreation#toggle_ignore_office_hours');
  }

  _fetch_availability(userId, duration, sessionId) {
    console.log(
      'userId: ' +
        userId +
        ', duration: ' +
        duration +
        ', sessionId: ' +
        sessionId
    );
    this.stimulate(
      'VideoCallCreation#fetch_availability',
      userId,
      duration,
      sessionId
    );
  }

  openModal(modalName) {
    document.getElementById('backdrop').style.display = 'block';
    document.getElementById(modalName).style.display = 'block';
    document.getElementById(modalName).classList.add('show');
  }

  closeModal() {
    document.querySelectorAll('.modal').forEach((item) => {
      item.style.display = 'none';
      item.classList.remove('show');
    });
    document.getElementById('backdrop').style.display = 'none';
  }
}
