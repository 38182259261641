import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['item'];
  static classes = ['hidden'];
  static values = { more: String, less: String };

  connect() {
    this.class = this.hasHiddenClass ? this.hiddenClass : 'd-none';
  }

  toggle(e) {
    console.log(e.target.innerText);
    let moreText = this.hasMoreValue ? this.moreValue : 'expand_more';
    if (e.target.innerText == moreText) {
      e.target.innerText = this.lessValue ? this.lessValue : 'expand_less';
    } else {
      e.target.innerText = this.hasMoreValue ? this.moreValue : 'expand_more';
    }

    this.itemTargets.forEach((item) => {
      item.classList.toggle(this.class);
    });
  }
}
