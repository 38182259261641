import { Controller } from 'stimulus';
import flash from '../lib/flash';

export default class extends Controller {
  static targets = ['source'];
  async copy() {
    let copyText = this.sourceTarget;
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    await navigator.clipboard.writeText(copyText.value);
    console.log('Copied the text: ' + copyText.value);
    flash('Copied to clipboard');
  }
  copyHtml() {
    var r = document.createRange();
    r.selectNode(this.sourceTarget);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(r);
    document.execCommand('copy');
    flash('Copied to clipboard');
  }
  copyData() {
    let text = this.sourceTarget.dataset.text;
    var copyText = document.createElement('pre');
    copyText.innerHTML = text;
    document.body.appendChild(copyText);
    copyText.style = 'display: inline; width: 1px;';
    var r = document.createRange();
    r.selectNode(copyText);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(r);
    document.execCommand('copy');
    copyText.remove();
    flash('Copied to clipboard');
  }
}
