import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {}

  showPreview() {
    let previewEl = document.getElementById('widget_preview');
    if (!previewEl) return;
    previewEl.classList.replace('d-none', 'd-flex');
  }

  closePreview() {
    let previewEl = document.getElementById('widget_preview');
    if (!previewEl) return;
    previewEl.classList.replace('d-flex', 'd-none');
  }
}
