import { Controller } from 'stimulus';
import StimulusReflex from 'stimulus_reflex';

export default class extends Controller {
  connect() {
    StimulusReflex.register(this);
    this.element.addEventListener('autocomplete.change', (e) => {
      let duration = this.element
        .getRootNode()
        .querySelector('#session_duration').value;
      this._fetch_availability(e.detail.value, duration);
    });
  }

  beforeReflex(el) {
    document.querySelector('.loading-spinner').classList.remove('d-none');
  }

  afterReflex(el, reflex) {
    if (document.querySelector('.loading-spinner')) {
      document.querySelector('.loading-spinner').classList.add('d-none');
    }
    let slotPickerController =
      this.application.getControllerForElementAndIdentifier(
        document.querySelector('#slot_picker_parent'),
        'slot-picker'
      );
    if (slotPickerController) {
      slotPickerController.connect();
    }
    let autocompleteController =
      this.application.getControllerForElementAndIdentifier(
        document.querySelector(
          '[data-controller="autocomplete session-creation"]'
        ),
        'autocomplete'
      );
    if (autocompleteController) {
      autocompleteController.connect();
    }

    if (reflex == 'SessionCreation#get_subscribers') {
      // document.querySelector('#launchSubscriberModal').click();
      this.openModal('subscriberSelectModal');
    }
  }

  reflexError(el) {
    document.querySelector('.loading-spinner').classList.add('d-none');
  }

  reflexHalted(el) {
    document.querySelector('.loading-spinner').classList.add('d-none');
  }

  fetch_availability(event) {
    event.preventDefault();
    let userId = this.element
      .getRootNode()
      .querySelector('#session_user_id').value;
    let duration = this.element
      .getRootNode()
      .querySelector('#session_duration').value;
    this._fetch_availability(userId, duration);
  }

  toggle_ignore_office_hours(event) {
    event.preventDefault();
    let user_id = event.target
      .getRootNode()
      .querySelector('#session_user_id').value;
    this.stimulate('SessionCreation#toggle_ignore_office_hours');
  }

  _fetch_availability(userId, duration) {
    console.log('userId: ' + userId + ', duration: ' + duration);
    this.stimulate('SessionCreation#set_session_attributes');
  }

  get_subscribers(event) {
    event.preventDefault();
    var search_query = this.element.querySelector('#search_query').value;
    console.log('Subscriber Search Query:', search_query);
    this.stimulate('SessionCreation#get_subscribers', search_query);
  }

  select_subscriber() {
    let subscriber_id = this.element.dataset.subscriberId;
    console.log('Selected:', subscriber_id);
    this.stimulate('SessionCreation#find_subscriber', subscriber_id);
  }

  build_subscriber() {
    this.stimulate('SessionCreation#build_subscriber');
  }

  afterBuildSubscriber() {
    this.openModal('subscriberBuildModal');
  }

  add_subscriber() {
    this.closeModal();
    this.stimulate('SessionCreation#set_session_attributes');
  }

  openModal(modalName) {
    document.getElementById('backdrop').style.display = 'block';
    document.getElementById(modalName).style.display = 'block';
    document.getElementById(modalName).classList.add('show');
  }

  closeModal() {
    document.querySelectorAll('.modal').forEach((item) => {
      item.style.display = 'none';
      item.classList.remove('show');
    });
    document.getElementById('backdrop').style.display = 'none';
  }
}
