import { Controller } from 'stimulus';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  static values = { url: String, param: String };

  changed(e) {
    let url = new URL(this.urlValue);
    if (e.target.value) {
      url.searchParams.set(this.paramValue, e.target.value);
    } else {
      url.searchParams.delete(this.paramValue);
    }
    Turbo.visit(url);
  }
}
