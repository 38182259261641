import Flatpickr from 'stimulus-flatpickr';
import { Controller } from 'stimulus';
import StimulusReflex from 'stimulus_reflex';

export default class extends Flatpickr {
  initialize() {}

  connect() {
    this.config = {
      onOpen: [this.disableDates.bind(this)],
      disableMobile: true,
    };
    StimulusReflex.register(this);
    super.connect();
  }

  disableDates(selectedDates, dateStr, instance) {
    const { availableDates } = instance.element.dataset;
    if (availableDates) {
      const dates = JSON.parse(availableDates).map((date) => new Date(date));
      instance.set('enable', dates);
    } else {
      instance.set('enable', []);
    }
  }
}
