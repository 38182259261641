import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'name',
    'sessionId',
    'startTime',
    'startTimeText',
    'cancelForm',
    'rescheduleForm',
  ];

  setContent(data) {
    this.nameTarget.innerHTML = data.name;
    this.sessionIdTarget.innerHTML = data.sessionId;

    if (this.hasStartTimeTarget && data.startTime !== 'NA') {
      // this.startTimeTarget.value = data.startTime;
      // $(this.startTimeTarget).flatpickr({
      //     enableTime:      true,
      //     minDate:         "today",
      //     altFormat:       "F j, Y at h:i K",
      //     dateFormat:      "Z",
      //     defaultHour:     new Date().getHours(),
      //     defaultMinute:   new Date().getMinutes(),
      //     minuteIncrement: 1,
      //     appendTo: $('#cal-container')[0]
      // });
    }
    if (this.hasStartTimeTextTarget && data.startTime !== 'NA') {
      this.startTimeTextTarget.innerHTML = data.startTime;
    }
    if (this.hasCancelFormTarget) {
      let url = this.cancelFormTarget.action;
      let origin = new URL(url).origin;
      this.cancelFormTarget.action = `${origin}/sessions/${data.sessionId}`;
    }
    if (this.hasRescheduleFormTarget) {
      this.rescheduleFormTarget.action = `${origin}/sessions/${data.sessionId}`;
    }
  }

  open() {
    document.body.classList.add('modal-open');
    this.element.setAttribute('style', 'display: block;');
    this.element.classList.add('show');
    let backdropEl = document.createElement('div');
    backdropEl.classList.add('modal-backdrop', 'fade', 'show');
    document.body.append(backdropEl);
  }

  close() {
    document.body.classList.remove('modal-open');
    this.element.removeAttribute('style');
    this.element.classList.remove('show');
    document.getElementsByClassName('modal-backdrop')[0].remove();
  }
}
