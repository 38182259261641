import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bsmodal"
export default class extends Controller {
  connect() {
    this.offCanvas = new bootstrap.Offcanvas(this.element, {
      keyboard: false
    })
    this.offCanvas.show()
  }

  disconnect() {
    this.offCanvas.hide()
  }
}