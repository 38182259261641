import { Controller } from 'stimulus';
import StimulusReflex from 'stimulus_reflex';

export default class extends Controller {
  static targets = ['client', 'secret'];

  connect() {
    StimulusReflex.register(this);
    $('select#provider_name').trigger('change');
  }

  switchProvider(event) {
    $('.validatable_field').parents('.form-group').removeClass('d-none');
    $('.validatable_field').parents('.form-group').show();
    let allowedColumns = JSON.parse(event.target.dataset.allowedColumns);
    let allowedFields = allowedColumns[event.target.value].map(
      (val) => 'provider_' + val
    );
    $('.validatable_field').each(function (field) {
      if (!allowedFields.includes(this.id)) {
        $(this).parents('.form-group').hide();
      }
    });
  }

  toggleVisibility(e) {
    if (this.targets.find(e.target.dataset.value)) {
      let target = this.targets.find(e.target.dataset.value);
      target.type = target.type == 'password' ? 'text' : 'password';
      e.target.innerHTML =
        target.type == 'password' ? 'visibility' : 'visibility_off';
    }
  }
}
