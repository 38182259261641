// app/javascript/channels/chat_channel.js
import consumer from './consumer';
import CableReady from 'cable_ready';

document.addEventListener('turbo:load', () => {
  let leadsQueueContainerEl = document.querySelector('#leads-queue-tbody');
  if (leadsQueueContainerEl) {
    consumer.subscriptions.create('LeadQueueChannel', {
      received(data) {
        if (data.cableReady) CableReady.perform(data.operations);
      },
    });
  }
});
