import { Controller } from 'stimulus';
import StimulusReflex from 'stimulus_reflex';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  connect() {
    StimulusReflex.register(this);
  }

  beforeShowSession(el) {
    document.querySelector('.loading-spinner').classList.remove('d-none');
    const url = new URL(window.location);
    if (url.searchParams.has('mobile')) url.searchParams.delete('mobile');
    switch (el.dataset.type) {
    case 'session':
      if (url.searchParams.has('visit_id'))
        url.searchParams.delete('visit_id');
      url.searchParams.set('vc_id', el.dataset.videoCallId);
      break;
    case 'visit':
      if (url.searchParams.has('vc_id')) url.searchParams.delete('vc_id');
      url.searchParams.set('visit_id', el.dataset.sessionQueueId);
      break;
    default:
      break;
    }
    window.history.pushState({}, '', url);
  }

  afterShowSession() {
    document.querySelector('.loading-spinner').classList.add('d-none');
  }

  beforeHideSession() {
    document.querySelector('.loading-spinner').classList.remove('d-none');
  }

  afterHideSession() {
    document.querySelector('.loading-spinner').classList.add('d-none');
  }

  hideSessionError() {
    document.querySelector('.loading-spinner').classList.add('d-none');
  }

  hideSessionHalted() {
    document.querySelector('.loading-spinner').classList.add('d-none');
  }

  showSessionError() {
    document.querySelector('.loading-spinner').classList.add('d-none');
  }

  showSessionHalted() {
    document.querySelector('.loading-spinner').classList.add('d-none');
  }

  showSession() {
    this.stimulate('Session#show_session', this.element.dataset);
  }

  hideSession() {
    this.stimulate('Session#hide_session', this.element.dataset);
  }

  redirectSession() {
    Turbo.visit(this.element.dataset.url);
  }
}
