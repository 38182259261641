export function getMetaValue(name) {
  const element = findElement(document.head, `meta[name="${name}"]`);
  if (element) {
    return element.getAttribute('content');
  }
}

export function findElement(root, selector) {
  if (typeof root == 'string') {
    selector = root;
    root = document;
  }
  return root.querySelector(selector);
}

export function toArray(value) {
  if (Array.isArray(value)) {
    return value;
  } else if (Array.from) {
    return Array.from(value);
  } else {
    return [].slice.call(value);
  }
}

export function removeElement(el) {
  if (el && el.parentNode) {
    el.parentNode.removeChild(el);
  }
}

export function insertAfter(el, referenceNode) {
  return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
}

export function setCookie(name, value, expiryInDays, path = '/') {
  if (typeof expiryInDays == 'number') {
    var cookie = `${name}=${encodeURIComponent(value)}; max-age=${
      expiryInDays * 24 * 60 * 60
    }; path=${path}`; // cookie string formation with max-age in days
    document.cookie = cookie; // adding cookie
  }
}

export function getCookie(cookieName) {
  var cookies = document.cookie.split(';');
  for (const cookie of cookies) {
    var pair = cookie.split('=');
    if (cookieName == pair[0].trim()) return decodeURIComponent(pair[1]);
  }
  return null; // cookie not found
}

export function isElementAvailable(dom_id) {
  var element = document.getElementById(dom_id);
  return typeof element != 'undefined' && element != null;
}

export function lightOrDark(color) {
  // Variables for red, green, blue values
  var r, g, b, hsp;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return 'light';
  } else {
    return 'dark';
  }
}

export function setWidgetVideoCallStatus(started = false) {
  if (started) window.parent.postMessage('video_call:started', '*');
  else window.parent.postMessage('video_call:ended', '*');
}

export function closeWidget() {
  window.parent.postMessage('gswidget:close', '*');
}
