import { Controller } from 'stimulus';
import { isSafariOnDesktop } from '../utils/getDeviceInfo';

export default class extends Controller {
  static targets = ['source'];
  share() {
    let is_safari_on_desktop = isSafariOnDesktop();
    if (navigator.share && !is_safari_on_desktop) {
      navigator
        .share({
          title: this.sourceTarget.dataset.title,
          text: this.sourceTarget.dataset.text,
        })
        .then(() => {
          console.log('Thanks for sharing!');
        })
        .catch(console.error);
    } else {
      let copyController =
        this.application.getControllerForElementAndIdentifier(
          this.element,
          'clipboard'
        );
      if (copyController) {
        copyController.copy();
      }
    }
  }
}
