import { Controller } from 'stimulus';
import Choices from 'choices.js';
require('choices.js/public/assets/styles/choices.min.css');
export default class extends Controller {
  connect() {
    let sortable = true;
    if (
      Object.prototype.hasOwnProperty.call(this.element.dataset, 'sortable')
    ) {
      sortable = this.element.dataset.sortable === 'true';
    }
    new Choices(this.element, {
      shouldSort: sortable,
      shouldSortItems: sortable,
      removeItemButton: true,
      classNames: {
        containerOuter:
          'choices form-control form-control-sm py-0 d-flex align-items-center',
        containerInner: '',
        input: 'choices__input',
      },
    });
  }
}
