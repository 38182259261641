import { Controller } from 'stimulus';
import Quagga from '@ericblade/quagga2';

const _getMedian = (arr) => {
  arr.sort((a, b) => a - b);
  const half = Math.floor(arr.length / 2);
  if (arr.length % 2 === 1) {
    return arr[half];
  }
  return (arr[half - 1] + arr[half]) / 2;
};

const _getMedianOfCodeErrors = (decodedCodes) => {
  const errors = decodedCodes
    .filter((x) => x.error !== undefined)
    .map((x) => x.error);
  const medianOfErrors = _getMedian(errors);
  return medianOfErrors;
};

const handleProcessed = (result) => {
  const drawingCtx = Quagga.canvas.ctx.overlay;
  const drawingCanvas = Quagga.canvas.dom.overlay;
  drawingCtx.font = '20px Arial';
  drawingCtx.fillStyle = 'green';

  if (result) {
    // console.warn('* quagga onProcessed', result);
    if (result.boxes) {
      drawingCtx.clearRect(
        0,
        0,
        parseInt(drawingCanvas.getAttribute('width')),
        parseInt(drawingCanvas.getAttribute('height'))
      );
      result.boxes
        .filter((box) => box !== result.box)
        .forEach((box) => {
          Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
            color: 'purple',
            lineWidth: 2,
          });
        });
    }
    if (result.box) {
      Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
        color: 'blue',
        lineWidth: 2,
      });
    }
    if (result.codeResult && result.codeResult.code) {
      // const validated = barcodeValidator(result.codeResult.code);
      // const validated = validateBarcode(result.codeResult.code);
      // Quagga.ImageDebug.drawPath(result.line, { x: 'x', y: 'y' }, drawingCtx, { color: validated ? 'green' : 'red', lineWidth: 3 });
      drawingCtx.font = '20px Arial';
      // drawingCtx.fillStyle = validated ? 'green' : 'red';
      // drawingCtx.fillText(`${result.codeResult.code} valid: ${validated}`, 10, 50);
      drawingCtx.fillText(result.codeResult.code, 10, 20);
      // if (validated) {
      //     onDetected(result);
      // }
    }
  }
};

export default class extends Controller {
  static values = { streamId: String };
  static targets = [
    'barcodeStartSearchBtn',
    'barcodeStopSearchBtn',
    'lookupCodeInput',
    'status',
    'preview',
    'lookupForm',
  ];

  stopScan() {
    Quagga.stop();
    this.previewTarget.innerHTML = '';
    this.barcodeStopSearchBtnTarget.hidden = true;
    this.barcodeStartSearchBtnTarget.hidden = false;
  }

  startScan() {
    Quagga.init(
      {
        inputStream: {
          name: 'Live',
          type: 'LiveStream',
          target: '.barcode-scanner-preview',
          constraints: {
            facingMode: 'environment',
          },
        },
        decoder: {
          readers: [
            'code_128_reader',
            'ean_reader',
            'ean_8_reader',
            'code_39_reader',
            'code_39_vin_reader',
            'codabar_reader',
            'upc_reader',
            'upc_e_reader',
            'i2of5_reader',
            '2of5_reader',
            'code_93_reader',
            'code_32_reader',
          ],
        },
      },
      (err) => {
        if (err) {
          console.log(err);
          return;
        }
        console.log('Initialization finished. Ready to start');
        Quagga.onProcessed(handleProcessed);
        Quagga.start();
        this.barcodeStopSearchBtnTarget.hidden = false;
        this.barcodeStartSearchBtnTarget.hidden = true;
        Quagga.onDetected((result) => {
          console.log(result);
          var code = result.codeResult.code;
          console.log('Detected Code: ', code);
          const err = _getMedianOfCodeErrors(result.codeResult.decodedCodes);

          // if Quagga is at least 75% certain that it read correctly, then accept the code.
          if (err < 0.1) {
            console.log('Detected code after error');
            console.log(result.codeResult.code);
            this.lookupCodeInputTarget.value = result.codeResult.code;
            this.lookupFormTarget.requestSubmit();
            Quagga.stop();
            this.previewTarget.innerHTML = '';
            this.barcodeStopSearchBtnTarget.hidden = true;
            this.barcodeStartSearchBtnTarget.hidden = false;
          }
        });
      }
    );
  }
}
