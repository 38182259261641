import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['weekdays'];
  connect() {}

  static days = {
    sunday: 1,
    monday: 2,
    tuesday: 4,
    wednesday: 8,
    thursday: 16,
    friday: 32,
    saturday: 64,
  };

  toggleInputs(e) {
    let element = e.target;
    let checked = element.checked;
    if (isNaN(parseInt(this.weekdaysTarget.value))) {
      this.weekdaysTarget.value = 0;
    }
    if (checked) {
      this.weekdaysTarget.value =
        parseInt(this.weekdaysTarget.value) + this.constructor.days[element.id];
    } else {
      this.weekdaysTarget.value =
        parseInt(this.weekdaysTarget.value) - this.constructor.days[element.id];
    }
  }
}
