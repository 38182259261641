import { Controller } from 'stimulus';
import StimulusReflex from 'stimulus_reflex';
import storageAvailable from '../utils/localStorage';

export default class extends Controller {
  static targets = ['availability'];

  connect() {
    StimulusReflex.register(this);
    this.localStoragePresent = storageAvailable('localStorage');
  }

  open(event) {
    event.preventDefault();
    this.handleDom(true);
  }

  clickOutside(event) {
    this.handleDom(false);
  }

  handleDom(status = false) {
    const dom = document.getElementById('mobile_sidebar');
    if (status) {
      dom.classList.add('show');
    } else {
      dom.classList.remove('show');
    }
  }

  toggleExpand() {
    let sidebarEl = document.getElementById('sidebar');
    if (sidebarEl.classList.contains('expanded')) {
      this.localStoragePresent && localStorage.setItem('navbar', 'compact');
      this._collapse();
    } else {
      this.localStoragePresent && localStorage.setItem('navbar', 'expanded');
      this._expand();
    }
  }

  _collapse() {
    let sidebarEl = document.getElementById('sidebar');
    let mainEl = document.getElementById('main');
    sidebarEl.classList.remove('expanded');
    sidebarEl.classList.add('compact');
    mainEl.classList.add('expanded');
  }

  _expand() {
    let sidebarEl = document.getElementById('sidebar');
    let mainEl = document.getElementById('main');
    sidebarEl.classList.remove('compact');
    sidebarEl.classList.add('expanded');
    mainEl.classList.remove('expanded');
  }

  updatePresence() {
    this.stimulate(
      'PresenceReflex#update_presence',
      this.availabilityTarget.checked ? 'online' : 'unavailable'
    );
  }
}
