import { Controller } from 'stimulus';
import StimulusReflex from 'stimulus_reflex';

export default class extends Controller {
  connect() {
    StimulusReflex.register(this);
  }

  open(start, end, selectedUserId) {
    const duration = (end - start) / 60000; // convert millisecond to minute
    const startTime = new Date(start.getTime());
    if (startTime.getTime() < Date.now()) {
      return;
    }
    this.element.classList.remove('close');
    this.element.dataset.start = startTime.toISOString();
    this.element.dataset.duration = duration;
    this.element.dataset.selectedUserId = selectedUserId;
    this.stimulate('SessionSidebarComponent#update_start_time');
  }

  close(event) {
    event.preventDefault();
    this.element.classList.add('close');
    this.stimulate('SessionSidebarComponent#close');
    const { success } = event.target.parentNode.dataset;
    if (success == 'true') {
      this.triggerCalendarElements();
    }
  }

  triggerCalendarElements() {
    const switchWeekDom = document.getElementById('switch_week_link');
    if (switchWeekDom.classList.contains('btn-purple')) {
      switchWeekDom.click();
    }
    const switchDayDom = document.getElementById('switch_day_link');
    if (switchDayDom.classList.contains('btn-purple')) {
      switchDayDom.click();
    }
  }
}
