import { Controller } from 'stimulus';
import { get } from '@rails/request.js';

export default class extends Controller {
  static values = {
    updateHistory: Boolean,
  };
  connect() {
    let url = this.element.href;
    this.element.addEventListener('click', (e) => {
      e.preventDefault();
      let spinnerEl = document.querySelector('.loading-spinner');
      if (spinnerEl) {
        spinnerEl.classList.remove('d-none');
      }
      if (this.hasUpdateHistoryValue && this.updateHistoryValue) {
        history.pushState({ turbo_frame_history: true }, '', url);
      }
      let resp = get(url, { responseKind: 'turbo-stream' });
      resp.finally(() => {
        if (spinnerEl) {
          spinnerEl.classList.add('d-none');
        }
      });
    });
  }
}
