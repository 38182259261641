import { Controller } from 'stimulus';
import { EditorView, basicSetup } from 'codemirror';
import { html } from '@codemirror/lang-html';

export default class extends Controller {
  static targets = [];

  connect() {
    let textarea = this.element;
    this.view = new EditorView({
      doc: textarea.value,
      extensions: [basicSetup, html()],
    });
    textarea.parentNode.insertBefore(this.view.dom, textarea);
    textarea.style.display = 'none';
    if (textarea.form)
      textarea.form.addEventListener('submit', () => {
        textarea.value = this.view.state.doc.toString();
      });
  }
}
