import { Controller } from 'stimulus';
import { post, get } from '@rails/request.js';

export default class extends Controller {
  static values = { createUrl: String };

  connect() {
    this._subscribeToCartEvents();
    window.parent.postMessage({ name: 'gs:get-cart' }, '*');
  }

  removeCartItem(e) {
    let cartEvent = {
      name: 'gs:remove-cart-data',
      data: {
        id: e.target.dataset.id,
        quantity: 0,
      },
    };
    window.parent.postMessage(cartEvent, '*');
    this.createEvent(
      'product_removed',
      'user',
      JSON.parse(e.target.dataset.data)
    );
  }

  async createEvent(event_name, event_type, data) {
    // eslint-disable-next-line
    const response = await post(this.createUrlValue, {
      responseKind: 'turbo-stream',
      body: JSON.stringify({
        event_name: event_name,
        event_type: event_type,
        data: data,
      }),
    });
  }

  async refresh() {
    // eslint-disable-next-line
    const response = await get(this.createUrlValue, {
      responseKind: 'turbo-stream',
    });
  }

  _subscribeToCartEvents() {
    window.addEventListener('message', (event) => {
      let eventData = event.data;
      if (eventData.name == 'gs:cart-data') {
        this.createEvent('cart_updated', 'system', eventData.data);
        eventData.data.items.forEach((item) => {
          this.createEvent('product_added', 'system', item);
        });
      } else if (eventData.name == 'gs:cart-data-added') {
        eventData.data.items.forEach((item) => {
          this.createEvent('product_added', 'user', item);
        });
      }
    });
  }
}
