import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['timeField', 'copyTime'];

  connect() {}

  toggleInputs(e) {
    let element = e.target;
    let checked = element.checked;
    let form_groups = document.querySelectorAll(`.${element.id}_group`);
    if (checked) this._checkOfficeHour(form_groups);
    else this._uncheckOfficeHour(form_groups);
  }

  _uncheckOfficeHour(elements) {
    elements.forEach((form_group) => {
      form_group.classList.replace('d-block', 'd-none');
      form_group.querySelectorAll('input').forEach((el) => {
        if (el.name.indexOf('_destroy') > -1) el.value = 1;
      });
    });
  }

  _checkOfficeHour(elements) {
    elements.forEach((form_group) => {
      form_group.classList.replace('d-none', 'd-block');
      form_group.querySelectorAll('input').forEach((el) => {
        if (el.name.indexOf('_destroy') > -1) el.value = false;
      });
    });
  }

  copyTime() {
    let container = document.querySelectorAll('.day-group.d-block')[0];
    if (container) {
      let start_time = container.querySelector('.start_at');
      let end_time = container.querySelector('.close_at');
      document.querySelectorAll('.start_at').forEach((el) => {
        el.value = start_time.value;
      });
      document.querySelectorAll('.close_at').forEach((el) => {
        el.value = end_time.value;
      });
    }
  }
}
