import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['bar'];
  connect() {
    const snackbar = this.barTarget;
  }

  show(message, expire = true) {
    this.barTarget.style.animation = 'fadeIn 0.5s forwards';
    this.barTarget.style.visibility = 'visible';
    this.barTarget.style.boxShadow = '0 0 5px rgba(0,0,0,0.3)';
    this.barTarget.textContent = message;

    if (expire && !this._timer) {
      this._timer = setTimeout(() => {
        console.log('Expired is working');
        this.hide();
        this._timer = null;
      }, 5000);
    }
  }

  hide() {
    this.barTarget.style.animation = 'fadeOut 0.5s 4.5s';
    this.barTarget.style.visibility = 'hidden';
    this.barTarget.textContent = '';
  }
}
