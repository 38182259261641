import consumer from './consumer';
import CableReady from 'cable_ready';

let subscribeToVideoCallChannel = () => {
  let videoCallContainerEl = document.querySelector(
    '[data-video-call-channel-name]'
  );
  if (videoCallContainerEl) {
    let channelName = videoCallContainerEl.dataset.videoCallChannelName;
    let token = videoCallContainerEl.dataset.participationToken;
    let role = videoCallContainerEl.dataset.participationRole;
    consumer.subscriptions.create(
      {
        channel: 'VideoCallChannel',
        channel_name: channelName,
        token: token,
        role: role,
      },
      {
        received(data) {
          if (data.cableReady) CableReady.perform(data.operations);
        },
      }
    );
  }
};
document.addEventListener('turbo:load', () => {
  subscribeToVideoCallChannel();
});

document.addEventListener('turbo:render', () => {
  subscribeToVideoCallChannel();
});
