import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content'];

  connect() {
    this.open = false;
  }

  toggle(event) {
    this.open === false ? this.show(event) : this.hide(event);
  }

  show() {
    this.open = true;
    this.contentTarget.style.setProperty('--read-more-line-clamp', '\'unset\'');
  }

  hide() {
    this.open = false;
    this.contentTarget.style.removeProperty('--read-more-line-clamp');
  }
}
