import flatpickr from 'flatpickr';
import StimulusReflex from 'stimulus_reflex';
import { Controller } from 'stimulus';

class DatePickerController extends Controller {
  connect() {
    StimulusReflex.register(this);
    document.addEventListener('cable-ready:after-morph', () => {
      this.initializeDatePicker();
    });
    this.initializeDatePicker();
  }

  initializeDatePicker() {
    const fromDate = document.querySelector('#from_date');
    flatpickr(fromDate, {
      altInput: true,
      altFormat: 'j F Y',
      dateFormat: 'Y-m-d',
      defaultDate: fromDate.value,
    });
    const toDate = document.querySelector('#to_date');
    flatpickr(toDate, {
      altInput: true,
      altFormat: 'j F Y',
      dateFormat: 'Y-m-d',
      defaultDate: toDate.value,
    });
  }

  setStartTime(e) {
    this.stimulate('UnavailableSlotsComponent#set_starttime', e.target);
  }

  setEndTime(e) {
    this.stimulate('UnavailableSlotsComponent#set_endtime', e.target);
  }
}

export default DatePickerController;
