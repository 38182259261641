import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['select', 'button'];

  connect() {
    this.showOrHideTarget(this.selectTarget.value);
    this.selectTarget.addEventListener('change', (e) => {
      this.showOrHideTarget(e.target.value);
    });
  }

  showOrHideTarget(select_value) {
    if (select_value === 'single_select' || select_value === 'multi_select') {
      this.buttonTarget.classList.remove('d-none');
    } else {
      this.buttonTarget.classList.add('d-none');
    }
  }
}
