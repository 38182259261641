import { Controller } from 'stimulus';
import { get } from '@rails/request.js';

export default class extends Controller {
  static values = { url: String };
  static targets = ['input'];

  connect() {
    console.log('dashboard controller loaded');
  }

  async apply(e) {
    let url = this.urlValue + '?daterange=' + this.inputTarget.value;
    const response = await get(url, { responseKind: 'turbo-stream' });
    if (response.ok) {
      console.log('response worked');
    }
    e.preventDefault();
  }
}
