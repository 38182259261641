import { Controller } from 'stimulus';
import StimulusReflex from 'stimulus_reflex';
import initIntlTelephoneInput from '../lib/telephone_input.js';

export default class extends Controller {
  static targets = ['cancelModal', 'rescheduleModal'];
  connect() {
    StimulusReflex.register(this);
    let el = document.querySelector('.add_fields');
    if (el)
      el.addEventListener('click', (e) => {
        this.refreshIntlTelephoneInput(e);
      });
  }

  refreshIntlTelephoneInput() {
    setTimeout(() => {
      initIntlTelephoneInput();
    }, 50);
  }

  scrollToFilters() {
    let el = document.querySelector('#session_listing_container');
    if (el.scrollTop > 0) el.scrollTo(0, 0);
  }

  launchCancelModal(event) {
    let modalController = this.application.getControllerForElementAndIdentifier(
      this.cancelModalTarget,
      'modal'
    );
    modalController.setContent(event.currentTarget.dataset);
    modalController.open();

    console.log(event.currentTarget.dataset);
    event.stopPropagation();
  }
  launchRescheduleModal(event) {
    let modalController = this.application.getControllerForElementAndIdentifier(
      this.rescheduleModalTarget,
      'modal'
    );
    modalController.setContent(event.currentTarget.dataset);
    modalController.open();
    console.log(event.currentTarget.dataset);
    event.stopPropagation();
  }
}
