import { Controller } from 'stimulus';
import { EmojiButton } from '@joeattardi/emoji-button';

export default class extends Controller {
  static targets = [];
  static values = { editor: String };
  connect() {
    const picker = new EmojiButton();
    const trigger = this.element;

    picker.on('emoji', (selection) => {
      let editorController =
        this.application.getControllerForElementAndIdentifier(
          this.editorElement(),
          'customize-trix'
        );
      editorController.insertString(selection.emoji);
    });
    trigger.addEventListener('click', () => {
      picker.togglePicker(trigger);
    });
  }

  editorElement() {
    if (this.hasEditorValue && this.editorValue) {
      return document.querySelector("#" + this.editorValue);
    }
    return document.querySelector('trix-editor');
  }
}
