// app/javascript/channels/chat_channel.js
import consumer from './consumer';
import CableReady from 'cable_ready';

document.addEventListener('turbo:load', () => {
  let notificationsContainerEl = document.querySelector(
    '[data-channel-subscription=notifications]'
  );
  if (notificationsContainerEl) {
    let channelName = notificationsContainerEl.dataset.channelUid;
    consumer.subscriptions.create(
      { channel: 'NotificationsChannel', room: channelName },
      {
        received(data) {
          let audio = new Audio('/notification-beep.mp3');
          audio.play();
          if (data.cableReady) CableReady.perform(data.operations);
        },
      }
    );
  }
});
