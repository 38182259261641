import { Controller } from 'stimulus';

export default class extends Controller {
  static values = { country: String };
  static targets = [
    'basicPrice',
    'premiumPrice',
    'period',
    'basicPricingSelect',
    'premiumPricingSelect',
    'period',
    'periodSelect',
    'basicBuyButton',
    'premiumBuyButton',
  ];

  connect() {
    let pricingUrl =
      process.env.NODE_ENV === 'production'
        ? 'https://w.gumstack.com/pricing.json'
        : 'https://w.gumstack.com/dev_pricing.json';
    fetch(pricingUrl)
      .then((response) => response.json())
      .then((data) => {
        this.pricing_data = data;
        this.updatePrice();
      });
  }

  updatePrice() {
    this.periodTargets.forEach((period) => {
      period.innerText = this.period();
    });
    this.updateBasicPrice();
    this.updatePremiumPrice();
  }

  updateBasicPrice() {
    this.basicPriceTarget.innerText =
      this.currency() + this.priceForPlan(this.basicPricingSelectTarget.value);

    let publishableKey =
      this.basicBuyButtonTarget.getAttribute('publishable-key');
    let customerSessionClientSecret = this.basicBuyButtonTarget.getAttribute(
      'customer-session-client-secret'
    );

    let stripeBuyButton = document.createElement('stripe-buy-button');
    stripeBuyButton.setAttribute(
      'buy-button-id',
      this.buy_button_id(this.basicPricingSelectTarget.value)
    );
    stripeBuyButton.setAttribute('publishable-key', publishableKey);
    stripeBuyButton.setAttribute(
      'customer-session-client-secret',
      customerSessionClientSecret
    );
    this.basicBuyButtonTarget.innerHTML = '';
    this.basicBuyButtonTarget.appendChild(stripeBuyButton);

    this.buy_button_id(this.basicPricingSelectTarget.value);
  }

  updatePremiumPrice() {
    this.premiumPriceTarget.innerText =
      this.currency() +
      this.priceForPlan(this.premiumPricingSelectTarget.value);

    let publishableKey =
      this.premiumBuyButtonTarget.getAttribute('publishable-key');
    let customerSessionClientSecret = this.premiumBuyButtonTarget.getAttribute(
      'customer-session-client-secret'
    );

    let stripeBuyButton = document.createElement('stripe-buy-button');
    stripeBuyButton.setAttribute(
      'buy-button-id',
      this.buy_button_id(this.premiumPricingSelectTarget.value)
    );
    stripeBuyButton.setAttribute('publishable-key', publishableKey);
    stripeBuyButton.setAttribute(
      'customer-session-client-secret',
      customerSessionClientSecret
    );
    this.premiumBuyButtonTarget.innerHTML = '';
    this.premiumBuyButtonTarget.appendChild(stripeBuyButton);
  }

  currencyCode() {
    return this.countryValue === 'india' ? 'inr' : 'usd';
  }

  currency() {
    return this.countryValue === 'india' ? '₹' : '$';
  }

  payment_link(tier) {
    return this.pricing_data[tier][this.planPeriod()]['payment_link'];
  }

  buy_button_id(tier) {
    return this.pricing_data[tier][this.planPeriod()]['buy_button_id'];
  }

  priceForPlan(tier) {
    return this.pricing_data[tier][this.planPeriod()][this.currencyCode()];
  }

  planPeriod() {
    return this.periodSelectTarget.checked ? 'yearly' : 'monthly';
  }

  period() {
    return this.periodSelectTarget.checked ? 'year' : 'month';
  }
}
