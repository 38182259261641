import { Controller } from 'stimulus';
import { get } from '@rails/request.js';

export default class extends Controller {
  static targets = ['storyPageSelector'];

  connect() {
    this.selected_story_page_id = this.storyPageSelectorTarget.value;
    this.selected_story_page_url =
      this.storyPageSelectorTarget.selectedOptions[0].dataset.storyPageUrl;
  }

  async pageSelected(e) {
    this.selected_story_page_id = this.storyPageSelectorTarget.value;
    this.selected_story_page_url =
      e.target.selectedOptions[0].dataset.storyPageUrl;
    await get(this.selected_story_page_url, {
      responseKind: 'turbo-stream',
    });
  }
}
