// 🪦
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input'];

  connect() {
    this.inputTarget.addEventListener('input', this.underscore.bind(this));
    this.underscore();
  }

  underscore() {
    let inputValue = this.inputTarget.value;
    this.inputTarget.value = inputValue.replace(/ /g, '_').replace(/[^a-zA-Z0-9_]/g, '');
  }
}
