import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input', 'counter'];
  static values = { maxLength: Number };

  connect() {
    this.inputTarget.addEventListener('input', this.count.bind(this));
    this.count();
  }

  count() {
    let inputValue = this.inputTarget.value;
    let length = inputValue.length;

    if (length > this.maxLengthValue) {
      inputValue = inputValue.substring(0, this.maxLengthValue);
      length = this.maxLengthValue;
    }

    this.inputTarget.value = inputValue;
    this.counterTarget.textContent = `${length}/${this.maxLengthValue}`;
  }
}
