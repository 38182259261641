import { Controller } from 'stimulus';
import { closeWidget } from '../helpers';

export default class extends Controller {
  static targets = [
    'submit',
    'comment',
    'feedbackSuccess',
    'feedbackFormContainer',
    'feedbackImgContainer',
  ];

  connect() {
    if (this.hasFeedbackSuccessTarget) {
      setTimeout(() => {
        closeWidget();
      }, 1500);
    }
    document
      .querySelectorAll('input[type=radio][name="feedback[rating]"]')
      .forEach((el) => {
        el.addEventListener('change', (e) => this.changePlaceholder(e));
      });
  }

  enableFeedback() {
    this.submitTarget.disabled = false;
    this.feedbackFormContainerTarget.classList.remove('d-none');
    this.feedbackImgContainerTarget.classList.remove('flex-column');
    this.feedbackImgContainerTarget.firstElementChild.firstElementChild.style.width =
      '28px';
    this.feedbackImgContainerTarget.firstElementChild.firstElementChild.style.height =
      '28px';
    this.feedbackImgContainerTarget.parentElement.classList.remove(
      'flex-grow-1'
    );
    this.feedbackImgContainerTarget.parentElement.nextElementSibling.classList.add(
      'flex-fill'
    );
  }

  changePlaceholder(e) {
    if (e.target.value <= 4) {
      this.commentTarget.placeholder = 'Tell us what could we do better?';
    } else {
      this.commentTarget.placeholder = 'What did you like the most?';
    }
  }

  scrollToFilters() {
    let el = document.querySelector('#feedback_listing_container');
    if (el.scrollTop > 0) el.scrollTo(0, 0);
  }
}
