import { Controller } from 'stimulus';
import moment from 'moment';

export default class extends Controller {
  static targets = ['container'];
  static values = { delay: String, frt: String };

  connect() {
    this.delay = parseInt(this.delayValue);
    this.frt = parseInt(this.frtValue);
    if (this.delay && this.frt) {
      this.timer = setInterval(() => {
        this.updateTimer();
      }, 1000);
    }
  }

  updateTimer() {
    if (this.delay < this.frt) {
      this.containerTarget.classList.remove('text-danger');
      this.containerTarget.classList.add('text-warning');
      this.containerTarget.innerText =
        'FRT: due ' +
        moment()
          .add(this.frt - this.delay, 'seconds')
          .fromNow();
    } else {
      this.containerTarget.classList.add('text-danger');
      this.containerTarget.classList.remove('text-warning');
      this.containerTarget.innerText =
        'FRT: overdue by ' +
        moment()
          .add(this.delay - this.frt, 'seconds')
          .fromNow(true);
    }
    this.delay += 1;
  }

  disconnect() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
}
