import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['wrapper'];

  showSideCar() {
    this.wrapperTarget.style.animation = 'fadeIn 0.5s forwards';
    this.wrapperTarget.classList.toggle('d-none');
  }

  hideSideCar() {
    this.wrapperTarget.style.animation = 'fadeOut 0.5s 4.5s';
    this.wrapperTarget.classList.toggle('d-none');
  }
}
