import { Controller } from 'stimulus';
import { lightOrDark } from '../helpers';
import hljs from 'highlight.js/lib/core';
import xml from 'highlight.js/lib/languages/xml';
hljs.registerLanguage('xml', xml);
import 'highlight.js/styles/stackoverflow-light.css';
export default class extends Controller {
  static targets = [
    'container',
    'colorPicker',
    'colorInput',
    'colorTheme',
    'color',
    'mockup',
    'mockupLogo',
    'bubbleRadio',
    'cardRadio',
    'emailCbx',
    'phoneNumberCbx',
    'cbxError',
    'form',
    'noBot',
  ];

  connect() {
    this._handleWidgetSelection();
    this._highlightCode();
    if (this.hasColorTarget) this.handleColorSelection();
  }

  logoChanged(e) {
    let input = e.target;
    let logo = document.getElementById(input.dataset.logoId);
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = (e) => {
        logo.src = e.target.result;
        logo.classList.remove('d-none');
      };
      reader.readAsDataURL(input.files[0]);
      if (input.dataset.hasOwnProperty('textId'))
        document.getElementById(input.dataset.textId).innerText =
          input.files[0].name;
    }
  }

  positionChanged(e) {
    let position_x_label = document.getElementById('position_x_label');
    if (position_x_label) {
      position_x_label.textContent = e.target.value == "bottom-left" ? "From Left" : "From Right"
    }
  }

  colorChanged(e) {
    let value = e.target.value;
    if (e.target == this.colorInputTarget) {
      if (this._is_valid_color(value)) this.chooseColor(value);
    } else this.chooseColor(value);
  }

  setBrandColor(color) {
    let theme = lightOrDark(color);
    this.colorThemeTarget.value = theme;
    this.containerTarget.dataset.themeMode = theme;
    this.containerTarget.style.cssText = `--brandColor:${color}`;
  }

  handleColorSelection() {
    this.colorTargets.forEach((element) => {
      element.addEventListener('click', () => {
        this.colorClickEvent(element);
      });
    });
  }

  colorClickEvent(el) {
    let color = el.dataset.value;
    if (!el.classList.contains('active')) {
      el.classList.add('active');
      this.chooseColor(color);
      this._reset_color_selection(el);
    }
  }

  chooseColor(color) {
    this.colorPickerTarget.value = color;
    this.colorInputTarget.value = color;
    this.setBrandColor(color);
  }

  reload() {
    window.location.reload();
  }

  scrollToMockup() {
    this.mockupTarget.scrollIntoView({ behavior: 'smooth' });
  }

  changeFragment(e) {
    let fragment = document.getElementById(e.target.dataset.fragmentId);
    if (fragment) {
      this._reset_fragment(fragment);
      this._setData(fragment, 'display', 'true');
    }
  }

  changeMockup(e) {
    let mockup = document.getElementById(e.target.dataset.mockupId);
    if (mockup) {
      this._reset_mockup(mockup);
      this._setData(mockup, 'display', 'true');
    }
  }

  changeCtaText(e) {
    let cta = document.getElementById(e.target.dataset.ctaId);
    if (cta) cta.innerText = e.target.value;
  }

  noBotsChanged(e) {
    if (e.target.checked) this.noBotTarget.classList.remove('d-none');
    else this.noBotTarget.classList.add('d-none');
  }

  selectType(type) {
    let is_bubble = type == 'bubble';
    this.cardRadioTarget.dataset.active = is_bubble ? false : true;
    this.bubbleRadioTarget.dataset.active = is_bubble ? true : false;
    let fragment = document.getElementById(type);
    if (fragment) {
      this._reset_fragment(fragment);
      this._setData(fragment, 'display', 'true');
    }
  }

  validateCbx() {
    if (!this.emailCbxTarget.checked && !this.phoneNumberCbxTarget.checked) {
      this.cbxErrorTarget.innerText =
        'Phone number or Email Address is Required';
      this.formInvalid = true;
    } else {
      this.cbxErrorTarget.innerText = '';
      this.formInvalid = false;
    }
  }

  submitForm() {
    if (this.formInvalid == true) {
      let form = document.getElementById('widgetfour');
      $('#collapseWidgetfour').collapse('show');
      form.scrollIntoView({ behavior: 'smooth' });
    } else {
      this.formTarget.submit();
    }
  }

  _setData(el, attrib, value) {
    el.dataset[attrib] = value;
  }

  _reset_fragment(fragment) {
    let fragments = document.querySelectorAll('.fragment');
    fragments.forEach((el) => {
      if (fragment.id != el.id) this._setData(el, 'display', 'false');
    });
  }

  _reset_mockup(mockup) {
    let mockups = document.querySelectorAll('.mockup');
    mockups.forEach((el) => {
      if (mockup.id != el.id) this._setData(el, 'display', 'false');
    });
  }

  _reset_color_selection(el) {
    this.colorTargets.forEach((element) => {
      if (element != el) element.classList.remove('active');
    });
  }

  _is_valid_color(color) {
    return /^#([0-9A-F]{3}){1,2}$/i.test(color);
  }

  _handleWidgetSelection() {
    if (this.hasBubbleRadioTarget && this.hasCardRadioTarget) {
      this.bubbleRadioTarget.onclick = () => {
        this.selectType('bubble');
      };
      this.cardRadioTarget.onclick = () => {
        this.selectType('card');
      };
      this._autoSelect();
    }
  }

  _autoSelect() {
    if (this.element.dataset.callback != 'true') {
      if (this.bubbleRadioTarget.dataset.active == 'true')
        this.selectType('bubble');
      if (this.cardRadioTarget.dataset.active == 'true')
        this.selectType('card');
    }
  }

  _highlightCode() {
    document.querySelectorAll('code').forEach((el) => {
      hljs.highlightElement(el);
    });
  }
}
