import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    setInterval(() => {
      this.element.classList.remove('show');
      this.element.classList.add('hide');
    }, 4000);
  }
}
