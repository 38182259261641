import { Controller } from 'stimulus';
import { setWidgetVideoCallStatus } from '../helpers';
import storageAvailable from '../utils/localStorage';
import { patch } from '@rails/request.js';
import moment from 'moment';
export default class extends Controller {
  static targets = [
    'incomingContainer',
    'mic',
    'camera',
    'avatar',
    'action',
    'status',
    'wrapper',
    'delayedForm',
    'submitBtn',
    'scheduleCallBackBtn',
    'timer',
    'minute',
    'second',
    'timerText',
    'timeoutText',
    'purposeFormContainer',
    'purposeSubmitBtn',
    'purposeTextArea',
    'purposeTextAreaContainer',
    'purposeLabels',
  ];

  async connect() {
    this.audio_mode = this.data.get('audioMode') == 'true';
    if (this.hasIncomingContainerTarget) {
      try {
        await import('agora-rtc-sdk-ng');
      } catch (e) {
        if (e instanceof DOMException && e.code == 18) {
          console.log('local storage permission denied');
        } else {
          throw e;
        }
      }
      this.initVideo();
    }
    if (this.hasActionTarget && this.hasWrapperTarget)
      this.initTimeoutMessage();

    if (this.hasScheduleCallBackBtnTarget) this.initEndCallTimer();

    this._checkAndInitForm();
    this._setStatusLoop();

    if (this.hasSubmitBtnTarget) {
      this.element.addEventListener('turbo:submit-start', () => {
        this.submitBtnTarget.disabled = true;
        this.submitBtnTarget.innerText = 'Connecting...';
      });

      this.element.addEventListener('turbo:submit-end', () => {
        this.submitBtnTarget.disabled = false;
        this.submitBtnTarget.innerText = 'Done';
      });
    }
    this._handleEarlyTimer();
    if (this.hasPurposeFormContainerTarget) {
      this.purposeFormContainerTarget.classList.remove('d-none');
    }
    this.localStoragePresent = storageAvailable('localStorage');
    this.localStoragePresent && localStorage.setItem('camera_on', false);
  }

  initTimeoutMessage() {
    setTimeout(() => {
      this.actionTarget.classList.add('active');
      this.wrapperTarget.classList.add('active');
    }, 35000);
  }

  closePurposeFormContainer() {
    if (this.hasPurposeFormContainerTarget) {
      this.purposeFormContainerTarget.classList.add('d-none');
    }
  }

  onPurposeTextChange() {
    if (
      this.purposeTextAreaTarget.value === '' ||
      this.purposeTextAreaTarget.value === null
    ) {
      this.purposeSubmitBtnTarget.disabled = true;
      this.purposeLabelsTarget.classList.replace('d-none', 'd-flex');
    } else {
      this.purposeSubmitBtnTarget.disabled = false;
      this.purposeLabelsTarget.classList.replace('d-flex', 'd-none');
    }
  }

  onPurposeSelect(e) {
    if (e.target.dataset.showTextarea == 'true') {
      this.purposeTextAreaContainerTarget.classList.remove('d-none');
      this.purposeTextAreaTarget.value = '';
    } else {
      this.purposeTextAreaContainerTarget.classList.add('d-none');
      this.purposeTextAreaTarget.value = e.target.innerText;
      this.purposeSubmitBtnTarget.disabled = false;
      this.purposeSubmitBtnTarget.click();
    }
  }

  async submitPurpose(e) {
    console.log(this.purposeFormContainerTarget.firstElementChild);
    this.purposeFormContainerTarget.firstElementChild.style.minHeight = '100%';
    await patch(e.target.dataset.url, {
      responseKind: 'turbo-stream',
      body: JSON.stringify({
        purpose: this.purposeTextAreaTarget.value,
      }),
    });
  }

  initEndCallTimer() {
    setTimeout(() => {
      this.scheduleCallBackBtnTarget.click();
    }, 55000);
  }

  initVideo() {
    // let streamid = Math.floor(Math.random() * (9999 - 1000 + 1) + 1000);
    // const localAudio = await AgoraRTC.createMicrophoneAudioTrack();
    if (this.audio_mode) {
      this.avatarTarget.classList.add('show');
      return;
    }

    // this.videoStream = AgoraRTC.createStream({
    //   streamID: streamid,
    //   audio: true,
    //   video: !this.audio_mode,
    // });
    // if (!this.audio_mode) {
    //   this.videoStream.init(() => {
    //     this.videoStream.play('user-video');
    //   });
    // }
    this.checkLocalMicCamPrefs();
  }

  toggleAudio() {
    var is_mic_on = this.micTarget.innerText == 'mic';
    if (is_mic_on) {
      this.micTarget.innerText = 'mic_off';
      this.micTarget.parentElement.classList.add('active');
    } else {
      this.micTarget.innerText = 'mic';
      this.micTarget.parentElement.classList.remove('active');
    }
    this.localStoragePresent && localStorage.setItem('mic_on', !is_mic_on);
  }

  async toggleVideo() {
    var is_camera_on = this.cameraTarget.innerText == 'videocam';
    if (is_camera_on) {
      this.cameraTarget.innerText = 'videocam_off';
      this.cameraTarget.parentElement.classList.add('active');
      this.videoStream && this.videoStream.setEnabled(false);
      if (this.videoStream && this.videoStream.isPlaying)
        this.videoStream.stop();
      this.localStoragePresent && localStorage.setItem('camera_on', false);
    } else {
      this.cameraTarget.innerText = 'videocam';
      this.cameraTarget.parentElement.classList.remove('active');
      if (!this.videoStream) {
        // eslint-disable-next-line
        this.videoStream = await AgoraRTC.createCameraVideoTrack();
      }
      this.localStoragePresent && localStorage.setItem('camera_on', true);
      this.videoStream.setEnabled(true);
      if (!this.videoStream.isPlaying) this.videoStream.play('user-video');
    }
    this.avatarTarget.classList.replace(
      `${this.cameraTarget.innerText == 'videocam' ? 'show' : 'hide'}`,
      `${this.cameraTarget.innerText == 'videocam' ? 'hide' : 'show'}`
    );
  }

  checkLocalMicCamPrefs() {
    if (!this.hasMicTarget || !this.hasCameraTarget) return;
    var is_mic_on = this.localStoragePresent && localStorage.getItem('mic_on');
    var is_camera_on =
      this.localStoragePresent && localStorage.getItem('camera_on');
    if (is_mic_on !== null && is_mic_on == 'false') this.toggleAudio();
    if (is_camera_on !== null && is_camera_on == 'true') this.toggleVideo();
  }

  endCall() {
    this.statusTarget.innerText = 'Disconnecting';
    if (!this.audio_mode) {
      this.videoStream && this.videoStream.stop();
      this.videoStream && this.videoStream.close();
    }
    setWidgetVideoCallStatus(false);
  }

  _checkAndInitForm() {
    if (this.hasDelayedFormTarget) {
      // setTimeout(() => {
      this.element.classList.add('formActive');
      // }, 2000);
    }
  }

  _setStatusLoop() {
    if (this.hasStatusTarget)
      setInterval(() => {
        if (this.statusTarget.innerText != 'Disconnecting')
          this.statusTarget.innerText = 'Connecting';
      }, 10000);
  }

  _handleEarlyTimer() {
    if (!this.hasTimerTarget) return;
    let start_time = moment(
      this.timerTarget.dataset.startTime,
      'YYYY-MM-DD hh:mm:ss'
    ).unix();
    let diff = start_time - moment().unix();
    let duration = moment.duration(diff * 1000, 'milliseconds');
    var intervalId = window.setInterval(() => {
      duration = moment.duration(duration - 1000, 'milliseconds');
      if (duration.seconds() <= 0 && duration.minutes() <= 0) {
        window.clearInterval(intervalId);
        return this._showWaitingMessage();
      }
      this.minuteTarget.innerText = duration.minutes();
      this.secondTarget.innerText = duration.seconds();
    }, 1000);
  }

  _showWaitingMessage() {
    if (
      !this.hasTimerTarget ||
      !this.hasTimerTextTarget ||
      !this.hasTimeoutTextTarget
    )
      return;
    this.timerTextTarget.classList.add('d-none');
    this.timeoutTextTarget.classList.remove('d-none');
  }
}
