import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.element.addEventListener('click', () => { this.onRowClick() });
  }

  onRowClick() {
    const url = this.element.dataset.href;
    Turbo.visit(url);
  }
}