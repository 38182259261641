import { Application } from 'stimulus';
import StimulusReflex from 'stimulus_reflex';
import consumer from '../../channels/consumer';

const application = Application.start();

import ActionPanelController from '../action_panel_controller';
import FeedbackController from '../feedback_controller';
import IncomingVideoCallController from '../incoming_video_call_controller';
import SnackController from '../snack_controller';
import TimeZoneSearchController from '../timezone_search_controller';
import VideoCallController from '../video_call_controller';
import VideoCallParticipationController from '../video_call_participation_controller';
import WidgetVideoCallController from '../widget_video_call_controller';
import DateSlotPickerController from '../date_slot_picker_controller';
import ShopifyWidgetOverlayController from '../shopify_widget_overlay_controller';
import BarcodeScannerController from '../barcode_scanner_controller';
import ClipboardController from '../clipboard_controller';
import WidgetPreviewController from '../widget_preview_controller';
import CallInviteController from '../call_invite_controller';
import CartController from '../cart_controller';
import ShareController from '../share_controller';
import GoogleAnalyticsController from '../google_analytics_controller';

application.register('action-panel', ActionPanelController);
application.register('feedback', FeedbackController);
application.register('incoming-video-call', IncomingVideoCallController);
application.register('snack', SnackController);
application.register('timezone-search', TimeZoneSearchController);
application.register('video-call', VideoCallController);
application.register(
  'video-call-participation',
  VideoCallParticipationController
);
application.register('widget-video-call', WidgetVideoCallController);
application.register('date-slot-picker', DateSlotPickerController);
application.register('shopify-widget-overlay', ShopifyWidgetOverlayController);
application.register('barcode-scanner', BarcodeScannerController);
application.register('clipboard', ClipboardController);
application.register('widget-preview', WidgetPreviewController);
application.register('call-invite', CallInviteController);
application.register('cart', CartController);
application.register('share', ShareController);
application.register('google-analytics', GoogleAnalyticsController);

StimulusReflex.initialize(application, { consumer, isolate: true });
