import { event } from 'jquery';
import { Controller } from 'stimulus';
import Analytics from '../lib/analytics';

export default class extends Controller {
  connect() {
    this.analytics = new Analytics();
    this.analytics.identify();
  }

  trackEvent(event) {
    const element = this.element;
    const event_name = element.dataset.eventName;
    const event_properties = this._get_event_properties(element.dataset);
    event_properties['scope'] = 'user_action';

    this.analytics.track(event_name, event_properties);
  }

  _get_event_properties(obj) {
    const event_properties = Object.keys(obj)
      .filter((key) => key.startsWith('analytics'))
      .reduce((filtered, key) => {
        filtered[key.split('analytics')[1]] = obj[key];
        return filtered;
      }, {});

    return event_properties;
  }
}
