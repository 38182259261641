// import Rails from '@rails/ujs'
import { Controller } from 'stimulus';
import StimulusReflex from 'stimulus_reflex';
import $ from 'jquery';
// let lastMessageId
// const reload = controller => {
//   controller.stimulate('ChatReflex#reload')
// }
// const debouncedReload = debounce(reload, 100)

export default class extends Controller {
  static targets = ['uploadModal', 'notificationTemplate'];
  connect() {
    StimulusReflex.register(this);
  }

  reload() {
    this.stimulate('Attachment#fetch', this.element.dataset.sessionId);
  }

  notify() {
    let appUID = this.element.dataset.appUid;
    let appParent = document.querySelector('#' + appUID + '-app');
    let isAppOn = appParent.dataset['appDisplay'] == 'true';
    if (!isAppOn) {
      let content = this.notificationTemplateTarget.innerHTML;
      const fragment = document.createRange().createContextualFragment(content);
      this.element.parentElement.appendChild(fragment);
      var rect = this.element.getBoundingClientRect();
      $('.app-notification').css('left', rect.x - 60);
      $('.app-notification').css('top', rect.y - 65);
    }
  }
}
