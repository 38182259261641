import { Controller } from 'stimulus';
import $ from 'jquery';

require('select2/dist/css/select2');
require('select2-bootstrap-theme/dist/select2-bootstrap');

import Select2 from 'select2';

export default class extends Controller {
  connect() {
    const dom = this.element.getElementsByClassName('gs-select2').item(0);
    const options = this.getOptions(dom);
    $(dom).select2(options);
  }

  getOptions(dom) {
    let options = { theme: 'bootstrap', dropdownParent: this.element, selectionCssClass: 'form-select' };
    for (let key in dom.dataset) {
      try {
        options[key] = JSON.parse(dom.dataset[key]);
      } catch (e) {
        console.log(e);
      }
    }
    console.log(options)
    return options;
  }
}

