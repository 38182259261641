import { Turbo } from '@hotwired/turbo-rails';
class MediaLibrary {
  constructor({ config, api }) {
    this.mediaLibraryUrl = config.endpoint;
    this.api = api;
  }

  render() {
    let block_index = this.api.blocks.getCurrentBlockIndex();
    fetch(this.mediaLibraryUrl + `&block_index=${block_index}`, {
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
      },
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html));
  }

  save(blockContent) {
    return {
      url: blockContent.value,
    };
  }

  static get toolbox() {
    return {
      title: 'Media library',
      icon: '<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20"><path d="M7.625 11.75h6.917L12.5 8.958l-2.021 2.563-1.396-1.75Zm-1.021 2.417q-.521 0-.854-.334-.333-.333-.333-.854V4.021q0-.521.333-.854.333-.334.854-.334h8.958q.521 0 .855.334.333.333.333.854v8.958q0 .521-.333.854-.334.334-.855.334Zm0-.667h8.958q.209 0 .365-.156t.156-.365V4.021q0-.209-.156-.365t-.365-.156H6.604q-.208 0-.364.156-.157.156-.157.365v8.958q0 .209.157.365.156.156.364.156Zm-2.166 2.833q-.521 0-.855-.333-.333-.333-.333-.854V5.521h.667v9.625q0 .208.156.364.156.157.365.157h9.624v.666ZM6.083 3.5V13.5 3.5Z"/></svg>',
    };
  }
}

export default MediaLibrary;
