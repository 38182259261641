import { Controller } from 'stimulus';
import moment from 'moment';

export default class extends Controller {
  static values = { format: String, time: String };
  static targets = ['display'];

  connect() {
    this.displayTarget.innerText = moment(this.timeValue).format(
      this.formatValue
    );
  }
}
