import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [];
  static values = {};
  connect() {
  }

  save() {
    const editorController = this.application.getControllerForElementAndIdentifier(document.querySelector("#editorjs"), 'editorjs')
    editorController?.save()
  }
}