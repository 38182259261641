// import Rails from '@rails/ujs'
import { Controller } from 'stimulus';
import StimulusReflex from 'stimulus_reflex';

// let lastMessageId
// const reload = controller => {
//   controller.stimulate('ChatReflex#reload')
// }
// const debouncedReload = debounce(reload, 100)

export default class extends Controller {
  connect() {
    StimulusReflex.register(this);
  }

  beforeReflex(el) {
    document.querySelector('.loading-spinner').classList.remove('d-none');
  }

  afterReflex(el) {
    document.querySelector('.loading-spinner').classList.add('d-none');
  }

  reflexError(el) {
    document.querySelector('.loading-spinner').classList.add('d-none');
  }

  reflexHalted(el) {
    document.querySelector('.loading-spinner').classList.add('d-none');
  }

  reload() {
    this.stimulate('LeadQueueReflex#reload');
  }

  show_lead(e) {
    if (e.target.nodeName.toLowerCase() == 'input') {
      return;
    }
    e.preventDefault();
    let path = this.element.dataset.href;
    window.location.href = path;
  }

  show_session(e) {
    e.preventDefault();
    let path = this.element.dataset.session_url;
    window.location.href = path;
  }

  show_modal(e) {
    let modalController = this.application.getControllerForElementAndIdentifier(
      document.getElementById(e.srcElement.dataset['target']),
      'modal'
    );
    modalController.open();
  }

  toggle_lock(e) {
    e.preventDefault();
    let leadId = this.element.dataset.leadId;
    this.stimulate('LeadQueue#toggle_lock_lead', leadId);
  }

  place_call(e) {
    e.preventDefault();
    let leadId = this.element.dataset.leadId;
    let userId = this.element.dataset.userId;
    this.stimulate('LeadQueue#place_call', leadId, userId);
  }
}
