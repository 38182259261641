
import { Controller } from 'stimulus';
import Chart from 'chart.js';
import { FunnelChart } from 'chartjs-chart-funnel';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default class extends Controller {
  static targets = ['canvas'];
  static values = { data: Object };

  connect() {
    console.log(this.dataValue)
    this.impressionsValue = this.dataValue.impressions;
    this.engagementValue = this.dataValue.engagement;
    this.intentValue = this.dataValue.intent;
    this.goalValue = this.dataValue.goal;

    this.chart = new FunnelChart(this.canvasTarget.getContext('2d'), {
      type: 'funnel',
      data: {
        labels: ['Impressions', 'Engagement', 'Intent', 'Goal'],
        datasets: [
          {
            data: [this.impressionsValue, this.engagementValue, this.intentValue, this.goalValue]
          },
        ],
      },
      plugins: [ChartDataLabels],
    });
  }
}