import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input', 'button']

  connect() {
    this.checkAll()
  }

  // Check whether all the check box inputs are checked
  // if checked, enable the submit button
  // if not checked, disable the submit button
  // by default the button should be disabled
  checkAll(e) {
    let checked = true;

    this.inputTargets.forEach(input => {
      if (!input.checked) {
        checked = false;
      }
    })
    if (checked) {
      // add disabled class to button target
      this.buttonTarget.classList.remove('disabled')
    } else {
      // remove disabled class from button target
      this.buttonTarget.classList.add('disabled')
    }
  }
}