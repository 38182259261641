import { Controller } from 'stimulus';

export default class extends Controller {
  static values = { storyUrl: String };

  connect() {
    const stories = [
      {
        href: this.storyUrlValue,
      },
    ];
    const playerContainer = this.element;
    playerContainer.innerHTML = null;
    const playerEl = document.createElement('amp-story-player');
    playerEl.setAttribute(
      'style',
      `height: ${this.element.dataset['crop'] || 'calc(100vh - 160px)'};`
    );
    playerContainer.append(playerEl);
    // eslint-disable-next-line
    const player = new AmpStoryPlayer(window, playerEl);
    player.load();

    if (playerEl.isReady) {
      playerEl.add(stories);
    } else {
      playerEl.addEventListener('ready', () => {
        playerEl.add(stories);
      });
    }
  }
}
