import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'paymentIdInput',
    'orderIdInput',
    'signatureInput',
    'paymentForm',
  ];
  connect() {
    let data = this.element.dataset;
    let options = {
      key: data['razorpayKey'],
      amount: data['razorpayAmount'],
      currency: data['razorpayCurrency'],
      name: data['razorpayName'],
      description: data['razorpayDescription'],
      order_id: data['razorpayOrder_id'],
      handler: (response) => {
        this._successHandler(response);
      },
      prefill: {
        name: data['razorpaySubscriber.name'],
        email: data['razorpaySubscriber.email'],
        contact: data['razorpaySubscriber.contact'],
      },
      theme: {
        color: data['razorpayTheme.color'],
      },
    };
    this.rzp = new Razorpay(options); // eslint-disable-line
    this.showModal();
  }

  _successHandler(response) {
    this.paymentIdInputTarget.value = response.razorpay_payment_id;
    this.orderIdInputTarget.value = response.razorpay_order_id;
    this.signatureInputTarget.value = response.razorpay_signature;
    this.paymentFormTarget.requestSubmit();
  }

  showModal() {
    this.rzp && this.rzp.open();
  }
}
