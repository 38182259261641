import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['image', 'value']
  imageSelected(e) {
    let image_url = e.detail.selected_url
    this.imageTarget.src = image_url;
    this.valueTarget.value = image_url;
    this.valueTarget.form.requestSubmit();
  }
}