import Flatpickr from 'stimulus-flatpickr';
import weekSelectPlugin from 'flatpickr/dist/plugins/weekSelect/weekSelect.js';
import viewPort from '../lib/view-port';

export default class extends Flatpickr {
  connect() {
    const { flatpickrPlugin, view } = this.element.dataset;
    this.config = {
      disableMobile: true,
      position: 'left',
    };
    if (
      flatpickrPlugin == 'week-select' &&
      view == 'WEEK' &&
      (viewPort() != 'xs' || viewPort != 'sm')
    ) {
      this.config.plugins = [new weekSelectPlugin({})];
    }

    super.connect();
  }

  change(value) {
    const { target } = this.element.dataset;
    const dom = document.getElementById(target);
    dom.value = value[0].toISOString();
    dom.dispatchEvent(new Event('change'));
  }
}
