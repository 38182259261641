import { Controller } from 'stimulus';
import ahoy from 'ahoy.js';

import { setWidgetVideoCallStatus } from '../helpers';
import storageAvailable from '../utils/localStorage';
import { setTag } from '../helpers/sentry';

const hasOwnProperty = (obj, prop) =>
  obj && Object.prototype.hasOwnProperty.call(obj, prop);

class BaseLayout {
  constructor() {}

  childNodes() {
    return document.querySelector(this.layout_container_selector).children;
  }

  replaceNodes(nodes) {
    document
      .querySelector(this.layout_container_selector)
      .replaceChildren(...nodes);
  }

  appendChild(child) {
    document.querySelector(this.layout_container_selector).append(child);
  }

  show() {
    document
      .querySelector(this.layout_container_selector)
      .classList.remove('d-none');
  }

  hide() {
    document
      .querySelector(this.layout_container_selector)
      .classList.add('d-none');
  }
}

class SpeakerViewLayout extends BaseLayout {
  constructor(nodes = []) {
    super();
    this.nodes = nodes;
    this.layout_container_selector = '.speaker';
  }

  renderStreams() {
    this.replaceNodes(this.nodes);
    this.handleSpeakerViewTransition();
    console.log('renderStream: speaker view', this.nodes);
  }

  handleSpeakerViewTransition() {
    let localVideo = document.querySelector('[data-type="local"]');
    if (
      document.querySelector(this.layout_container_selector).children.length ==
      2
    ) {
      let remoteVideo = document.querySelector('[data-type="remote"]');
      if (remoteVideo && localVideo) {
        localVideo.className = 'popup_view';
        localVideo.ondblclick = () => {
          this.togglePopupView();
        };
        remoteVideo.className = 'full_screen_view';
      }
    } else if (localVideo) {
      localVideo.className = 'full_screen_view';
      localVideo.ondblclick = null;
    }
  }

  togglePopupView() {
    let localVideo = document.querySelector('[data-type="local"]');
    let remoteVideo = document.querySelector('[data-type="remote"]');
    if (remoteVideo && localVideo) {
      if (localVideo.className == 'popup_view') {
        remoteVideo.className = 'popup_view';
        localVideo.className = 'full_screen_view';
        this.toggleVideoSwitchingListeners(localVideo, remoteVideo);
      } else {
        localVideo.className = 'popup_view';
        remoteVideo.className = 'full_screen_view';
        this.toggleVideoSwitchingListeners(remoteVideo, localVideo);
      }
    }
  }

  toggleVideoSwitchingListeners(removeEl, addEl) {
    if (removeEl) removeEl.ondblclick = null;
    if (addEl)
      addEl.ondblclick = () => {
        this.togglePopupView();
      };
  }
}

class GalleryViewLayout extends BaseLayout {
  constructor(nodes = []) {
    super();
    this.nodes = nodes;
    this.layout_container_selector = '.grid_view';
  }

  renderStreams() {
    let videoonlynodes = document.querySelectorAll('[data-mode="video"]');
    let i = 0;
    while (
      videoonlynodes &&
      videoonlynodes.length &&
      i < videoonlynodes.length
    ) {
      videoonlynodes[i].className = 'video';
      i++;
    }
    this.replaceNodes(this.nodes);
    this.handleGalleryViewTransition();
  }

  handleGalleryViewTransition() {
    let gridList = document.querySelector(this.layout_container_selector);
    let remoteVideos = document.querySelectorAll('[data-type="remote"]');
    let videoCount = document.querySelector(this.layout_container_selector)
      .children.length;
    if (videoCount > 2) {
      remoteVideos.forEach((element) => {
        if (element.classList.contains('full_screen_view')) {
          return;
        } else {
          element.className = 'popup_view';
        }
      });
    }

    gridList.childNodes.forEach((element) => {
      element.addEventListener('click', (e) => {
        gridList.childNodes.forEach((element) => {
          element.className = 'popup_view';
        });
        e.currentTarget.className = 'full_screen_view';
      });
    });
  }
}

class ScreenShareLayout extends BaseLayout {
  constructor(nodes) {
    super();
    this.nodes = nodes;
    this.layout_container_selector = '.screenshare';
  }

  renderStreams() {
    document
      .querySelector('.screenshare-container')
      .replaceChildren(...document.querySelectorAll('[data-mode="screen"]'));
    let videoonlynodes = document.querySelectorAll('[data-mode="video"]');
    let i = 0;
    while (
      videoonlynodes &&
      videoonlynodes.length &&
      i < videoonlynodes.length
    ) {
      videoonlynodes[i].className = 'video';
      i++;
    }
    let nodes = document.querySelectorAll('video');
    let j = 0;
    while (nodes && nodes.length && j < nodes.length) {
      nodes[j].className = 'video-contain';
      j++;
    }
    document
      .querySelector('.videos-container')
      .replaceChildren(...document.querySelectorAll('[data-mode="video"]'));
  }

  childNodes() {
    return document.querySelectorAll('[id^="user_"]');
  }
}

class LayoutManager {
  constructor(participant_data) {
    this.participant_data = participant_data;
    this.currentLayout = new SpeakerViewLayout();
    this.stream_ids = [];
  }

  resolveLayout() {
    let nodes = this.currentLayout.childNodes();
    this.currentLayout.hide();
    if (this.stream_ids.indexOf('5555') != -1) {
      this.currentLayout = new ScreenShareLayout(nodes);
    } else if (this.stream_ids.length > 2) {
      this.currentLayout = new GalleryViewLayout(nodes);
    } else {
      this.currentLayout = new SpeakerViewLayout(nodes);
    }
    this.currentLayout.renderStreams();
    this.currentLayout.show();
  }

  addStream(stream_id, type) {
    if (this.stream_ids.indexOf(stream_id) === -1)
      this.stream_ids.push(stream_id);
    let stream_dom = this.createStreamElement(stream_id, type);
    this.currentLayout.appendChild(stream_dom);
    this.resolveLayout();
  }

  removeUser(stream_id) {
    let ids = this.stream_ids.filter((id) => id !== stream_id);
    this.stream_ids = ids;
    let stream_dom_id = 'user_' + stream_id;
    let elem = document.querySelector('#' + stream_dom_id);
    elem.remove();
    this.resolveLayout();
  }

  removeStream(stream_id, mediaType) {
    let stream_dom_id = mediaType + '_track_' + stream_id;
    let elem = document.querySelector('#' + stream_dom_id);
    elem.remove();
  }

  createStreamElement(user_id, type) {
    let user_dom_id = 'user_' + user_id;
    let video_track_dom_id = 'video_track_' + user_id;
    let audio_track_dom_id = 'audio_track_' + user_id;
    let avatar_dom_id = 'avatar_' + user_id;
    let user_dom = document.getElementById(user_dom_id);
    let audio_track_dom = document.getElementById(audio_track_dom_id);
    let video_track_dom = document.getElementById(video_track_dom_id);
    let avatar_dom = document.getElementById(avatar_dom_id);
    let username = this.participant_data[user_id]?.username || 'NA';

    if (!user_dom) {
      user_dom = document.createElement('div');
      user_dom.id = user_dom_id;
      user_dom.dataset.type = type;
    }

    if (!avatar_dom) {
      avatar_dom = document.createElement('div');
      avatar_dom.id = avatar_dom_id;
      avatar_dom.classList.add('avatar_container', 'show');
      avatar_dom.innerHTML = `<div class="avatar-letter text-uppercase">${
        username ? username[0] : '<i class="material-icons">user</i>'
      }</div>`;

      user_dom.appendChild(avatar_dom);
    }

    if (!video_track_dom) {
      video_track_dom = document.createElement('div');
      video_track_dom.dataset.mode = user_id == '5555' ? 'screen' : 'video';
      video_track_dom.id = video_track_dom_id;
      video_track_dom.className = 'video';
      video_track_dom.style.width = '100%';
      video_track_dom.style.height = '100%';

      user_dom.appendChild(video_track_dom);
    }

    if (!audio_track_dom) {
      audio_track_dom = document.createElement('div');
      audio_track_dom.dataset.mode = user_id == '5555' ? 'screen' : 'audio';
      audio_track_dom.id = audio_track_dom_id;

      user_dom.appendChild(audio_track_dom);
    }

    return user_dom;
  }
}

export default class extends Controller {
  static targets = [
    'audioInput',
    'videoInput',
    'audioOutput',
    'audioIcon',
    'videoIcon',
    'localVideo',
    'remoteVideo',
    'gallery',
    'speaker',
    'screen',
    'screenShare',
    'screenContainer',
  ];
  connect() {
    this.agora_app_id = this.data.get('agoraAppId');
    this.optimization_mode = this.data.get('optimizationMode');
    this.video_profile = this.data.get('videoProfile');
    this.rtc_token = this.data.get('rtcToken');
    this.rtc_screen_token = this.data.get('rtcScreenToken');
    this.channel_name = this.data.get('channelName');
    this.video_call_id = this.data.get('id');
    this.uid = this.data.get('uid');
    this.participant_data = JSON.parse(this.data.get('participantData'));
    this.audio_mode = this.data.get('audioMode') == 'true';
    this.is_host = this.data.get('host') == 'true';
    this.localStoragePresent = storageAvailable('localStorage');
    setTag('call.id', this.video_call_id?.toString());
    setTag('call.uid', this.uid?.toString());
    import('../lib/channel')
      .then((channel) => this.join(channel.default))
      .then(() => {
        this.audioInputDevices = [];
        this.audioOutputDevices = [];
        this.videoInputDevices = [];
        this.layout = 'speaker';
        this.checkAndDisableTooltips();

        const params = new URLSearchParams(window.location.search);
        this.is_popup = params.has('popup') && params.get('popup') == 'true';
        this.pip_stream_id = null;
        if (this.is_popup) {
          window.addEventListener('focus', () => {
            if (this.pip_stream_id) {
              document.exitPictureInPicture();
              this.pip_stream_id = null;
            }
          });
          window.addEventListener('blur', () => {
            let stream_ids = this.channel.streams.ids;
            let remote_stream_id = stream_ids.find(
              (id) => id != this.local_stream_id
            );
            // let remote_stream_id = remote_stream_ids.length ? remote_stream_ids[0] : nil
            if (remote_stream_id) {
              this.pip_stream_id = remote_stream_id;
              document
                .getElementById('video' + this.pip_stream_id)
                .requestPictureInPicture();
            }
          });
        }
      });
    this.layoutManager = new LayoutManager(this.participant_data);
  }

  disconnect() {
    this.channel && this.channel.leaveMeeting();
  }

  join(Channel) {
    if (window.channel) {
      this.channel = window.channel;
    } else {
      this.channel = new Channel(
        this.agora_app_id,
        this.rtc_token,
        this.rtc_screen_token,
        this.channel_name,
        this.uid,
        this.participant_data,
        this.video_profile
      );
      window.channel = this.channel;
      this._subscribeChannelEvents();
      var is_camera_on =
        this.localStoragePresent && localStorage.getItem('camera_on');

      this.channel.joinMeeting({
        video: this.is_host ? true : is_camera_on == 'true',
        audio: true,
        optimizationMode: this.optimization_mode,
      });

      if (is_camera_on == 'false' && !this.is_host) {
        this.channel.is_video_mute = true;
        this.videoIconTarget.innerText = 'videocam_off';
        this.videoIconTarget.parentElement.classList.add('active');
      }
    }
  }

  toggleScreenShare() {
    if (this.channel.rtc_screen_client?.local_published) {
      this.channel.endScreenShare();
      this.screenShareTarget.innerText = 'screen_share';
    } else {
      this.channel.startScreenShare();
      this.screenShareTarget.innerText = 'stop_screen_share';
    }
  }

  toggleCamera() {
    this.channel.toggleCamera();
  }

  addUser() {
    $('#add-user-modal').modal('show');
  }

  getDevices() {
    $('#video-call-setting-modal').modal('show');
    this.channel.getDevices((devices) => {
      this.setAudioVideoInputsDOM(devices);
    });
  }

  changeAudioInput() {
    let deviceLabel =
      this.audioInputTarget.options[this.audioInputTarget.selectedIndex].text;
    let deviceId = this.audioInputTarget.value;
    this.channel.switchDevice('audio', deviceId, deviceLabel);
  }

  changeVideoInput() {
    let deviceLabel =
      this.videoInputTarget.options[this.videoInputTarget.selectedIndex].text;
    let deviceId = this.videoInputTarget.value;
    this.channel.switchDevice('video', deviceId, deviceLabel);
  }

  changeAudioOutput() {
    let deviceLabel =
      this.audioOutputTarget.options[this.audioOutputTarget.selectedIndex].text;
    let deviceId = this.audioOutputTarget.value;
    this.channel.setAudioOutput(deviceId, deviceLabel);
  }

  setAudioVideoInputsDOM = (devices) => {
    this.audioInputDevices = devices.filter(function (device) {
      return device.kind === 'audioinput';
    });
    this.videoInputDevices = devices.filter(function (device) {
      return device.kind === 'videoinput';
    });
    this.audioOutputDevices = devices.filter(function (device) {
      return device.kind === 'audiooutput';
    });
    let audioInputSelect = document.getElementById('audioInput');
    let audioOutputSelect = document.getElementById('audioOutput');
    let videoInputSelect = document.getElementById('videoInput');

    audioInputSelect.innerHTML = '';
    audioOutputSelect.innerHTML = '';
    videoInputSelect.innerHTML = '';

    this.audioInputDevices.forEach((audioDevice) => {
      let option = document.createElement('option');
      option.value = audioDevice.deviceId;
      option.selected = option.value == this.channel.rtc_client.audioDeviceId;
      option.innerText = audioDevice.label;
      audioInputSelect.appendChild(option);
    });

    this.audioOutputDevices.forEach((audioDevice) => {
      let option = document.createElement('option');
      option.value = audioDevice.deviceId;
      option.selected =
        option.value == this.channel.rtc_client.audioOutputDeviceId;
      option.innerText = audioDevice.label;
      audioOutputSelect.appendChild(option);
    });

    this.videoInputDevices.forEach((videoDevice) => {
      let option = document.createElement('option');
      option.value = videoDevice.deviceId;
      option.selected = option.value == this.channel.rtc_client.videoDeviceId;
      option.innerText = videoDevice.label;
      videoInputSelect.appendChild(option);
    });
  };

  _subscribeChannelEvents() {
    this.channel.on('stream-updated', (stream_id) => {
      this.renderStreamStatus(stream_id);
    });

    this.channel.on('user-published', (user_id, type, mediaType) => {
      this.onStreamAdded(user_id, type, mediaType);
    });

    this.channel.on('user-unpublished', (user_id) => {
      this.renderStreamStatus(user_id);
    });

    this.channel.on('user-left', (user_id) => {
      this.onUserRemoved(user_id);
    });

    this.channel.on('status-update', (message, expire) => {
      this.renderSnackBar(message, expire);
    });

    this.channel.on('screen-share-ended', () => {
      this.screenShareTarget.innerText = 'screen_share';
    });
  }

  onStreamAdded(user_id, type, mediaType) {
    if (type == 'local') {
      this.local_stream_id = user_id;
    } else {
      ahoy.track('stream_added', {
        vc_id: this.video_call_id,
        uid: user_id,
      });
    }

    this.layoutManager.addStream(user_id, type);

    let user_dom_id = mediaType + '_track_' + user_id;
    if (!(mediaType === 'audio' && type === 'local')) {
      this.channel.playStream(user_dom_id, user_id, mediaType, type);
    }

    this.renderStreamStatus(user_id);
  }

  onStreamRemoved(user_id) {
    this.layoutManager.removeStream(user_id);
    this.renderStreamStatus(user_id);
    ahoy.track('stream_removed', {
      vc_id: this.video_call_id,
      uid: user_id,
    });
  }

  onUserRemoved(user_id) {
    this.layoutManager.removeUser(user_id);
    ahoy.track('user_removed', {
      vc_id: this.video_call_id,
      uid: user_id,
    });
  }

  _renderAudioStatus(attributes) {
    let is_audio_on = hasOwnProperty(attributes, 'is_audio_on')
      ? attributes['is_audio_on']
      : true;
    return is_audio_on === 'yes'
      ? '<i class="material-icons text-success mr-2">mic</i>'
      : '<i class="material-icons text-danger mr-2">mic_off</i>';
  }

  _renderVideoStatus(attributes) {
    let is_video_on = hasOwnProperty(attributes, 'is_video_on')
      ? attributes['is_video_on']
      : true;
    return is_video_on === 'yes'
      ? '<i class="material-icons text-success mr-2">videocam</i>'
      : '<i class="material-icons text-danger mr-2">videocam_off</i>';
  }

  renderStreamStatus(user_id) {
    if (this.local_stream_id != user_id && !this.is_host) return;
    let stream = this.channel.users[user_id];
    let player = document.getElementById('player_' + user_id);
    if (stream && player) {
      let { attributes } = stream;
      let username = hasOwnProperty(attributes, 'username')
        ? attributes['username']
        : 'NA';
      let status = document.getElementById('status_' + user_id);
      if (!status) {
        status = document.createElement('div');
        status.id = 'status_' + user_id;
        status.classList.add('status_container');
        let status_text = document.createElement('span');
        status_text.innerHTML = `${
          this.local_stream_id == user_id ? '' : username
        }`;
        status.innerHTML = `${this._renderAudioStatus(attributes)}`;
        status.appendChild(status_text);
        player.appendChild(status);
      } else {
        status.innerHTML = `${this._renderAudioStatus(attributes)} ${
          this.local_stream_id == user_id ? '' : username
        }`;
      }
    }
  }

  toggleLocalAudio() {
    this.channel.toggleLocalAudio();
    if (this.channel && this.channel.is_audio_mute) {
      this.renderSnackBar('Microphone off', true);
      this.audioIconTarget.innerText = 'mic_off';
      this.audioIconTarget.parentElement.classList.add('active');
    } else {
      this.renderSnackBar('Microphone on', true);
      this.audioIconTarget.parentElement.classList.remove('active');
      this.audioIconTarget.innerText = 'mic';
    }
  }

  toggleLocalVideo() {
    this.channel.toggleLocalVideo();
    if (this.channel.is_video_mute) {
      this.renderSnackBar('Camera off', true);
      this.videoIconTarget.innerText = 'videocam_off';
      this.videoIconTarget.parentElement.classList.add('active');
    } else {
      this.renderSnackBar('Camera on', true);
      this.videoIconTarget.parentElement.classList.remove('active');
      this.videoIconTarget.innerText = 'videocam';
    }
  }

  renderSnackBar(message, expire) {
    const snackBarDom = document.getElementById('snackbar');
    if (snackBarDom) {
      const snackBarController =
        this.application.getControllerForElementAndIdentifier(
          snackBarDom,
          'snack'
        );
      snackBarController && snackBarController.show(message, expire);
    }
  }

  setLocalStreamDefaultAttributes() {
    var is_mic_on = this.localStoragePresent && localStorage.getItem('mic_on');
    var is_camera_on =
      this.localStoragePresent && localStorage.getItem('camera_on');
    if (is_mic_on !== null && is_mic_on == 'false') this.toggleLocalAudio();
    if (is_camera_on !== null && is_camera_on == 'false' && !this.audio_mode)
      this.toggleLocalVideo();
  }

  endCall() {
    this.channel && this.channel.leaveMeeting();
    document.querySelector(this.element.dataset.leaveForm).submit();
    setWidgetVideoCallStatus(false);
  }

  onVideoCallEnded() {
    this.channel && this.channel.leaveMeeting();
    let completeForm = document.querySelector(
      '#complete_video_call_' + this.element.dataset.videoCallId
    );
    if (completeForm) {
      completeForm.submit();
    }
  }

  updateParticipantData(e) {
    if (!this.channel) return;
    this.channel.updateParticipantData(e.detail.data);
  }

  checkAndDisableTooltips() {
    if ('ontouchstart' in window && window.screen.width < 1024) {
      $('[data-toggle="tooltip"]').tooltip('disable');
    }
  }
}
