import { Controller } from 'stimulus';
import StimulusReflex from 'stimulus_reflex';
import { Notyf } from 'notyf';
import { patch } from '@rails/request.js';
// let lastMessageId
// const reload = controller => {
//   controller.stimulate('ChatReflex#reload')
// }
// const debouncedReload = debounce(reload, 100)

export default class extends Controller {
  initialize() {
    this.notyf = new Notyf({
      types: [
        {
          type: 'info',
          dismissible: true,
          icon: false,
          className: 'notification-info',
          position: { x: 'right', y: 'top' },
        },
      ],
    });
  }

  connect() {
    StimulusReflex.register(this);
  }

  _browserNotify(message) {
    if ('serviceWorker' in navigator) {
      this._serviceWorkerNotify({
        title: 'You have an incoming call',
        body: message,
      });
      return;
    }

    if ('Notification' in window) {
      if (Notification.permission === 'granted') {
        let notification = new Notification('You have an incoming call', {
          body: message,
          requireInteraction: true,
        });
        console.log(notification);
      } else if (Notification.permission !== 'denied') {
        // ask for permission
        Notification.requestPermission().then(function (permission) {
          // If the user accepts, let's create a notification
          if (permission === 'granted') {
            new Notification('You have an incoming call', {
              body: message,
              requireInteraction: true,
            });
          }
        });
      }
      return;
    }
    console.log('This browser does not support desktop notification');
  }

  updateCounts(el) {
    let unreadCount = 0;
    if (el.target instanceof HTMLTemplateElement &&
      el.target.templateContent &&
      el.target.templateContent.querySelectorAll('.content').length > 0
    ) {
      unreadCount =
        el.target.templateContent.querySelectorAll('.content').length;
    }
    if (unreadCount > 0) {
      document
        .querySelector('.notifications button span.badge')
        .classList.remove('d-none');
    } else {
      document
        .querySelector('.notifications button span.badge')
        .classList.add('d-none');
    }
  }

  async markRead() {
    let notificationIds = [];
    document.querySelectorAll('#mini_notifications .content').forEach((el) => {
      notificationIds.push(el.dataset.notificationId);
    });
    if (
      !document.querySelector('#mini_notifications.show') ||
      notificationIds.length == 0
    ) {
      return;
    }
    await patch(this.element.querySelector('button').dataset.url, {
      responseKind: 'turbo-stream',
      body: JSON.stringify({
        notifications: notificationIds,
      }),
    });
  }

  _serviceWorkerNotify(message) {
    if (Notification.permission == 'granted') {
      navigator.serviceWorker.getRegistration().then(function (reg) {
        var options = {
          body: message.body,
          icon: '/images/icon-192.png',
          sound: '/call-tone.mp3',
          badge: '/images/badge-128.png',
          data: {
            dateOfArrival: Date.now(),
            primaryKey: 1,
          },
          requireInteraction: true,
        };
        reg.showNotification(message.title, options);
      });
    }
  }

  notify(e) {
    if (e.target && e.target.getAttribute('action') == 'remove') {
      this.cancelRingTone();
      return;
    }
    let notificationEl =
      e.target.templateContent.querySelector('.notification');
    if (notificationEl) {
      if (
        notificationEl.dataset.notificationType != 'incoming_call' &&
        notificationEl.dataset.notificationType != 'call_invite'
      ) {
        let notification = this.notyf.success({
          type: 'info',
          message: notificationEl.querySelector(
            'span[data-notification-attr=\'message\']'
          ).innerHTML,
          duration: 0,
        });
        notification.on('click', () => {
          window.location.href = notificationEl.dataset.targetUrl;
        });
      } else {
        let message = notificationEl.querySelector(
          'span[data-notification-attr=\'message\']'
        ).innerHTML;
        if (notificationEl.dataset.playRingtone) {
          this._browserNotify(message);
          this._playRingtone();
        }
      }
    }
  }

  hideNotification(e) {
    e.preventDefault();
    let parentEl = e.target.closest('div.notification');
    parentEl.classList.add('d-none');
  }

  _playRingtone() {
    let ringtoneEl = document.querySelector('audio#call-ringtone');
    if (ringtoneEl) {
      window.setTimeout(() => {
        ringtoneEl
          .play()
          .then(console.log('played audio'))
          .catch((e) => {
            console.log('audio did not play', e);
          });
        ringtoneEl.muted = false;
      }, 1000);
      window.setTimeout(() => {
        this.cancelRingTone();
      }, 30000);
    }
  }

  cancelRingTone(e) {
    if (e) {
      e.stopEventPropogation();
    }
    let ringtoneEl = document.querySelector('audio#call-ringtone');
    ringtoneEl.pause();
  }

  show(e) {
    let notificationData = e.detail;
    let notification = this.notyf.success({
      type: 'info',
      message: notificationData.message,
      duration: 0,
    });
    notification.on('click', () => {
      window.location.href = notificationData.targetUrl;
    });
  }
}
