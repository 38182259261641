import { Controller } from 'stimulus';
import StimulusReflex from 'stimulus_reflex';

export default class extends Controller {
  connect() {
    StimulusReflex.register(this);
    this.controllers = {
      note: 'Note#fetch',
      attachment: 'Attachment#fetch',
    };
  }

  _setData(el, attrib, value) {
    el.dataset[attrib] = value;
  }

  _getData(el, attrib) {
    return el.dataset[attrib];
  }

  _reset(currentAppId) {
    let me = this;
    let elements = document.getElementsByClassName('sidebar-app');
    for (let el of elements) {
      if (currentAppId != el.id) {
        el.classList.add('d-none');
        me._setData(el, 'appDisplay', 'false');
      }
    }
  }

  toggle_app(event) {
    event.preventDefault();
    $('.app-notification').addClass('d-none');
    let appUID = this.element.dataset.appUid;
    let appParent = document.querySelector('#' + appUID + '-app');
    this._reset(appParent.id);
    let isAppShowing = this._getData(appParent, 'appDisplay') == 'true';
    if (isAppShowing) {
      appParent.classList.add('d-none');
      document.querySelector('#session_room').classList.remove('app-shown');
      this._setData(appParent, 'appDisplay', 'false');
    } else {
      let controllerAction = this.controllers[appUID];
      this.stimulate(controllerAction, this.element.dataset.sessionId);
      appParent.classList.remove('d-none');
      document.querySelector('#session_room').classList.add('app-shown');
      this._setData(appParent, 'appDisplay', 'true');
    }
  }
}
