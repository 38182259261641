import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['value', 'image'];

  mediaSelected(e) {
    const mediaURL = e.detail.selected_url;
    if (this.hasImageTarget) {
      this.imageTarget.src = mediaURL;
    }
    this.valueTarget.value = mediaURL;
    this.valueTarget.form.requestSubmit();
  }
}
