import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'noticeDuration',
    'slotItem',
    'customTime',
    'integrationForm',
    'profilePic',
    'profilePicForm',
    'notificationWrapper',
    'notificationToggle',
    'notificationError',
    'ringtoneInput',
    'ringtoneWrapper',
    'instructionsInput',
  ];

  connect() {
    this._handleSlotItemClick();
    this.checkAndEnableNotifications();
  }

  toggleIntegrationForm() {
    $('#app-integration-form').fadeToggle();
  }

  open360DialogPermissionScreen() {
    const windowFeatures =
      'toolbar=no, menubar=no, width=600, height=900, top=100, left=100';
    open(
      'https://hub.360dialog.com/dashboard/app/uXt6vjPA/permissions',
      'integratedOnboardingWindow',
      windowFeatures
    );
  }

  showCustomTime(e) {
    e.target.style.display = 'none';
    this.customTimeTarget.classList.remove('d-none');
    this.customTimeTarget.classList.add('d-flex');
  }

  changeProfilePic(e) {
    if (e.target.files[0])
      this.profilePicTarget.src = URL.createObjectURL(e.target.files[0]);
    this.profilePicFormTarget.requestSubmit();
  }

  checkAndEnableNotifications() {
    if (this.hasNotificationToggleTarget) {
      var granted = Notification.permission == 'granted';
      this.notificationWrapperTarget.dataset.notificationEnabled = granted;
      this.notificationToggleTarget.checked = granted;
      this.notificationToggleTarget.disabled = granted;
    }
  }

  enableNotifications() {
    this.notificationToggleTarget.checked = false;
    Notification.requestPermission().then((res) => {
      if (res == 'denied')
        this.notificationErrorTarget.classList.remove('d-none');
      this.checkAndEnableNotifications();
    });
  }

  toggleRingtone() {
    let enabled = this.ringtoneInputTarget.value == 'true';
    this.ringtoneInputTarget.value = !enabled;
    this.ringtoneWrapperTarget.dataset.ringtone = !enabled;
  }

  _handleSlotItemClick() {
    if (this.hasSlotItemTarget) {
      this.slotItemTargets.forEach((el) => {
        el.addEventListener('click', () => {
          if (!el.classList.contains('active')) el.classList.add('active');
          this.noticeDurationTarget.value = parseInt(el.dataset.value);
          this._reset_slots(el);
        });
      });
    }
  }

  _reset_slots(slot) {
    let slots = document.querySelectorAll('.slot-item');
    slots.forEach((el) => {
      if (slot !== el) el.classList.remove('active');
    });
  }

  playRingtone() {
    let ringtoneEl = document.querySelector('audio#dummy-ringtone');
    if (ringtoneEl) {
      ringtoneEl
        .play()
        .then(console.log('played audio'))
        .catch((e) => {
          console.log('audio did not play', e);
        });
      ringtoneEl.muted = false;
    }
  }

  setScheduledCallMode(e) {
    if (e.target.checked)
      this.element.dataset.scheduledCallMode = e.target.value;
    if (e.target.value == 'gumstack')
      this.instructionsInputTarget.setAttribute(
        'placeholder',
        'Include any other instructions here.'
      );
    else
      this.instructionsInputTarget.setAttribute(
        'placeholder',
        'INCLUDE MEETING LINK OR INSTRUCTIONS ON HOW YOU\'LL CALL THEM BACK'
      );
  }
}
