var mixpanel = require('mixpanel-browser');

export default class Analytics {
  constructor() {
    this.can_track = process.env.NODE_ENV === 'production' && !!window._mt;
  }

  identify() {
    if (this.can_track && window._mt_userid) {
      mixpanel.identify(window._mt_userid);
      mixpanel.people.set({
        $first_name: window._mt_username,
        $email: window._mt_useremail,
        USER_ID: window._mt_userid,
      });
    }
  }

  track(event_name, event_properties = {}) {
    if ('connection' in navigator) {
      event_properties['liteMode'] = navigator.connection.saveData;
    }
    if (this.can_track) {
      mixpanel.track(event_name, event_properties);
    } else {
      console.log(event_name, event_properties);
    }
  }
}
