import { Controller } from 'stimulus';
import hljs from 'highlight.js/lib/core';
import xml from 'highlight.js/lib/languages/xml';
import hljsCurl from 'highlightjs-curl';
import hljsJson from 'highlight.js/lib/languages/json';

// import 'highlight.js/styles/stackoverflow-light.css';

export default class extends Controller {
  static targets = ['code'];
  connect() {
    hljs.registerLanguage('xml', xml);
    hljs.registerLanguage('json', hljsJson);
    hljs.registerLanguage('curl', hljsCurl);
    this.highlightCode();
  }
  highlightCode() {
    this.codeTargets.forEach((code) => {
      hljs.highlightBlock(code);
    });
  }
}
