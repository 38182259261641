import { Controller } from 'stimulus';
import { post, get, patch } from '@rails/request.js'

export default class extends Controller {
    static targets = [];
    static values = { url: String }

    connect() {

    }

    async refreshUsers() {
        const response = await get(this.urlValue, { responseKind: 'turbo-stream' });
    }

    async cancelInvite(e){
        const response = await patch(e.target.dataset.url, {
            responseKind: 'turbo-stream',
            body: JSON.stringify(
                {
                    status:'canceled'
                }
            )
        });
    }

    async addAgent(e) {
        const response = await post(e.target.dataset.url, {
            responseKind: 'turbo-stream',
            body: JSON.stringify(
                {
                    user_id: parseInt(e.target.dataset.userId)
                }
            )
        });
    }
}
