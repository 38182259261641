// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import '@hotwired/turbo-rails';
import '../lib/rails_common';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import '../stylesheets/unsigned';
import '../lib/bs_custom.js';
import jstz from 'jstz';
import '../lib/telephone_input.js';
import * as Sentry from '@sentry/browser';

import 'controllers';

if (process.env.NODE_ENV === 'production' && window._mt) {
  var mixpanel = require('mixpanel-browser');
  mixpanel.init(window._mt);
}

if (process.env.NODE_ENV === 'production' && window._sentry_client_dsn) {
  Sentry.init({
    dsn: window._sentry_client_dsn,
  });
}

document.addEventListener('turbo:load', () => {
  const oldIntl = window.Intl;
  let detectedTz = '';
  try {
    window.Intl = undefined;
    detectedTz = jstz.determine().name();
    detectedTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    window.Intl = oldIntl;
  } catch (e) {
    // sometimes (on android) you can't override intl
    detectedTz = jstz.determine().name();
  }

  console.log('detectedTz: ' + detectedTz.split('/')[1]);

  $('#user_time_zone option[value=\'' + detectedTz.split('/')[1] + '\']').prop(
    'selected',
    true
  );
});
