import { Controller } from 'stimulus';
import { post, patch } from '@rails/request.js';

export default class extends Controller {
    static values = { userId: String, createUrl: String, updateUrl: String, updateDuration: String };
    static targets = ["option", "input", "submit"];

    connect(){
        let default_option;
        for(let i=0; i<this.optionTargets.length; i++){
            if(this.optionTargets[i].checked){
                default_option = this.optionTargets[i]
            }
        }
        this.duration = default_option.dataset.duration
    }

    selectRadioOption(e) {
        this.duration = e.target.dataset.duration;
    }

    async createUnavailability(e){
        e.preventDefault();
        const response = await post(this.createUrlValue, {
            responseKind: 'turbo-stream',
            body: JSON.stringify({
              duration: this.duration,
            }),
        });
    }

    async updateUnavailability(e){
        e.preventDefault();
        const response = await patch(this.updateUrlValue, {
            responseKind: 'turbo-stream',
            body: JSON.stringify({
              duration: this.updateDurationValue,
            }),
        });
    }
}
