import { Controller } from '@hotwired/stimulus';
import * as Turbo from '@hotwired/turbo';

export default class extends Controller {
  static targets = ['timer'];
  static values = { duration: Number };

  connect() {
    this.startTimer();
  }

  startTimer() {
    this.durationValue = this.durationValue || 5000;
    console.log(this.durationValue);
    this.timeInteraval = setInterval(() => {
      this.timerTarget.innerText = this.durationValue / 1000;
      this.durationValue -= 1000;
      console.log(this.durationValue);
      if (this.durationValue <= 0) {
        let url =
          new URLSearchParams(window.location.search).get('back_to') ||
          window.location.pathname;
        this.next(url);
      }
    }, 1000);
  }

  next(url) {
    clearInterval(this.timeInteraval);
    history.pushState({ turbo_frame_history: true }, '', url);

    Turbo.visit(url);
  }
}
