import { Controller } from 'stimulus';
import StimulusReflex from 'stimulus_reflex';
import { isElementAvailable } from '../helpers';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  static targets = ['startTime'];
  connect() {
    StimulusReflex.register(this);
    document.addEventListener(
      'cable-ready:after-morph',
      this.refreshSlotPicker.bind(this)
    );
  }

  disconnect() {
    document.removeEventListener(
      'cable-ready:after-morph',
      this.refreshSlotPicker
    );
  }

  changeStartTime(event) {
    this.stimulate('CreateSessionComponent#start_time', event.target);
  }

  refreshSlotPicker() {
    if (isElementAvailable('slot_picker_parent')) {
      let slotPickerController =
        this.application.getControllerForElementAndIdentifier(
          document.querySelector('#slot_picker_parent'),
          'slot-picker'
        );
      if (slotPickerController) {
        slotPickerController.connect();
      }
    }
  }

  submitSuccess(anchorElement) {
    const { redirectPath } = anchorElement.dataset;
    Turbo.visit(redirectPath);
  }
}
