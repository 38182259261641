import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['moreOptionToggleIcon', 'moreOptionToggle', 'moreOptions'];
  static values = { showPanel: Boolean };

  connect() {
    this.videoCallContainer = document.querySelector('#video-call-container');
    if (this.hasShowPanelValue && this.showPanelValue) {
      this.showActionPanel();
    }
  }

  disconnect() {
    document.onmouseup = null;
    document.ontouchend = null;
  }

  _setData(el, attrib, value) {
    el.dataset[attrib] = value;
  }

  _getData(el, attrib) {
    return el.dataset[attrib];
  }

  _reset(currentPanelID) {
    let panels = document.querySelectorAll('.action-panel-container');
    for (let el of panels) {
      if (currentPanelID != el.id) {
        this._setData(el, 'panelDisplay', 'false');
        this._resetActionPanel(el);
      }
    }
  }

  _resetActionPanel(el) {
    if (el) {
      el.style.cssText = '';
      if (el.classList.contains('show-mobile-actions'))
        el.classList.remove('show-mobile-actions');
    }
  }

  showActionPanel(e) {
    e && e.preventDefault();
    this.videoCallContainer.classList.add('hide-actions');
    let panelUid = this.element.dataset.panelUid;
    this.panelParent = document.querySelector(`#${panelUid}_app`);
    this._reset(this.panelParent.id);
    this._setData(this.panelParent, 'panelDisplay', 'true');

    // this.registerEvents(this.panelParent);
  }

  hideActionPanel(e) {
    e.preventDefault();
    this.videoCallContainer.classList.remove('hide-actions');
    let panelUid = this.element.dataset.panelUid;
    this.panelParent = document.querySelector(`#${panelUid}_app`);
    this._setData(this.panelParent, 'panelDisplay', 'false');

    this.deRegisterEvents(this.panelParent);
    this._resetActionPanel(this.panelParent);
  }

  registerEvents(el) {
    let is_touch = 'ontouchstart' in document.documentElement;
    // Register for touch listener if it is touch device
    if (is_touch) {
      el.ontouchstart = (e) => {
        this.handleTouchListener(e);
      };
      document.ontouchend = () => {
        document.ontouchmove = null;
        this.toggleSelectOnChannel(false);
      };
    }
    // Register for mouse events (desktop,non touch devices)
    el.onmousedown = (e) => {
      this.handleListener(e);
    };
    document.onmouseup = () => {
      document.onmousemove = null;
      this.toggleSelectOnChannel(false);
    };
  }

  deRegisterEvents(el) {
    el.onmousedown = null;
    el.ontouchmove = null;
  }

  resize(e, is_touch_event) {
    let allowed_width = parseInt(window.screen.width * 0.7);
    let allowed_height = parseInt(window.screen.height) + 2;
    let is_mobile = window.screen.width <= 1024;
    let dx = 0;
    let dy = 0;

    if (is_touch_event) {
      dx = this.t_pos_x - e.targetTouches[0].pageX;
      dy = this.t_pos_y - e.targetTouches[0].pageY;
      this.t_pos_x = e.targetTouches[0].pageX;
      this.t_pos_y = e.targetTouches[0].pageY;
    } else {
      dx = this.m_pos_x - e.x;
      dy = this.m_pos_y - e.y;
      this.m_pos_x = e.x;
      this.m_pos_y = e.y;
    }

    let computedWidth =
      parseInt(getComputedStyle(this.panelParent, '').width) + dx;
    let computedHeight =
      parseInt(getComputedStyle(this.panelParent, '').height) + dy;
    if (computedWidth < allowed_width && !is_mobile) {
      this.panelParent.style.width = parseInt(computedWidth) + 'px';
    } else if (is_mobile && computedHeight < allowed_height) {
      this.panelParent.style.height = parseInt(computedHeight) + 'px';
    }

    let is_mobile_actions_shown = this.panelParent.classList.contains(
      'show-mobile-actions'
    );
    let difference =
      allowed_height - parseInt(getComputedStyle(this.panelParent, '').height);

    if (is_mobile && difference <= 10) {
      if (!is_mobile_actions_shown)
        this.panelParent.classList.add('show-mobile-actions');
    } else {
      if (is_mobile_actions_shown)
        this.panelParent.classList.remove('show-mobile-actions');
    }
  }

  handleTouchListener(e) {
    this.t_pos_x = e.targetTouches[0].pageX;
    this.t_pos_y = e.targetTouches[0].pageY;
    this.toggleSelectOnChannel(true);
    document.ontouchmove = (e) => {
      this.resize(e, true);
    };
  }

  handleListener(e) {
    this.m_pos_x = e.x;
    this.m_pos_y = e.y;
    this.toggleSelectOnChannel(true);
    document.onmousemove = (e) => {
      this.resize(e, false);
    };
  }

  toggleSelectOnChannel(addSelect) {
    if (addSelect && !this.videoCallContainer.classList.contains('no-select'))
      this.videoCallContainer.classList.add('no-select');
    else this.videoCallContainer.classList.remove('no-select');
  }

  handleVideoCallContainerClick(e) {
    if (
      e.target.closest('.action-panel-container') ||
      e.target.closest('#product-carousel')
    )
      return;
    if (this.videoCallContainer.classList.contains('hide-actions'))
      this.videoCallContainer.classList.remove('hide-actions');
    else this.videoCallContainer.classList.add('hide-actions');
  }

  notify() {
    let panelUid = this.element.dataset.panelUid;
    let panelParent = document.querySelector(`#${panelUid}_app`);
    let is_panel_shown = panelParent.dataset.panelDisplay == 'true';
    if (!is_panel_shown) {
      this.showBadge(this.element);
    }
  }

  showBadge(el) {
    let badgeEl = el.querySelector('.notification-badge');
    if (badgeEl) {
      let badgeCount =
        badgeEl.textContent == '' ? 0 : parseInt(badgeEl.textContent);
      badgeEl.textContent = badgeCount + 1;
    }
  }

  _resetBadge(el) {
    let badgeEl = el.querySelector('.notification-badge');
    if (badgeEl) badgeEl.textContent = '';
  }

  panelToggle(e) {
    let el = e.target;
    let data = el.dataset;
    let shouldHide = data.hide == 'false';
    let panel = document.getElementById(`${data.panelUid}_app`);
    panel.style.height = shouldHide ? '79px' : 'auto';
    el.innerText = shouldHide ? 'Expand' : 'Hide';
    el.dataset.hide = shouldHide;
  }

  toggleMoreOptions() {
    if (!this.hasMoreOptionsTarget || !this.hasMoreOptionToggleTarget) return;
    const active = this.moreOptionToggleTarget.dataset.active == 'true';
    this.moreOptionToggleTarget.dataset.active = !active;
    if (active) {
      this.moreOptionToggleTarget.innerText = 'More options';
      this.moreOptionToggleIconTarget.className = 'bi bi-caret-up-fill fs-12';
      this.moreOptionsTarget.classList.replace('d-flex', 'd-none');
    } else {
      this.moreOptionToggleTarget.innerText = 'Less options';
      this.moreOptionToggleIconTarget.className = 'bi bi-caret-down-fill fs-12';
      this.moreOptionsTarget.classList.replace('d-none', 'd-flex');
    }
  }
}
