// app/javascript/channels/chat_channel.js
import consumer from './consumer';
import CableReady from 'cable_ready';

document.addEventListener('turbo:load', () => {
  let SessionQueueContainerEl = document.querySelector('#session-queue-body');
  if (SessionQueueContainerEl) {
    consumer.subscriptions.create('SessionQueueChannel', {
      received(data) {
        if (data.cableReady) CableReady.perform(data.operations);
      },
    });
  }
});
