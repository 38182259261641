import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['source', 'destination'];

  connect() {
    this.sourceTarget.addEventListener('input', () => {
      if (this.destinationTarget.readOnly) return;

      this.destinationTarget.value = this.sourceTarget.value
        .toLowerCase()
        .replaceAll(' ', '_')
        .replaceAll(/[^a-zA-Z0-9_]/g, '');
    });
  }
}
