import { Controller } from 'stimulus';
import QRCode from 'qrcode';

export default class extends Controller {
  static values = { link: String };
  static targets = ['canvas', 'downloadPNGBtn', 'downloadSVGBtn'];

  connect() {
    QRCode.toCanvas(
      this.canvasTarget,
      this.linkValue,
      { scale: 6 },
      function (error) {
        if (error) console.error(error);
        console.log('success!');
      }
    );
    if (this.hasDownloadPNGBtnTarget) {
      this.createPNGDownload();
    }
    if (this.hasDownloadSVGBtnTarget) {
      this.createSVGDownload();
    }
  }

  createPNGDownload() {
    this.downloadPNGBtnTarget.download = 'qrcode.png';
    this.downloadPNGBtnTarget.href = this.canvasTarget.toDataURL();
  }

  async createSVGDownload() {
    const svg = await QRCode.toString(this.linkValue, { type: 'svg' });
    const blob = new Blob([svg], { type: 'image/svg+xml;charset=utf-8' });
    const url = URL.createObjectURL(blob);

    this.downloadSVGBtnTarget.href = url;
    this.downloadSVGBtnTarget.download = 'qrcode.svg';
  }
}
