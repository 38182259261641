import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['downloadPopup', 'linkToggle', 'link'];

  connect() {
    this.showDownloadPopup();
  }

  showDownloadPopup() {
    if (!this.hasDownloadPopupTarget) return;
    if (this.downloadPopupTarget.dataset.show == 'false') return;
    setTimeout(() => {
      this.downloadPopupTarget.classList.remove('d-none');
    }, 2000);
  }

  closeDownloadPopup() {
    if (!this.hasDownloadPopupTarget) return;
    this.downloadPopupTarget.remove();
  }

  toggleVideoStoreLink() {
    if (!this.hasLinkToggleTarget || !this.hasLinkTarget) return;
    let active = this.linkToggleTarget.dataset.active == "true"
    this.linkToggleTarget.dataset.active = !active;
    let preview_url = document.getElementById("preview-url-btn");
    let bagitLink = this.element.dataset.bagitLink
    let videoStoreLink = this.element.dataset.videoStoreLink
    if (active) {
      this.linkTarget.innerHTML = bagitLink
      this.linkToggleTarget.innerHTML = "Use your domain"
      this.element.dataset.text = bagitLink
      if(preview_url) preview_url.setAttribute('href',`${bagitLink}?preview=true`);
    }
    else {
      this.linkTarget.innerHTML = videoStoreLink
      this.linkToggleTarget.innerHTML = "Use bagit.live"
      this.element.dataset.text = videoStoreLink
      if(preview_url) preview_url.setAttribute('href',videoStoreLink);
    }
  }

}
