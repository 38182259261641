import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['distance'];
  connect() {
    var distance = parseInt(this.distanceTarget.value);
    var x = setInterval(function () {
      if (distance < 0) {
        clearInterval(x);
        document.getElementById('expired_redirect_link').click();
      }
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      document.getElementById('time_remaining').innerHTML =
        minutes + ' minutes ' + seconds + ' seconds';
      distance = distance - 1000;
    }, 1000);
  }
}
